
import { Context } from "../context/context";
import RangePresenter from "../private/rangePresenter";
import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";
import Why from "./why";


export default class MonthlyCostFilter extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false,
        lowest: 0,
        highest: 0,
        numberOfOptions: 0,
        value: 0
    }

    componentDidMount(){
        var results = Context.getAdviceByRoute(this.props.routeType);
        results.sort((a, b) => a.totalCostPerYear - b.totalCostPerYear);
        this.setState({
            lowest: results[0].totalCostPerYear / 12,
            highest: results[results.length - 1].totalCostPerYear / 12,
            numberOfOptions: results.length,
            value: sessionStorage.getItem("bww_filter_"+this.props.routeType+"_maxYearlyEnergyCost") == null ? this.getMax(results[results.length - 1].totalCostPerYear / 12) : parseInt(sessionStorage.getItem("bww_filter_"+this.props.routeType+"_maxYearlyEnergyCost")) /12
        });
    } 

    getMax =(value = this.state.highest) =>{
        var upperBound = value * 1.05;        
        
        var parse = "1";
        for(var i =0; i < value.toFixed(0).length -2; i++)
            parse += "0";
        
        var roundingIncrement = parseInt(parse);
        return Math.ceil(upperBound / roundingIncrement) * roundingIncrement;
    }

    getMin =() =>{
        var value = this.state.lowest;
        var lowerBound = value * 0.95;     
        
        var parse = "1";
        for(var i =0; i < value.toFixed(0).length -2; i++)
            parse += "0";
         
        var roundingIncrement = parseInt(parse);
        return Math.ceil(lowerBound / roundingIncrement) * roundingIncrement;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });

        sessionStorage.setItem("bww_filter_"+this.props.routeType+"_maxYearlyEnergyCost", e.target.elements[this.props.id].value * 12);
        if(this.props.id != "filterUpdate")
            super.submit(e.target.elements[this.props.id].value, this.props.goTo, e.target.elements[this.props.id].value);
        const evt = new CustomEvent("bww_filter_updated", {          
            bubbles: true,
            cancelable: true,
            composed: false, 
        }); 
        window.dispatchEvent(evt);
    }

    setValue = (e) =>{
        this.setState({
            value: e.target.value
        });
    }

    render({ why, id}, {lowest,highest, numberOfOptions, value }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                            <p>Er zijn totaal {numberOfOptions} mogelijkheden.</p>
                                <p>De laagste lasten per maand zijn ongeveer <RangePresenter value={lowest}  /> </p>
                                <p>De hoogste lasten per maand zijn ongeveer <RangePresenter value={highest}  /> </p>
                            </div>

                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6"> 
                                    <label for={id} class="block mb-2 text-sm font-bold text-bww-600">Wat is het maximale wat je per maand aan warmte wilt uitgeven?</label> 
                                    <input type="number" required id={id} class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5 " min={this.getMin()} max={this.getMax()} value={value}/>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                {typeof(why) == "undefined" ? "" :
                <Why why={why} />
                }
            </section>
        )
    }
}