import { Auth } from "../auth/Auth";
import { callApi } from "../callApi";

export class Context {
    static realEstate() {
        var context = localStorage.getItem("bww_context");
        var realEstateObjects = JSON.parse(localStorage.getItem("bww_realEstateObjects"));
        return realEstateObjects.filter((x) => {
            if (x.id.toLowerCase() == context.toLowerCase())
                return true;
            return false;
        })[0];
    }

    static mode(){
        var mode =  localStorage.getItem("bww_mode");
        if(mode == null)
            return "DataGathering";

        return mode;
    }

    static hasUserCompletedPreferenceChat(){
        var hasSeen = localStorage.getItem("bww_user_preference_chat_is_seen");
        if(hasSeen == null)
            return false; 
        return true;
    }

    static realEstateId() {
        return localStorage.getItem("bww_context");
    }
    static flowId() {
        return localStorage.getItem("bww_flowId");
    }
    static flowHistory() {
        return localStorage.getItem("bww_flowhistory"); 
    }
    static availableRealEstate() {
        return JSON.parse(localStorage.getItem("bww_realEstateObjects"));
    }
    static sessionId() {
        return localStorage.getItem("bww_sessionId");
    }
    static participatorId() {
        return localStorage.getItem("bww_participatorId");
    }
    static measurmentId() {
        return localStorage.getItem("bww_measurementId");
    }

    static showHelp(){
        return localStorage.getItem("bww_show_help") == "true";
    }
    
    static getMedal() {
        var total = this.totalFinishedFlows();
        switch (total) {
            case 1:
                return "Bronze";
            case 2:
                return "Silver";
            case 3:
                return "Gold";
            default:
                return "None";
        }
    }
    static totalFinishedFlows() {
        var x = localStorage.getItem("bww_totalFinishedFlows");
        if (x == null) {
            this.setMissingFlowInfo();
            setTimeout(() => {
                Context.totalFinishedFlows();
            }, 1000);
        }
        return parseInt(x);
    }
    static finishedFlows() {
        var x = localStorage.getItem("bww_finishedFlows");
        if (x == null) {
            this.setMissingFlowInfo();         
        }
        return x?.split(",") ?? [];
    }
    static async setMissingFlowInfo() {
        return callApi("GET", "/api/1/progress/" + this.measurmentId() + "/" + this.participatorId() + "/" + this.realEstateId() + "").then(report => {
            Context.handleSetMissingFlowInfoResponse(report);
        });
    }
    static handleSetMissingFlowInfoResponse(report) {
        var isOnboardingFinished = false;
        var isHouseDetailsFinished = false;
        var isPersonalBehaviorFinished = false;
        for (var i = 0; i < report.flows.length; i++) {
            var flowReport = report.flows[i];
            if (flowReport.flowId == "onboarding" && flowReport.isCompleted)
                isOnboardingFinished = true;
            if (flowReport.flowId == "houseDetails" && flowReport.isCompleted)
                isHouseDetailsFinished = true;
            if (flowReport.flowId == "personalBehavior" && flowReport.isCompleted)
                isPersonalBehaviorFinished = true;
        }

        var counter = 0;
        var flows = [];
        if (isOnboardingFinished) {
            counter++;
            flows.push("onboarding");
        }
        if (isHouseDetailsFinished) {
            counter++;
            flows.push("houseDetails");
        }
        if (isPersonalBehaviorFinished) {
            counter++;
            flows.push("personalBehavior");
        }

        localStorage.setItem("bww_totalFinishedFlows", counter)
        localStorage.setItem("bww_finishedFlows", flows.join(","));
    }

    static getAdvice(){
        var data = sessionStorage.getItem("bww_advices");
        if(data != null && typeof(data) == "string" && data.length > 3)        
            return JSON.parse(data); 

        (async() => {
            var json = await retrieveAdvice();
            sessionStorage.setItem("bww_advices", JSON.stringify(json.advices));
            sessionStorage.setItem("bww_advices_selected", JSON.stringify(json.selectedAdvice));
            sessionStorage.setItem("bww_advices_currentBuildingLevels", JSON.stringify(json.currentBuildingLevels));
            return json; 
        })();
    }

    static getCurrentBuildingLevels(){
        var data = sessionStorage.getItem("bww_advices_currentBuildingLevels");
        return JSON.parse(data); 
    }

    static getAdviceByRoute(route){
        return this.getAdvice().filter(x => x.route == route);
    }

    static getAdivceContext(){
        var data = sessionStorage.getItem("bww_advice_context");
        if(data != null && typeof(data) == "string" && data.length > 3)
            return JSON.parse(data); 
        
        (async() => {
            var json = await retrieveAdviceContext();              
            sessionStorage.setItem("bww_advice_context", JSON.stringify(json));
            return json;
        })();
    }
}

async function retrieveAdvice() {
    var url =  "/api/1/" + Context.measurmentId() + "/advice/" + Context.realEstate().bagCode;
	var apiUrl = localStorage.getItem('bww_api_url');
	var url2 = apiUrl + url;
	if(apiUrl == null)
		url2 = url;

    const response = await fetch(url2);
    return await response.json(); 
}

async function retrieveAdviceContext() {
    var url =  "/api/1/" + Context.measurmentId() + "/advice/" + Context.realEstate().bagCode+ "/context";
	var apiUrl = localStorage.getItem('bww_api_url');
	var url2 = apiUrl + url;
	if(apiUrl == null)
		url2 = url;

    const response = await fetch(url2);
    return await response.json();
}