import { Component, Fragment } from 'preact';
import { XIcon, LogoutIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Context } from '../context/context';
import { navigation } from '../navigation';
import { Auth } from '../auth/Auth';
import Medal from './medal';

export default class Menu extends Component {
    state = {
        currentUrl: window.location.pathname,
        mobileMenuOpen: false
    };

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    setMobileMenuOpen = (isMobileMenuVisible) => {
        this.setState({ mobileMenuOpen: isMobileMenuVisible });
    }

    isActive = (item) => {
        var currentUrl = this.state.currentUrl;
        if(item?.href == "/dashboard" && currentUrl == "/advies-staat-klaar")
            return true;

        return item.href == currentUrl || (currentUrl.indexOf(item.href) != -1 && item.href != "/");
    }

    getCurrentAddress = () => {
        var reo = Context.realEstate();
        return reo.street + " " + reo.houseNumber+(reo.houseLetter ?? "")+(reo.houseNumberAddition ?? "");
    }
    
    getContextSwitch = () => {
        var reos = Context.availableRealEstate();
        if (reos.length > 1) {
            return (
                <a href="#" class="absolute -bottom-6 text-slate-200 hover:text-white text-sm font-medium flex">
                    Andere woning
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2 ml-1 mt-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"></path>
                    </svg>
                </a>
            );
        }

        return "";
    }

 

    logOut = (e) =>{
        e.preventDefault();
        Auth.logout();
    }

    componentDidMount() {
        var scope = this;
        window.addEventListener("bww_routeChanged", function (e) {
            scope.setState({
                currentUrl: e.detail.route
            });
        });
    }

    render({ }, { currentUrl, mobileMenuOpen }) {
        return (
            <>
                <nav class="fixed w-full z-40 bg-bww-600 overflow-hidden">
                    <div class="max-w-screen-2xl mx-auto pl-6 pr-4 lg:px-4">
                        <div class="relative flex items-center justify-between h-16 lg:h-20">
                            <div class="flex-1 flex items-center">
                                <div class="flex-shrink-0 flex items-center">
                                <a href="/dashboard">
                                    <img src="/assets/svg/logo.svg" />
                                    </a>
                                </div>
                                <div class="hidden lg:block sm:ml-6">
                                    <div class="flex space-x-4">
                                        {navigation.filter(x => !x.isHidden).map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={this.classNames(
                                                    this.isActive(item) ? 'bg-black' : '',
                                                    'text-white font-bold relative hover:text-bww-200 px-3 py-8 text-sm lg:text-base'
                                                )}
                                                aria-current={item.href == currentUrl ? 'page' : undefined}
                                            >
                                                {this.isActive(item) ? <span class="absolute -inset-y-1 -inset-x-1 bg-black rotate-[5deg] -z-10"></span> : ""}
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div class="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto">
                                <button type="button" class="relative overflow-hidden font-bold text-white text-sm md:text-base  ease-in duration-300 px-6 pr-5 py-8  flex items-center justify-center" onClick={(e) => { e.preventDefault(); this.setMobileMenuOpen(true); }}>                                    <span class="absolute -inset-y-4 inset-x-1 bg-bww-500  rotate-[5deg] -z-10"></span>

                                    {this.getCurrentAddress()}
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-4 md:h-4 ml-2" fill="#ffffff" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>

                <Transition.Root show={mobileMenuOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-60" onClose={this.setMobileMenuOpen}>
                        <div className="fixed inset-0" />

                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                            <div className="flex h-full flex-col overflow-y-scroll bg-black py-6 shadow-xl">

                                                <div class="p-6 pb-10 relative flex items-start justify-between bg-black">
                                                    <div class="text-white flex items-center font-bold z-10">
                                                        <div class="relative">
                                                            {this.getCurrentAddress()}
                                                            {this.getContextSwitch()}
                                                        </div>
                                                    </div>

                                                    <div class="ml-3 flex h-7 items-center">
                                                        <button id="closeMenuTrigger" type="button" class="rounded-md bg-black text-white hover:text-slate-200 focus:outline-none focus:ring-2 focus:ring-white focus:-ring-offset-2" onClick={() => this.setMobileMenuOpen(false)}>
                                                            <span class="sr-only">Menu sluiten</span>
                                                            <XIcon className="h-6 w-6" />
                                                        </button>
                                                    </div>
                                                </div>

                                               <Medal />

                                                <div className="py-4 sm:py-6 lg:hidden ">
                                                    {navigation.filter(x => !x.isHidden).map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            className={this.classNames(
                                                                this.isActive(item)
                                                                    ? 'bg-bww-600 text-white'
                                                                    : 'text-bww-100 hover:bg-bww-600 hover:text-white',
                                                                'group py-3 px-4 flex items-center text-sm font-medium'
                                                            )}
                                                            aria-current={this.isActive(item) ? 'page' : undefined}
                                                        >
                                                            <item.icon
                                                                className={this.classNames(
                                                                    this.isActive(item) ? 'text-white' : 'text-bww-300 group-hover:text-white',
                                                                    'mr-3 h-6 w-6'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            <span>{item.name}</span>
                                                        </a>
                                                    ))}
                                                </div>
                                                <div class="py-4 sm:py-6">
                                                    <a
                                                            key="logout"
                                                            href="#"
                                                            onClick={this.logOut}
                                                            className={this.classNames(                                                                
                                                                  'text-bww-100 hover:bg-bww-600 hover:text-white',
                                                                'group py-3 px-4 flex items-center text-sm font-medium'
                                                            )}
                                                            aria-current={undefined}
                                                        >
                                                            <LogoutIcon
                                                                className={this.classNames(
                                                                    'text-bww-300 group-hover:text-white',
                                                                    'mr-3 h-6 w-6'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            <span>Uitloggen</span>
                                                        </a>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>
        )
    }
}