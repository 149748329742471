import { Component } from "preact";
import Spinner from "./spinner";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Why from "./why";


export default class DiscountPercentages extends Component {
    state = {
        isSubmitting: false
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];
        var discountInstallationPercentage = e.target.elements.DiscountInstallationPercentage.value;
        var discountContructionPercentage = e.target.elements.DiscountContructionPercentage.value;
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "DiscountInstallationPercentage", discountInstallationPercentage, "Korting op installatiekosten"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "DiscountContructionPercentage", discountContructionPercentage, "Korting op constructiekosten"));


        var goTo = this.props.goTo;
        Promise.all(promises).then(function (responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, printableKey) {
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value + "",
            "printableKey": printableKey,
            "printableValue": value +""
        }));
    }

    render({ why }) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>
                            <div class="grid grid-cols-2 gap-6">

                                <div class="pt-2">
                                    Korting op installatie
                                </div>
                                <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                        <input min="0" max="90" required type="number" name="DiscountInstallationPercentage" id="DiscountInstallationPercentage" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md  sm:text-sm border-gray-300" value="0" onChange={this.handleInputChange} />
                                    </div>
                                    <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                        %
                                    </div>
                                </div>

                                <div class="pt-2">
                                    Korting op bouwkundige aanpassingen
                                </div>
                                <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                        <input min="0" max="90" required type="number" name="DiscountContructionPercentage" id="DiscountContructionPercentage" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md  sm:text-sm border-gray-300" value="0" onChange={this.handleInputChange} />
                                    </div>
                                    <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                        %
                                    </div>
                                </div>


                            </div>

                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <Why why={why} />
            </section>
        );
    }
}
