import {
    HomeIcon,
    UserGroupIcon,
    TemplateIcon,
    DatabaseIcon,
    FingerPrintIcon    
} from '@heroicons/react/outline'

export const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: TemplateIcon, isHidden: false },
    { name: 'Mijn Huis', href: '/mijnhuis', icon: HomeIcon, isHidden: false },
    { name: 'Mijn Data', href: '/mijndata', icon: DatabaseIcon, isHidden: false },
    { name: 'Projectgroep', href: '/projectgroep', icon: UserGroupIcon, isHidden: false },
    { name: 'Privacy', href: '/privacy', icon: FingerPrintIcon, isHidden: false },
    { name: 'Mijn huis in detail', href: '/mijnhuis/huisindetail', icon: DatabaseIcon, isHidden: true },
    { name: 'Hoe woon ik', href: '/mijnhuis/gedrag', icon: DatabaseIcon, isHidden: true },
]
