import { callApi } from "../callApi";
import { Context } from "../context/context";
import AbstractQuestion from "./abstractQuestion";
import CustomContent from "./customContent";
import Spinner from "./spinner";
import Why from "./why";


export default class InsolationQuestion extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false,
        canSubmit: false,
        selectedOption: "",
        estimationValue: "0",
    }

    componentDidMount() {
        var reo = Context.realEstate();
        this.setState({
            estimationValue: reo[this.props.estimationValue].toString()
        })        
    }

    handleListClick = (e) => {
        e.preventDefault();
        var selected = e.target.dataset["value"];
        this.setState({
            canSubmit: true,
            selectedOption: selected
        }); 
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        }); 
        var x = e.target.elements[this.props.id];
        var goTo = this.props.goTo;
        var selectedOptions = this.props.options.filter((f) => {
            if(f.value == x.value)
                return true;
        });

        if(typeof(selectedOptions[0].goTo) !== "undefined" && selectedOptions[0].goTo.length > 0)
            goTo = selectedOptions[0].goTo

        super.submit(x.value, goTo, selectedOptions[0].friendlyValue);      
    }

    render({ id, options, why }, { canSubmit, selectedOption, showOther, estimationValue }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <CustomContent id={id} />

                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                    <input name={id} id={id} value={selectedOption} type="hidden" />
                                    {options.filter(x => parseInt(x.value) >= parseInt(estimationValue)).map((x, i) => (
                                        <li data-value={x.value} class={((selectedOption == x.value) ? "bg-bww-600 text-white " : "bg-white ") + " relative font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={this.handleListClick}>
                                            {x.label}

                                            {estimationValue == x.value ?
                                                <span class="absolute -bottom-3 left-0 right-0 text-center">
                                                    <span class="whitespace-nowrap px-3 py-2 inline-black bg-bww-600 text-white text-xs rounded">
                                                        Jouw bouwjaar
                                                    </span>
                                                </span>
                                                : ""}

                                        </li>
                                    ))}
                                </ul>

                                {showOther ?
                                    <div class="my-6">
                                        <label for="other" class="block mb-2 text-sm font-bold text-bww-600">Namelijk</label>
                                        <input type="text" name="other" required id="other" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5 " placeholder="" />
                                    </div> : ""}
                            </div>
                            {(canSubmit) ?
                                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                        {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                    </button>
                                </div>
                                : ""}
                        </form>


                    </div>

                </div>

                <Why why={why} />
            </section>
        )
    }
}

