import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";
export default class AppartmentFacadeVsGlass extends Component {
    state = {
        rows: [
            {
                key: "PercentageFacadeSquareMeters_Facade",
                value: 50,
                sortIndex: 0,
                isValueEnteredByHand: false,
                label: "Buitengevel"
            }, {
                key: "PercentageFacadeSquareMeters_Glass",
                value: 50,
                sortIndex: 1,
                isValueEnteredByHand: false,
                label: "Glas"
            }

        ],
        total: 100
    }

    componentDidMount() {

    
    }

    handleInputChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        var p = this.state.rows;

        var currentElement = p.filter((x) => x.key == id)[0];

        var enteredValues = p.filter((x) => x.isValueEnteredByHand && x.key != id);
        var totalChangedByHand = 0;
        for (let i = 0; i < enteredValues.length; i++)
            totalChangedByHand += parseInt(enteredValues[i].value);

        var notEnteredValues = p.filter((x) => !x.isValueEnteredByHand && x.key != id);
        var totalNotEnteredValues = 0;
        for (let i = 0; i < notEnteredValues.length; i++)
            totalNotEnteredValues += parseInt(notEnteredValues[i].value);

        var newState = [];

        if (value >= this.state.total) {
            value = this.state.total;
            for (let i = 0; i < enteredValues.length; i++) {
                var x = enteredValues[i];
                x.value = 0,
                    newState.push(x)
            }
            for (let i = 0; i < notEnteredValues.length; i++) {
                var x = notEnteredValues[i];
                x.value = 0,
                    newState.push(x)
            }
        }
        else if (notEnteredValues.length > 0) {
            var delta = this.state.total - totalChangedByHand - value;

            if (delta >= 0) {
                for (let i = 0; i < notEnteredValues.length; i++) {
                    var x = notEnteredValues[i];
                    x.value = Math.round(delta / notEnteredValues.length),
                        newState.push(x)
                }

                for (let i = 0; i < enteredValues.length; i++) {
                    var x = enteredValues[i];
                    newState.push(x)
                }
            }
            else {
                value = value - delta;
                for (let i = 0; i < notEnteredValues.length; i++) {
                    var x = notEnteredValues[i];
                    x.value = 0,
                        newState.push(x)
                }

                for (let i = 0; i < enteredValues.length; i++) {
                    var x = enteredValues[i];
                    newState.push(x)
                }
            }
        }
        else {
            var delta = this.state.total - value;

            if (delta >= 0) {
                for (let i = 0; i < enteredValues.length; i++) {
                    if (i == 0) {
                        var x = enteredValues[i];
                        x.value = 0,
                            x.isValueEnteredByHand = false;
                        newState.push(x)
                    } else {
                        var x = enteredValues[i];
                        x.value = Math.round(delta / enteredValues.length),
                            newState.push(x)
                    }


                }
            }
            else {
                value = value - delta;
                for (let i = 0; i < enteredValues.length; i++) {
                    var x = enteredValues[i];
                    x.value = 0,
                        newState.push(x)
                }
            }
        }

        currentElement.value = value;
        currentElement.isValueEnteredByHand = true;
        newState.push(currentElement);

        newState.sort((a, b) => {
            return a.sortIndex - b.sortIndex;
        });

        this.setState({
            rows: newState
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });
        var goTo = this.props.goTo;

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];

        for (let i = 0; i < this.state.rows.length; i++) {
            var p = this.state.rows[i];
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, p.key, (p.value + ""),  (p.label + "")));
        }

        Promise.all(promises).then(function (responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, printableKey) {
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": "Omvang "+ printableKey.toLowerCase() +" (in m²)",
            "printableKey": printableKey,
            "printableValue": value +""
        }));
    }

    render({ why }, { rows, total }) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>
                            <div class="grid grid-cols-2 gap-6">
                                {rows.map((x) => (
                                    <>
                                        <div class="pt-2">
                                            {x.label}
                                        </div>
                                        <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                                <input required type="number" name={x.key} id={x.key} class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md  sm:text-sm border-gray-300" value={x.value} onChange={this.handleInputChange} />
                                            </div>
                                            <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                                %
                                            </div>
                                        </div>
                                    </>
                                ))}


                                <div class="pt-2">
                                    Totaal
                                </div>
                                <div class="pt-2 border-t-2 text-right">
                                    {total} %
                                </div>

                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>

                <Why why={why} />
            </section>

        );
    }
}