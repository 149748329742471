export default function EneryLabel(props) {

    function getFill() {
        var label = props.label.toUpperCase();
        if(label == "A" || label[0] == "A")
            return "fill-lime-800";
        if(label == "B")
            return "fill-lime-600";
        if(label == "C")
            return "fill-amber-400";
        if(label == "D")
            return "fill-amber-600";            
        if(label == "E")
            return "fill-red-500";        
        if(label == "F")
            return "fill-red-800";            
        if(label == "G")
            return "fill-red-900";
    }


    return (
        <div class={(typeof(props.h) != "undefined" ? "h-"+props.h : "h-10" )  + " relative"}>
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class={(typeof(props.h) != "undefined" ? "h-"+props.h : "h-10" ) +  " z-10 relative"} viewBox="0 0 322.911 215.41" enable-background="new 0 0 322.911 215.41">
                <polygon class={getFill()} points="259,43.589 215.411,0 200.411,0 0,0 0,215 0,215.41 215.411,215.41 215.411,215 259,171.41 322.911,107.499"></polygon>
            </svg>

            <div class={(typeof(props.h) != "undefined" ? "left-1 leading-"+props.h : "leading-10 text-xl left-3" )  + " absolute top-0 uppercase text-white font-bold z-20"}>
                {props.label.toUpperCase()}
            </div>
        </div>
    );
}
