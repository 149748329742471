const Footer = () => (

<footer class="max-w-full m-auto bg-black mt-16 py-8 px-4 bg-slate-100 border-t-2 border-black">
        <div class="text-center">
            <img class="inline-block mb-4" src="/assets/svg/logo.svg" />
            <div class="text-sm font-bold">
                <a href="/mijnhuis" class="text-bww-200 px-2">Mijn huis</a>
                <a href="/mijndata" class="text-bww-200 px-2">Mijn data</a>
                <a href="/projectgroep" class="text-bww-200 px-2">Projectgroep</a>
                <a href="/privacy" class="text-bww-200 px-2">Privacy</a>
            </div>
        </div>
    </footer>
    )

export default Footer;