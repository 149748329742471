import { Component } from "preact";
import Spinner from "./spinner";
import { Context } from "../context/context";
import { HeartIcon } from "@heroicons/react/solid";
import EneryLabel from "../private/eneryLabel";
import HeatingIcon from "../private/heatingIcon";
import RangePresenter from "../private/rangePresenter";
import { callApi } from "../callApi";



export default class RateRouteResults extends Component {
    state = {
        showSpinnerForSubmit: false
    }

    renderHeatGrid = () => {
        var selected = sessionStorage.getItem("bww_preference_heat_grid");
        if (selected == null)
            return (<></>);

        var solution = Context.getAdvice().filter(x => x.id == selected)[0];
        return (<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
            <div class="px-4 py-6 sm:px-6 lg:p-8 lg:pb-0">
                <h3 class="text-xl font-medium text-gray-900">Jouw keuze in route Warmtenet</h3>
            </div>
            <div class="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                <div class="sm:flex lg:col-span-7">
                    <div class="rounded-lg">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="flex justify-center justify-items-center">
                                <HeatingIcon label={solution.label} name={solution.heating.name} clasName="h-10 " />
                            </div>
                            <div class="flex justify-center justify-items-center pt-2">
                                <EneryLabel label={solution.label} h="6" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 sm:ml-6 sm:mt-0">
                        <h3 class="text-base font-medium text-gray-900">
                            {solution.heating.longName}
                        </h3>
                        <p class="mt-3 text-sm text-gray-500">Maandelijkse kosten: <RangePresenter value={solution.totalCostPerYear / 12} /> </p>
                        <p class="mt-3 text-sm text-gray-500">Investering: <RangePresenter value={solution.totalInvestment} /></p>
                    </div>
                </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                <p class="text-sm font-medium text-gray-900">Hoe enthousiast ben je over deze warmteoplossing?</p>
                <div class="mt-6" aria-hidden="true">

                    <div class="hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                        <label class="">
                            <input type="radio" name="heat_grid_likelyhood" value="1" /> niet enthousiast
                        </label>

                        <label class="text-center">
                            <input type="radio" name="heat_grid_likelyhood" value="3" checked={true} /> neutraal
                        </label>

                        <label class="text-right">
                            <input type="radio" name="heat_grid_likelyhood" value="5" /> enthousiast
                        </label>
                    </div>
                </div>
            </div>
        </div>);
    }

    renderFuel = () => {
        var selected = sessionStorage.getItem("bww_preference_fuel");
        if (selected == null)
            return (<></>);

        var solution = Context.getAdvice().filter(x => x.id == selected)[0];
        return (<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
            <div class="px-4 py-6 sm:px-6 lg:p-8 lg:pb-0">
                <h3 class="text-xl font-medium text-gray-900">Jouw keuze in route Brandstof</h3>
            </div>
            <div class="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                <div class="sm:flex lg:col-span-7">
                    <div class="rounded-lg">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="flex justify-center justify-items-center">
                                <HeatingIcon label={solution.label} name={solution.heating.name} clasName="h-10 " />
                            </div>
                            <div class="flex justify-center justify-items-center pt-2">
                                <EneryLabel label={solution.label} h="6" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 sm:ml-6 sm:mt-0">
                        <h3 class="text-base font-medium text-gray-900">
                            {solution.heating.longName}
                        </h3>
                        <p class="mt-3 text-sm text-gray-500">Maandelijkse kosten: <RangePresenter value={solution.totalCostPerYear / 12} /> </p>
                        <p class="mt-3 text-sm text-gray-500">Investering: <RangePresenter value={solution.totalInvestment} /></p>
                    </div>
                </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                <p class="text-sm font-medium text-gray-900">Hoe enthousiast ben je over deze warmteoplossing?</p>
                <div class="mt-6" aria-hidden="true">

                    <div class="hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                        <label class="">
                            <input type="radio" name="fuel_likelyhood" value="1" /> niet enthousiast
                        </label>

                        <label class="text-center">
                            <input type="radio" name="fuel_likelyhood" value="3" checked={true} /> neutraal
                        </label>

                        <label class="text-right">
                            <input type="radio" name="fuel_likelyhood" value="5" /> enthousiast
                        </label>
                    </div>
                </div>
            </div>
        </div>);
    }

    renderAllElectric = () => {
        var selected = sessionStorage.getItem("bww_preference_all_electric");
        if (selected == null)
            return (<></>);

        var solution = Context.getAdvice().filter(x => x.id == selected)[0];
        return (<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
            <div class="px-4 py-6 sm:px-6 lg:p-8 lg:pb-0">
                <h3 class="text-xl font-medium text-gray-900">Jouw keuze in route All Electric</h3>
            </div>
            <div class="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                <div class="sm:flex lg:col-span-7">
                    <div class="rounded-lg">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="flex justify-center justify-items-center">
                                <HeatingIcon label={solution.label} name={solution.heating.name} clasName="h-10 " />
                            </div>
                            <div class="flex justify-center justify-items-center pt-2">
                                <EneryLabel label={solution.label} h="6" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 sm:ml-6 sm:mt-0">
                        <h3 class="text-base font-medium text-gray-900">
                            {solution.heating.longName}
                        </h3>
                        <p class="mt-3 text-sm text-gray-500">Maandelijkse kosten: <RangePresenter value={solution.totalCostPerYear / 12} /> </p>
                        <p class="mt-3 text-sm text-gray-500">Investering: <RangePresenter value={solution.totalInvestment} /></p>
                    </div>
                </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                <p class="text-sm font-medium text-gray-900">Hoe enthousiast ben je over deze warmteoplossing?</p>
                <div class="mt-6" aria-hidden="true">

                    <div class="hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                        <label class="">
                            <input type="radio" name="all_electric_likelyhood" value="1" /> niet enthousiast
                        </label>

                        <label class="text-center">
                            <input type="radio" name="all_electric_likelyhood" value="3" checked={true} /> neutraal
                        </label>

                        <label class="text-right">
                            <input type="radio" name="all_electric_likelyhood" value="5" /> enthousiast
                        </label>
                    </div>
                </div>
            </div>
        </div>);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var form = e.target;
        var isHeatGrid = sessionStorage.getItem("bww_preference_heat_grid");
        var isFuel = sessionStorage.getItem("bww_preference_fuel");
        var isAllElectric = sessionStorage.getItem("bww_preference_all_electric");

        var saves = [];
        if (isHeatGrid)
            saves.push(callApi("POST", "/api/1/answers/record", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "sessionId": Context.sessionId(),
                "flowId": Context.flowId(),
                "questionCode": "heat_grid_likelyhood",
                "value": form.querySelector("input[name='heat_grid_likelyhood']:checked").value,
            })));
        if (isFuel)
            saves.push(callApi("POST", "/api/1/answers/record", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "sessionId": Context.sessionId(),
                "flowId": Context.flowId(),
                "questionCode": "fuel_likelyhood",
                "value": form.querySelector("input[name='fuel_likelyhood']:checked").value,
            })));
        if (isAllElectric)
            saves.push(callApi("POST", "/api/1/answers/record", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "sessionId": Context.sessionId(),
                "flowId": Context.flowId(),
                "questionCode": "all_electri_likelyhood",
                "value": form.querySelector("input[name='all_electric_likelyhood']:checked").value,
            })));

        Promise.all(saves).then(() => {
            callApi("POST", "/api/1/answers/record", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "sessionId": Context.sessionId(),
                "flowId": Context.flowId(),
                "questionCode": "ResultsHaveBeenPicked",
                "value": "true",
            })).then(
                (data) => {
                    alert("Bedankt voor het doorgeven van je kueze! De werkgroep gaat er mee aan de slag.")
                }
            );
        });
    }

    render({ }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">

                            <div class="space-y-8">
                                {this.renderHeatGrid()}
                                {this.renderFuel()}
                                {this.renderAllElectric()}
                            </div>

                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </section>
        )
    }
}