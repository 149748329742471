
import AppartmentFacadeVsGlass from "./appartmentFacadeVsGlass";
import AreaNotHeated from "./areaNotHeated";
import ConfirmIncorrectData from "./confirmIncorrectData";
import CookingMethod from "./cookingMethod";
import DescribeFamily from "./describeFamily";
import DiscountPercentages from "./discountPercentages";
import EneryUsage from "./eneryUsage";
import FlowRouter from "./flowRouter";
import GetEmail from "./getEmail";
import GoToAdviceRoot from "./goToAdviceRoot";
import GoToDashboard from "./goToDashboard";
import InsolationQuestion from "./insolationQuestion";
import InvestmentCostFilter from "./investmentCostFilter";
import MonthlyCostFilter from "./monthlyCostFilter";
import MultipleChoice from "./multipleChoice";
import MultipleHouseUsageAsHouse from "./multipleHouseUsageAsHouse";
import MultipleHouseUsageSquareMeters from "./multipleHouseUsageSquareMeters";
import Number from "./number"; 
import PickResult from "./pickResult";
import PointSorter from "./pointSorter";
import RadioChoise from "./radioChoice";
import RateRouteResults from "./rateRouteResults";
import RoofType from "./roofType";
import SelectHouse from "./selectHouse";
import SignInComponent from "./signInComponent";
import SignUpComponent from "./signupComponent";
import SolarBoiler from "./solarBoiler";
import WarmWaterUsage from "./warmWaterUsage"; 



const questions = {
    "RadioChoise" : RadioChoise,
    "RadioChoice": RadioChoise,
    "GetEmail": GetEmail,
    "DescribeFamily": DescribeFamily,
    "EneryUsage": EneryUsage,
    "CookingMethod" : CookingMethod,
    "ConfirmIncorrectData" : ConfirmIncorrectData,
    "Number": Number,
    "AreaNotHeated": AreaNotHeated,
    "MultipleChoice" : MultipleChoice,
    "GoToDashboard" : GoToDashboard,
    "SelectHouse": SelectHouse,
    "MultipleHouseUsageSquareMeters" : MultipleHouseUsageSquareMeters,
    "MultipleHouseUsageAsHouse" : MultipleHouseUsageAsHouse,
    "WarmWaterUsage" : WarmWaterUsage,
    "RoofType" : RoofType,
    "InsolationQuestion": InsolationQuestion,
    "DiscountPercentages" : DiscountPercentages,
    "AppartmentFacadeVsGlass" : AppartmentFacadeVsGlass,
    "SignUpComponent" : SignUpComponent,
    "SignInComponent" : SignInComponent,
    "MonthlyCostFilter": MonthlyCostFilter,
    "InvestmentCostFilter": InvestmentCostFilter ,
    "PickResult": PickResult,
    "RateRouteResults": RateRouteResults,
    "PointSorter" : PointSorter,
    "SolarBoiler": SolarBoiler,
    "FlowRouter": FlowRouter,
    "GoToAdviceRoot": GoToAdviceRoot 
};

function Question(props) {
    const Q = questions[props.type];  
    return <Q {...props} />; 
}
  
export default Question;    