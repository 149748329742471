import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";


export default class GetEmail extends Component {
    state = {
        isSubmitting: false
    };

    handleSubmit = (e) =>{
        e.preventDefault();       
        var x = e.target.elements;

        if(x.email.value != x.email2.value){
            alert("e-mailadressen komen niet overeen");
            return;
        }

        this.setState({
            isSubmitting: true
        }); 
    
        var goTo = this.props.goTo;


        if(!this.state.showSpinnerForSubmit)    
            callApi("PUT", "/api/1/participators/update", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),               
                "nickname": x.name.value,
                "email": x.email.value,
            })).then(x => {
                const e = new CustomEvent("bww_goTo", {
                    detail: {
                        goTo: goTo,
                        isInPanel: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });
    
                window.dispatchEvent(e)
            });  
    }

    render({why}) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
            <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                <form onSubmit={this.handleSubmit}>
                    <div class="p-4 md:p-6 lg:p-8">
                        <div class="relative mb-4 md:mb-6 lg:mb-8">
                            <div class="mb-6">
                                <label for="name" class="block mb-2 text-sm font-bold text-bww-600">Naam</label>
                                <input type="text" name="name" required id="name" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5 " placeholder="Naam Achternaam" />
                            </div>
                            <div class="mb-6">
                                <label for="email" class="block mb-2 text-sm font-bold text-bww-600">E-mail</label>
                                <input type="email" name="email" required id="email" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5" placeholder="mijn@email.nl" />
                            </div>
                            <div class="mb-6">
                                <label for="email2" class="block mb-2 text-sm font-bold text-bww-600">E-mail (controle)</label>
                                <input type="email" name="email2" required id="email2" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5" placeholder="mijn@email.nl" />
                            </div>
                        </div>

                        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                {this.state.isSubmitting ? <Spinner /> : "Verder"}
                            </button>
                        </div>       
                    </div>
                </form>
            </div>

            <Why why={why} />
        </section> 
        );  
    } 
}