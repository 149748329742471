import { Component, createRef } from "preact";
import { callApi, loadStatic } from "../callApi";
import Question from "../questions/question";
import ChatMessage from "./message";
import Router from "./router";
import Youtube from "./youtube";
import Progress from "../advice/bar";

export default class ChatConversation extends Component {
    state = {
        messages: [],
        conversation: [],
        history: [],
        progress: 0,
        currentConverstaionScriptId: "",
        script: 0,
        header: "",
    };

    ref = createRef();
    bar = createRef();

    compoments = {
        "ChatMessage": ChatMessage,
        "Question": Question,
        "YouTube": Youtube,
        "Router": Router
    }

    componentDidMount() {
        var flow = this.props.flow ?? "onboarding";        
        this.loadFlow(flow);
        var scope = this;

        window.addEventListener("bww_goTo", function(e){
            if((scope.props.hideExtraOptions ?? false) == e.detail.isInPanel)
                scope.goToFlowItem(e.detail.goTo);
        });
        
    }
  
    componentDidUpdate() {
        this.scrollToBottom()
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    loadFlow = (flow) => {
        loadStatic("get", "/assets/flows/" + flow + ".json?v=4")
            .then(data => {
                this.setState({
                    progress: 0,
                    script: 0,
                    conversation: data.conversation
                }, () => {   
                    this.script = data.conversation;
                    var fId = localStorage.getItem("bww_flowId");             
                    if(fId != null && fId == flow){
                        var h = localStorage.getItem("bww_flowhistory")?.split(",") ?? [];
                        this.setState({
                            history: h
                        }, () =>{
                            if(h.length > 1 && this.props.hideExtraOptions !== true)                           
                                this.goToFlowItem(h[h.length - 1])                            
                            else                             
                                this.showNext();
                        });     
                    }
                    else{
                        localStorage.setItem("bww_flowId", flow);
                        localStorage.setItem("bww_flowhistory", "");
                        this.showNext();                                    
                    }
                })
            });
    }

    goToFlowItem = (goTo) => {
        if(this.ref.current != null)
            this.ref.current.classList.add("animate-disappear");

            
        if(goTo.length == 0){
            this.setState({
                messages: [],
                progress: 0,
                script: 0,
                history: []
            }, () => {
                this.ref.current.classList.remove("animate-disappear");
                this.bar.current.style.width = "0%";      
                         
                this.showNext(); 
                this.storeHistory(0);
            });
        }

        else{    
            for(var i = 0; i < this.script.length; i++){
                var x = this.script[i];
                if(x.id == goTo){
                    var h = this.state.history;
                    if(h.indexOf(goTo) == -1)
                        h.push(goTo);

                    if(this.props.hideExtraOptions !== true)
                        localStorage.setItem("bww_flowhistory", h.join(","));
                    
                    this.setState({
                        messages: [],
                        progress: 0,
                        script: i,
                        history: h
                    }, () => {
                        if(this.ref.current != null)
                            this.ref.current.classList.remove("animate-disappear");

                        var percentage = ((this.state.script / this.script.length) * 100);
                        this.bar.current.style.width = percentage + "%";              
            
                        this.showNext(); 
                        this.storeHistory(percentage);
                    });
                }
            };
        }
    }

    
    storeHistory = (percentage) =>{
        localStorage.setItem("bww_flowhistory", this.state.history.join(","));        
        if(localStorage.getItem("bww_flowId") == "welcome")
            return;

        callApi("PUT", "/api/1/measurements/measurement/"+localStorage.getItem("bww_measurementId")+"/realestate/"+localStorage.getItem("bww_context")+"/participator/"+localStorage.getItem("bww_participatorId")+"/history/flow/"+localStorage.getItem("bww_flowId"), JSON.stringify({           
            "history": this.state.history.join("|"),
            "percentage": percentage
        }));
    }

    onAfterItemRender = () => {
        this.forceUpdate();
        this.setState({ progress: this.state.progress + 1 }, () => {
            setTimeout(this.showNext, 1500);
            this.bar.current.style.width = (((this.state.script / this.script.length) + ((this.state.progress / this.script[this.state.script].content.length) / this.script.length)) * 100) + "%";
        });
    }

    showNext = () => {
        var msgs = this.state.messages.slice();
        msgs.push(this.script[this.state.script].content[this.state.progress]);
        this.setState({ 
            messages: msgs,
            currentConverstaionScriptId: this.script[this.state.script].id,
            header: this.script[this.state.script].resultHeaderActive ?? ""
        });

        if ((this.state.progress + 1) == this.script[this.state.script].content.length)
            return;
    }

    goBack = (e) =>{
        e.preventDefault(); 
        var h = this.state.history;
        if(h.length == 0){
            this.setState({
                history: [],
                progress: 0,
                messages: [],
                script: 0
            }, () => {
                localStorage.removeItem("bww_flowhistory");
                this.showNext();
            });            
        }

        else{
            h.pop();
            while(h[h.length - 1].startsWith("check")){
                h.pop();
            }

            this.setState({
                history: h
            }, () => {
                localStorage.setItem("bww_flowhistory", h.join(","));
                this.goToFlowItem(h[h.length - 1]);
            });          
        }
    }

    render({ hideExtraOptions = false }, { messages, header, conversation, currentConverstaionScriptId }) {
    
        return (
            <div>

                { hideExtraOptions !== true ? (header.length > 1) ? <Progress mode={header} currentConverstaionScriptId={currentConverstaionScriptId} /> : 

                <div class="h-2 md:h-3 lg:h-4 bg-slate-200 sticky top-0 left-0 right-0  z-10">
                    <div ref={this.bar} style="width: 0%;" class="bg-bww-600 h-full transition-all"></div>
                </div>
                : ""
                } 

                <div class="p-4 pt-8 lg:pt-16 max-w-4xl mx-auto" >
                    <div ref={this.ref} class="z-0 relative">
                        {this.state.script > 0 && header.length < 1 ? (
                            <div class="mb-8 md:mb-10 lg:mb-12 text-right">
                                <a href="#" class="text-slate-400 flex justify-end items-center" onClick={this.goBack}>
                                    <svg clip-rule="evenodd" class="w-4 h-4 mr-1" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path class="fill-slate-400" d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z" fill-rule="nonzero"></path>
                                    </svg>
                                    terug
                                </a>
                            </div>
                        ) : ""}

                        {messages.map(x => {
                            if(typeof(x) !== "undefined"){
                                var RenderItem = this.compoments[x.display];                             
                                return (
                                    <RenderItem {...x.props} afterRender={this.onAfterItemRender} conversation={conversation} currentId={currentConverstaionScriptId} isInPanel={hideExtraOptions}  /> 
                                )
                            }
                        })}
                    </div>
                   
                    <div ref={el => { this.el = el }}></div>
                </div>

                {header.length > 1 && hideExtraOptions !== true ? <div class="h-2 md:h-3 lg:h-4 bg-slate-200 sticky top-0 left-0 right-0  z-10 -mb-16 mt-4">
                    <div ref={this.bar} style="width: 0%;" class="bg-bww-600 h-full transition-all"></div>
                </div> :  ""}

                {hideExtraOptions === true ?  <div ref={this.bar}></div> : ""}
            </div>
        );
    }
} 