import { Component } from "preact";
import { loadStatic } from "../callApi";

export default class FAQ extends Component {
    state = {
        questions: []
    }

    componentDidMount() {
        var doc = this.props.doc;
        this.loadFaq(doc);
    }

    componentWillUpdate(nextProps, nextState) {     
        if (nextProps.doc != this.props.doc) 
            this.loadFaq(nextProps.doc);
    }

    loadFaq = (doc) => {
        loadStatic("get", "/assets/faq/" + doc + ".json?v=4")
            .then(data => {
                this.setState({
                    questions: data.questions.map((x, i) => {
                        return{
                            id: "faq_"+i,
                            question: x.question,
                            answer: x.answer,
                            isOpen: false
                        }
                    })
                })
            });
    }

    toggleQuestion = (id) =>{
        var newState = [];
        for(var i =0; i< this.state.questions.length; i++){
            var x = this.state.questions[i];
            if(x.id == id)
                x.isOpen = !x.isOpen;

            newState.push(x);
        }

        this.setState({
            questions: newState
        })
    }

    render({hideTitle }, { questions }) {
        return (
            <>

                {(hideTitle ?? false) ? "" : 
                    <h3 class="text-lg font-bold text-black flex items-center mb-4">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="mr-4 h-6 w-4 fill-bww-600" viewBox="0 0 7.302 13" enable-background="new 0 0 7.302 13">
                            <polygon points="3.483,0 7.302,0 3.819,13 0,13 "></polygon>
                        </svg>
                        Veelgestelde vragen
                    </h3>}

                    {questions.map((x, i) => (
                        <div class="py-4 mb-4 border-b">
                            <button class="accordion flex items-center justify-between w-full" onClick={(e) => this.toggleQuestion("faq_"+i)}>
                                <div class="font-bold text-left">
                                    {x.question}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class={(x.isOpen ? "rotate-180" : "") + " w-4 h-4 transition ease-in-out"} viewBox="0 0 24 24">
                                    <path class="fill-bww-600" d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"></path>
                                </svg>
                            </button>
                            <div class={(x.isOpen ? "" : "hidden")+ " mt-4 mr-8"}>
                                {x.answer.map(a => (
                                    <p class="mb-2" dangerouslySetInnerHTML={{ __html: a }}></p>
                                ))}
                            </div>
                        </div>
                    ))}

</>
        )
    }
}