import AbstractQuestion from "./abstractQuestion";
import CustomContent from "./customContent";
import Spinner from "./spinner";
import Why from "./why";


export default class RadioChoise extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false,
        canSubmit: false,
        selectedOption: null
    }

    handleClick = (e, goTo, friendlyValue) => {
        e.preventDefault();
        this.setState({
            showSpinnerForSubmit: true
        });
        var btn = e.target;
       
        if(this.props.id == "InsulationWish")       
            sessionStorage.setItem("bww_filter_insulation", btn.value);
       
        if(!this.state.showSpinnerForSubmit)    
            super.submit(btn.value, goTo, friendlyValue);        
    }

    handleOptionChange = (e) =>{
        if(e.target.value == "--")
            this.setState({ canSubmit: false});
        else
            this.setState({ canSubmit: true});
    }

    handleListClick = (e) =>{
        e.preventDefault();
        var selected = e.target.dataset["value"];
        this.setState({
            canSubmit: true,
            selectedOption: selected
        }); 
    }

    onSubmit = (e) =>{
        e.preventDefault();
        this.setState({
            isSubmitting: true
        }); 
        var x = e.target.elements[this.props.id];
        var goTo = this.props.goTo;
        var selectedOptions = this.props.options.filter((f) => {
            if(f.value == x.value)
                return true;
        });       

        if(typeof(selectedOptions[0].goTo) !== "undefined" && selectedOptions[0].goTo.length > 0)
            goTo = selectedOptions[0].goTo

        var friendlyValue = selectedOptions[0]?.friendlyValue ?? x.value;
        if(!(this.props.noSave ?? false))
            super.submit(x.value, goTo, friendlyValue);      
    }

    render({id, options, screenReaderTitle, why}, {canSubmit, selectedOption}) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">
                        
                        <CustomContent id={id}  />
                       
                        {(options.length <= 2) ? 
                            <div class="sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                {options.map(x => (
                                    <button type="button" onClick={(e) => this.handleClick(e, x.goTo, (x.friendlyValue ?? ""))} value={x.value} class={(x.isPrimary) ? "my-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg" : "my-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500  lg:text-lg"}>
                                        {this.state.showSpinnerForSubmit && x.isPrimary ? <Spinner /> : x.label}
                                    </button>
                                    ))
                                }                               
                            </div>

                            :                         
                            
                            <form onSubmit={this.onSubmit}>
                                <div class="relative mb-4 md:mb-6 lg:mb-8">
                                    {(options.length <= 6) ?                                         
                                        <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                                <input name={id} id={id} value={selectedOption} type="hidden" />
                                              {options.map(x => (
                                                <li data-value={x.value} class={((selectedOption == x.value) ? "bg-bww-600 text-white " : "bg-white ") + " font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={this.handleListClick}>                                                  
                                                    {x.label.replace("<sub>2</sub>", "₂")}                                          
                                                </li>
                                              ))}
                                        </ul>
                                    :
                                    <div class="mb-6">
                                        <label for={id} class="block font-medium text-gray-700">{screenReaderTitle}</label>
                                        <select onChange={this.handleOptionChange} id={id} name={id} class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bww-500 focus:border-bww-500 sm:text-sm rounded-md">
                                            <option selected value="--">--</option>
                                            {options.map((x) => (
                                                <option value={x.value}>{x.label}</option>
                                            ))}                       
                                        </select>
                                    </div>
                                }
                                </div>
                                {(canSubmit) ? 
                                    <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                        <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                            {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                        </button>
                                    </div>
                                : ""}
                            </form>

                        } 
                    </div>

                </div>
                {typeof(why) == "undefined" ? "" :
                <Why why={why} />
                }
            </section>
        )
    }
}