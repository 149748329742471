import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";
import Why from "./why";


export default class RoofType extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false,
        canSubmit: false,
        selectedOption: null,
        options: [{
            "value": "0",
            "label": "Platdak"
        },{
            "value": "1",
            "label": "Lessenaarsdak",
        },{
            "value": "2",
            "label": "Zadeldak",
        }]
    }

    getIcon(type){
        if(type == "1")
            return(
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     viewBox="0 0 49.851 41.476" enable-background="new 0 0 49.851 41.476" class="w-[80%] mb-4 mx-auto" >
<g>
    <path  class="fill-current" d="M48.288,17.726c-0.664-0.59-1.291-1.225-1.935-1.836C40.949,10.757,35.541,5.633,30.151,0.483
        c-0.59-0.565-1.083-0.634-1.786-0.208c-1.775,1.073-3.593,2.077-5.388,3.116C16.044,7.396,9.118,11.411,2.18,15.404
        c-1.375,0.792-1.492,1.049-0.261,2.048c1.128,0.916,1.575,1.942,1.47,3.391c-0.125,1.688-0.029,3.394-0.029,5.091
        c-0.003,0-0.008,0-0.012,0c0,1.95,0.038,3.903-0.014,5.854c-0.032,1.154,0.414,1.88,1.424,2.452
        c3.902,2.207,7.783,4.457,11.642,6.741c0.935,0.553,1.722,0.477,2.602-0.057c1.879-1.145,3.774-2.265,5.686-3.358
        c6.469-3.7,12.923-7.433,19.448-11.036c1.384-0.766,2.074-1.592,1.817-3.165c-0.323-1.96,0.068-3.55,2.21-4.295
        C48.946,18.798,48.896,18.261,48.288,17.726z M23.18,4.778c0.209-0.112,0.68-0.04,0.858,0.129
        c5.748,5.437,11.474,10.895,17.202,16.351c0.029,0.027,0.032,0.077,0.113,0.285c-1.332,0.786-2.692,1.589-4.153,2.45
        C31.276,18.38,25.356,12.772,19.285,7.02C20.689,6.201,21.916,5.457,23.18,4.778z M17.639,7.973
        c0.276-0.138,0.907,0.093,1.187,0.354c3.862,3.611,7.692,7.255,11.525,10.898c1.839,1.746,3.669,3.508,5.671,5.423
        c-1.393,0.799-2.655,1.621-4.015,2.229c-0.31,0.139-1.034-0.379-1.429-0.744c-3.003-2.806-5.969-5.649-8.949-8.478
        c-2.273-2.157-4.551-4.307-6.821-6.466c-0.299-0.284-0.569-0.598-0.971-1.021C15.162,9.387,16.366,8.611,17.639,7.973z
         M12.086,11.196c0.245-0.129,0.765-0.093,0.955,0.083c5.758,5.438,11.491,10.907,17.225,16.371
        c0.022,0.021,0.009,0.081,0.021,0.259c-1.198,0.749-2.356,1.533-3.581,2.191c-0.271,0.145-0.904-0.035-1.163-0.281
        c-4.578-4.343-9.119-8.724-13.677-13.085c-1.16-1.111-2.352-2.187-3.616-3.359C9.598,12.599,10.823,11.859,12.086,11.196z
         M7.132,20.686c-1.401-1.334-2.801-2.675-4.331-4.136c1.406-0.829,2.614-1.591,3.881-2.241c0.243-0.126,0.811,0.079,1.061,0.313
        c3.038,2.832,6.045,5.693,9.056,8.553c2.701,2.564,5.396,5.135,8.236,7.837c-1.412,0.81-2.621,1.601-3.924,2.184
        c-0.327,0.147-1.058-0.265-1.425-0.611C15.48,28.639,11.31,24.657,7.132,20.686z M43.12,25.604
        c-4.715,2.753-9.409,5.536-14.128,8.283c-3.14,1.831-6.304,3.627-9.462,5.428c-0.283,0.163-0.602,0.263-1.18,0.509
        c0-2.336,0-4.413,0-6.485c0.191,0,0.301-0.035,0.348,0.004C20.7,35.024,20.684,35,23.095,33.6
        c6.808-3.956,13.628-7.89,20.447-11.831c0.25-0.145,0.518-0.26,0.776-0.388C45.115,23.334,44.704,24.679,43.12,25.604z
         M43.079,20.488c-0.269,0.129-0.879-0.141-1.161-0.407C36.252,14.732,30.61,9.359,24.793,3.829c1.375-0.786,2.659-1.561,3.99-2.239
        c0.221-0.112,0.742,0.11,0.979,0.333c5.634,5.314,11.25,10.646,16.865,15.979c0.092,0.085,0.152,0.2,0.326,0.433
        C45.65,19.075,44.397,19.848,43.079,20.488z"/>
</g>
</svg>
            );

       
                if(type == "2")
                    return(
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 49.851 41.476" enable-background="new 0 0 49.851 41.476" class="w-[80%] mb-4  mx-auto" >
        
        <path  class="fill-current" d="M49.585,14.036c-3.201-4.402-6.37-8.826-9.573-13.227c-0.229-0.315-0.638-0.5-0.867-0.672
    c-0.966,0.228-1.743,0.379-2.503,0.596c-3.27,0.931-6.725,1.123-9.779,2.925c-8.51,5.021-17.101,9.905-25.649,14.861
    c-1.69,0.981-1.698,1.298,0.029,2.174c1.229,0.623,1.786,1.395,1.713,2.857c-0.139,2.764-0.091,5.541-0.02,8.311
    c0.012,0.551,0.332,1.34,0.764,1.598c4.412,2.629,8.868,5.189,13.345,7.711c0.395,0.223,1.121,0.227,1.52,0.012
    c2.358-1.268,4.67-2.627,6.992-3.963c6.813-3.924,13.62-7.855,20.438-11.766c0.779-0.447,1.097-1.037,1.053-1.926
    c-0.062-1.229,0.062-2.475-0.078-3.69c-0.233-2.026,0.176-3.594,2.307-4.349C50.034,15.219,50.066,14.699,49.585,14.036z
     M39.231,19.811L30.106,6.853c1.286-0.743,2.575-1.488,3.864-2.233l9.125,12.959C41.808,18.323,40.519,19.068,39.231,19.811z
     M28.794,7.612l9.123,12.958c-1.553,0.896-3.107,1.795-4.661,2.691l-9.123-12.957C25.685,9.409,27.239,8.51,28.794,7.612z
     M27.149,26.789l-9.123-12.956c1.598-0.923,3.195-1.846,4.792-2.769l9.124,12.957C30.346,24.943,28.75,25.865,27.149,26.789z
     M11.638,17.526c1.694-0.979,3.385-1.956,5.075-2.933l9.122,12.956c-1.626,0.939-3.251,1.879-4.89,2.824
    C17.844,26.092,14.813,21.91,11.638,17.526z M45.613,23.678c-0.008,0.266-0.312,0.619-0.571,0.77
    c-8.618,5-17.247,9.98-25.877,14.959c-0.142,0.082-0.32,0.102-0.611,0.189c0-2.908,0-5.738,0-8.623
    c0.107-0.031,0.166-0.066,0.183-0.051c1.657,1.438,3.051,0.516,4.569-0.375c6.927-4.063,13.894-8.061,20.851-12.076
    c0.403-0.234,0.829-0.432,1.481-0.769C45.639,19.854,45.663,21.766,45.613,23.678z M44.409,16.82L35.285,3.86
    c1.249-0.722,2.495-1.442,3.749-2.167c3.084,4.263,6.134,8.477,9.303,12.857C47.024,15.309,45.718,16.063,44.409,16.82z"/>
</svg>
                    );

        return(
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                    viewBox="0 0 49.851 41.476" enable-background="new 0 0 49.851 41.476"  class="w-[80%] mb-4  mx-auto">
                <g>
                    <path class="fill-current" d="M48.836,9.634C43.563,6.57,38.289,3.526,33.015,0.483C32.71,0.304,32.387,0.172,32.029,0
                        c-0.318,0.165-0.628,0.317-0.934,0.49c-5.657,3.262-11.321,6.524-16.979,9.78c-4.473,2.586-8.946,5.174-13.412,7.768
                        c-0.94,0.542-0.933,1.052-0.02,1.641c0.218,0.146,0.443,0.325,0.688,0.396c1.231,0.332,1.383,1.192,1.363,2.316
                        c-0.065,3.189-0.059,6.373,0.007,9.557c0.013,0.516,0.331,1.242,0.741,1.488c4.44,2.625,8.926,5.166,13.386,7.773
                        c0.661,0.385,1.131,0.338,1.793-0.045c9.131-5.289,18.282-10.541,27.433-15.788c0.689-0.397,0.953-0.907,0.94-1.714
                        c-0.046-2.965,0.079-5.929-0.06-8.894c-0.065-1.607,0.37-2.613,1.88-3.308C50.212,10.838,50.159,10.402,48.836,9.634z
                            M25.299,5.399c0.272-0.146,0.789-0.073,1.08,0.092c4.981,2.839,9.945,5.704,15.171,8.721c-1.634,0.933-3.017,1.793-4.472,2.515
                        c-0.357,0.171-1.02,0.04-1.41-0.186c-4.143-2.355-8.258-4.75-12.387-7.139c-0.747-0.43-1.508-0.86-2.468-1.41
                        C22.435,7.041,23.844,6.173,25.299,5.399z M18.775,9.158c0.351-0.186,0.973-0.239,1.304-0.053
                        c4.823,2.752,9.614,5.545,14.418,8.337c0.178,0.106,0.318,0.277,0.568,0.497c-1.448,0.84-2.791,1.688-4.194,2.401
                        c-0.363,0.192-1.006,0.186-1.356-0.014c-4.883-2.818-9.733-5.676-14.597-8.535c-0.066-0.04-0.112-0.132-0.239-0.277
                        C16.102,10.68,17.413,9.873,18.775,9.158z M12.787,12.651c0.245-0.132,0.702-0.099,0.953,0.046
                        c5.002,2.858,9.978,5.744,15.225,8.774c-1.667,0.966-3.149,1.847-4.664,2.681c-0.212,0.111-0.623,0.025-0.854-0.106
                        c-4.883-2.819-9.753-5.664-14.623-8.509c-0.106-0.06-0.192-0.173-0.43-0.384C9.896,14.292,11.325,13.438,12.787,12.651z
                            M17.32,27.717c-5.049-2.877-10.078-5.789-15.384-8.845c1.707-0.973,3.216-1.853,4.75-2.674c0.166-0.093,0.517,0.027,0.728,0.146
                        c4.996,2.886,9.992,5.784,14.988,8.682c0.02,0.013,0.02,0.065,0.059,0.185c-1.422,0.834-2.852,1.694-4.308,2.515
                        C17.935,27.844,17.531,27.844,17.32,27.717z M44.171,24.939c-7.318,4.082-14.55,8.33-21.81,12.525
                        c-1.25,0.715-2.488,1.469-4.029,2.383c0-3.602-0.02-6.857,0.027-10.111c0.006-0.338,0.371-0.795,0.694-0.979
                        c5.472-3.203,10.971-6.379,16.463-9.55c2.674-1.548,5.346-3.089,8.026-4.625c0.636-0.371,1.297-0.702,2.176-1.171
                        c0,3.017-0.059,5.823,0.02,8.622C45.777,23.385,45.501,24.191,44.171,24.939z M43.099,13.228c-0.237,0.125-0.747-0.113-1.064-0.298
                        c-4.327-2.461-8.635-4.949-12.949-7.438c-0.569-0.324-1.132-0.661-1.979-1.165c1.654-0.953,3.124-1.813,4.619-2.64
                        c0.186-0.099,0.542-0.073,0.735,0.04c5.041,2.892,10.064,5.79,15.364,8.854C46.123,11.553,44.635,12.439,43.099,13.228z"/>
                </g>
            </svg>
        );
    }

    handleListClick = (e, x) => {
        e.preventDefault();

        var selected = x.value;
        this.setState({
            canSubmit: true,
            selectedOption: selected
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });   
        var selectedOptions = this.state.options.filter((f) => {
            if(f.value == e.target.elements.RoofType.value)
                return true;
        });

        super.submit(e.target.elements.RoofType.value, this.props.goTo, selectedOptions[0].label);        
    }

    render({ why }, { options, canSubmit, selectedOption }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">

                                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3">
                                    <input name="RoofType" id="RoofType" value={selectedOption} type="hidden" />
                                    {options.map(x => (
                                        <li data-value={x.value} class={((selectedOption == x.value) ? "bg-bww-600 text-white " : "bg-white ") + " font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={(e) => this.handleListClick(e, x)}>
                                            {this.getIcon(x.value)}
                                            {x.label}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {(canSubmit) ?
                                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                        {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                    </button>
                                </div>
                                : ""}
                        </form>
                    </div>
                </div>

                <Why why={why} />
            </section>
        )
    }
}