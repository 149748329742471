import { Component } from "preact";
import { route } from "preact-router";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class Router extends Component {
    componentWillMount(){
        var operator = this.props.operator;
        var compareWith = this.props.compareWith;

        if(this.props.type == "Redirect")
            route(this.props.goTo, true);            
        
        else if(this.props.type == "AnswerRouter"){               
            callApi("POST", "/api/1/answers/answer", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "flowId": Context.flowId(), 
                "questionCode": this.props.compareValue
            })).then(answer => {
                var compareValue = answer.values[0];
                if(typeof(compareWith) == "number" && operator !== "COUNT IS MORE THAN" && operator !== "CONTAINS" && operator !== "EXISTS")
                    compareValue = parseInt(compareValue);

                this.handleCompare(operator, compareValue, compareWith);
            });
        }
        else if(this.props.type == "ResultType"){    
            var ctx = Context.getAdivceContext();
     
            if(ctx[this.props.compareValue] == true)
                this.handleTrue();
            else
                this.handleFalse();
        }

        else{            
            var reo = Context.realEstate();
            var compareValue = reo[this.props.compareValue];       
            
            this.handleCompare(operator, compareValue, compareWith);
        }
    }

    handleCompare = (operator, compareValue, compareWith) => {      
        if (operator == ">") {
            if (compareValue > compareWith)
                this.handleTrue();
            else
                this.handleFalse();
        }
        
        if(operator == "CONTAINS"){           
            if (compareValue.split(",").indexOf(compareWith) != -1)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "NOT CONTAINS"){           
            if (compareValue.split(",").indexOf(compareWith) == -1)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "COUNT IS MORE THAN"){           
            if (compareValue.split(",").length > compareWith)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "EXISTS"){
            if (typeof(compareValue) !== "undefined" && compareValue != null )
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "==") {           
            if (compareValue == compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "!=") {
            if (compareValue != compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "<") {
            if (compareValue < compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "<=") {
            if (compareValue <= compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == ">=") {
            if (compareValue >= compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }
    }
    
    handleTrue = () =>{
        var goTo = this.props.whenTrueGoTo;
        this.dispatchGoToEvent(goTo);
    }

    handleFalse = () =>{
        var goTo = this.props.whenFalseGoTo;
        this.dispatchGoToEvent(goTo);
    }

    dispatchGoToEvent = (value) =>{
        
        const e = new CustomEvent("bww_goTo", {
            detail: {
                goTo: value,
                isInPanel: false
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(e)
    }
}