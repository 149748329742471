import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";

export default class EneryUsage extends Component {
    state = {
        isSmartMeter: true,
        isSubmitting: false,
        expectedEnergyUsages: 0,
        expectedGasUsages: 0,
        gasWarning: "",
        energyWarning: "",
    };

    componentDidMount(){
        var reo = Context.realEstate();
        this.setState({
            expectedEnergyUsages: reo.estimatedTotalEnergyUsage,
            expectedGasUsages: reo.estimatedTotalGasUsage,
        });
    }

    toggleIsSmartMeter = (e) => {
        e.preventDefault();
        this.setState({
            isSmartMeter: !this.state.isSmartMeter
        });
    }

    preLoadNormalEnergyUsage = () => {
        var reo = Context.realEstate();
        if(!this.state.isSmartMeter)
            return reo.estimatedTotalEnergyUsage;
        return Math.round(reo.estimatedTotalEnergyUsage * 0.65);
    }

    preLoadNonPeakEnergyUsage = () => {
        var reo = Context.realEstate();
        return Math.round(reo.estimatedTotalEnergyUsage * 0.35);
    }

    preLoadNormalSolarProduction = () => {       
        return 0;
    }

    preLoadNonPeakSolarProduction = () => {        
        return 0;
    }

    preLoadGasUsage = () => {
        var reo = Context.realEstate();
        return reo.estimatedTotalGasUsage;
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];

        var x = e.target.elements;
        var normalEnergyUsage = x.NormalEnergyUsage.value;
        var normalSolarProduction = x.NormalSolarProduction.value;
        var gasUsage = x.GasUsage.value;
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "SkipedEnergyUsage", "false", "Energieverbuik overgeslagen"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "NormalEnergyUsage", normalEnergyUsage, "Energieverbruik hoogtarief in kWh"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "NormalSolarProduction", normalSolarProduction, "Zonnestroom hoogtarief in kWh"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "GasUsage", gasUsage, "Gasverbuik in m³"));

        if(this.state.isSmartMeter){
            var nonPeakEnergyUsage = x.NonPeakEnergyUsage?.value ?? "0";
            var nonPeakSolarProduction = x.NonPeakSolarProduction?.value ?? "0";
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "NonPeakEnergyUsage", nonPeakEnergyUsage, "Energieverbruik laagtarief in kWh"));
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "NonPeakSolarProduction", nonPeakSolarProduction, "Zonnestroom laagtarief in kWh"));
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalEnergyUsage", (parseInt(normalEnergyUsage) + parseInt(nonPeakEnergyUsage)), "Totaal energieverbruik in kWh"));
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalSolarProduction", (parseInt(normalSolarProduction) + parseInt(nonPeakSolarProduction)), "Totaal zonnestroom in kWh"));
        }
        else{
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalEnergyUsage", normalEnergyUsage, "Totaal energieverbruik in kWh"));
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalSolarProduction", normalSolarProduction, "Totaal zonnestroom in kWh"));
        }

        var goTo = this.props.goTo;
        Promise.all(promises).then(function(responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });   
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, printableKey){
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value+"",
            "printableKey": printableKey,
            "printableValue": value +""
        }));
    }

    skip = (e) =>{
        e.preventDefault();
        var goTo = this.props.skipWillGoTo;
        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();
        var promises = [];
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "SkipedEnergyUsage", "true"));
        Promise.all(promises).then(function() {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });   
    }

    handleGasChange = (e) =>{
        var gasUsage = e.target.value;
        var expected = this.state.expectedGasUsages; 
        var x = gasUsage / expected ;

        var message = "";
        if(gasUsage == null || gasUsage == 0)
            message = "";        
        else if(x < 0.5)
            message= "Het ingevulde gasverbruik is een stuk lager dan wij verwachten. Weet je het zeker?";
        else if(x > 2)
            message ="Het ingevulde gasverbruik is een stuk hoger dan wij verwachten. Weet je het zeker?";        
        else
            message = "";
        
        this.setState({
            gasWarning: message
        });
    }

    handleEnergyChange =() =>{
        var expected = this.state.expectedEnergyUsages;        
        var normal = document.getElementById("NormalEnergyUsage").value;
        var energyUsage = parseInt(((normal == "" || normal == null) ? 0 : normal))
        
        if(this.state.isSmartMeter){
            var nonPeak = document.getElementById("NonPeakEnergyUsage")?.value;      
            var z = parseInt(((nonPeak == "" || nonPeak == null ) ? 0 : nonPeak));
            if(isNaN(z))
                z = 0;
            energyUsage += z;
        }        

        var x = energyUsage / expected;
        
        var message = "";
        if(energyUsage == 0)
            var message = "";        
        else if(x < 0.5)
            var message = "Het ingevulde stroomverbruik is een stuk lager dan wij verwachten. Weet je het zeker?";
        else if( x > 2 )
            var message = "Het ingevulde stroomverbruik is een stuk hoger dan wij verwachten. Weet je het zeker?";
        else
            var message = "";
        
        this.setState({
            energyWarning: message
        });
    }


    render({ why }, { isSmartMeter, gasWarning, energyWarning }) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="flex items-center justify-between mb-6">
                                <span class="flex-grow flex flex-col">
                                    <span class="font-medium text-gray-900" id="availability-label">Ik heb geen dubbele meter/slimme meter</span>
                                    <span class="text-sm text-gray-500" id="availability-description">Selecteer dit als je een 'oude' analoge stroommeter hebt.</span>
                                </span>

                                <button onClick={this.toggleIsSmartMeter} type="button" class={((!isSmartMeter) ? "bg-bww-600" : "bg-gray-200") + " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500"} role="switch" aria-checked="false" aria-labelledby="availability-label" aria-describedby="availability-description">
                                    <span aria-hidden="true" class={((!isSmartMeter) ? "translate-x-5" : "translate-x-0") + " pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}></span>
                                </button>
                            </div>

                            <div class="grid sm:grid-cols-2 gap-6 mb-6">
                                <div class="colspan-2 pt-6">
                                    <label for="GasUsage" class="block font-medium text-gray-700">Gasverbruik</label>
                                    <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                        <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">

                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                                                </svg>
                                            </div>
                                            <input type="number" required name="GasUsage" id="GasUsage" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder={this.preLoadGasUsage()} onChange={(e) => this.handleGasChange(e)} />
                                        </div>
                                        <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                            m<sup>3</sup>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>
                            {gasWarning.length == 0 ? "" : 
                                <p class="text-sm text-red-700 mb-6 -mt-6">{gasWarning}</p>
                            }

                            <div class="grid sm:grid-cols-2 gap-6">
                                <div class="colspan-2">
                                    <h3 class="block mb-2 text-sm font-bold text-bww-600">Elektraverbruik</h3>
                                    <div class="mb-6">

                                        <label for="NormalEnergyUsage" class="block font-medium text-gray-700">Verbruik Normaal tarief</label>
                                        <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">

                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                                    </svg>
                                                </div>
                                                <input required type="number" name="NormalEnergyUsage" id="NormalEnergyUsage" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder={this.preLoadNormalEnergyUsage()} onChange={() => this.handleEnergyChange()} />
                                            </div>
                                            <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                                kWh
                                            </div>
                                        </div>

                                        {(isSmartMeter) ?
                                            <>
                                                <label for="NonPeakEnergyUsage" class="block font-medium text-gray-700">Verbruik Dal tarief</label>
                                                <div class="mt-1 flex rounded-md shadow-sm">
                                                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                                            </svg>
                                                        </div>
                                                        <input required type="number" name="NonPeakEnergyUsage" id="NonPeakEnergyUsage" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder={this.preLoadNonPeakEnergyUsage()}  onChange={() => this.handleEnergyChange()} />
                                                    </div>
                                                    <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                                        kWh
                                                    </div>
                                                </div>
                                            </>
                                            : ""}

                                    </div>
                                </div>
                                <div class="colspan-2">
                                    <h3 class="block mb-2 text-sm font-bold text-bww-600">Teruglevering</h3>

                                    <div class="mb-6">

                                        <label for="NormalSolarProduction" class="block font-medium text-gray-700">Teruglevering Normaal tarief</label>
                                        <div class="mt-1 flex rounded-md shadow-sm mb-4">
                                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">

                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                </div>
                                                <input type="number" required name="NormalSolarProduction" id="NormalSolarProduction" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder={this.preLoadNormalSolarProduction()} value="0" min="0" max="50000" />
                                            </div>
                                            <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                                kWh
                                            </div>
                                        </div>
 
                                        {(isSmartMeter) ?
                                            <>
                                                <label for="NonPeakSolarProduction" class="block font-medium text-gray-700">Teruglevering Dal tarief</label>
                                                <div class="mt-1 flex rounded-md shadow-sm">
                                                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">

                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </div>
                                                        <input type="number" required name="NonPeakSolarProduction" id="NonPeakSolarProduction" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"  placeholder={this.preLoadNonPeakSolarProduction()}  value="0"  min="0" max="50000"  />
                                                    </div>
                                                    <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                                        kWh
                                                    </div>
                                                </div>
                                            </>
                                            : ""}

                                    </div>
                                </div>

                                {energyWarning.length == 0 ? "" : 
                                    <p class="text-sm text-red-700 mb-6 -mt-6">{energyWarning}</p>
                                }
                            </div>
                          
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:mt-0 sm:col-start-1 sm:text-sm lg:text-lg mb-4 sm:mb-0" onClick={this.skip}>Overslaan</button>
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <Why why={why} />
            </section>
        );
    }
}