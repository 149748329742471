import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";

export default class MultipleHouseUsageAsHouse extends Component {
    state ={
        options: [],
        showSpinnerForSubmit: false,
        canSubmit: false,
        selectedOptions: []
    };

    componentDidMount() {
        var reo = Context.realEstate();
        var options = [];
        for (var i = 0; i < reo.numberOfUsages; i++) {
            var id = "purpose" + (i + 1);
            var purpose = reo[id];
            options.push({
                id: id,                
                label: purpose,
                value: purpose               
            });
        }

        this.setState({
            options: options            
        });
    }

    handleListClick = (e) =>{
        e.preventDefault();
        var value = e.target.dataset["value"];

        var array = this.state.selectedOptions;
        var index = array.indexOf(value);   
        if (index !== -1) 
            array.splice(index, 1);
        else
            array.push(value)
        
        if(value == "geen"){
            array = ["geen"];
        }

        if(array.length > 1 && array.indexOf("geen") != -1){
            var index = array.indexOf("geen");   
            if (index !== -1) 
                array.splice(index, 1);
        }

        var canSubmit = true;
        if(array.length == 0)
            canSubmit =false;

        this.setState({
            canSubmit: canSubmit,
            selectedOptions: array          
        }); 
    }

    onSubmit = (e) =>{
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });      
        var goTo = this.props.goTo;
             
        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "DwellingUsages", this.state.selectedOptions.join(",")))      

        Promise.all(promises).then(function(responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });   
    }

    getPromise(mId, pId, reoId, sId, fId, code, value){
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value,
            "printableKey": this.props.friendlyKey,
            "printableValue": value +""
        }));
    }

    render({id, why}, {options, canSubmit, selectedOptions}) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">                       
                                               
                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">                                                          
                                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                        <input name={id} id={id} value={selectedOptions.join(",")} type="hidden" />
                                        {options.map(x => (
                                        <li data-value={x.value} class={((selectedOptions.indexOf(x.value) !== -1) ? "bg-bww-600 text-white " : "bg-white ") + " font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={this.handleListClick}>                                                  
                                            {x.label}                                          
                                        </li>
                                        ))}
                                </ul>
                              
                            </div>
                            {(canSubmit) ? 
                                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                        {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                    </button>
                                </div>
                            : ""}
                        </form>

             
                    </div>

                </div>

                <Why why={why} />
            </section>
        )
    }
}