import { Component } from "preact";
import { Context } from "../context/context";



export default class FlowRouter extends Component {


    handleListClick = (e, target) => {
        e.preventDefault();
        const evt = new CustomEvent("bww_goTo", {
            detail: {
                goTo: target,
                isInPanel: false
            },
            bubbles: true,
            cancelable: true,
            composed: false, 
        });

        window.dispatchEvent(evt)
    }

    parseDynamicText = (text) =>{
        var a = Context.getAdvice();
        var heatgrid = a.filter(x => x.route == "heat_grid");
		var fuel = a.filter(x => x.route == "fuel");
		var electric = a.filter(x => x.route == "all_electric");
		var independant = a.filter(x => x.route == "other");

        text = text.replace("{NumberOfElectricResults}",  electric.length);
        text = text.replace("{NumberOfGasResults}",  fuel.length);
        text = text.replace("{NumberOfHeatGridResults}",  heatgrid.length);
        text = text.replace("{NumberOfIndependantResults}",  independant.length);

        return text; 
    }

    render({ id, conversation, currentId, extraOptions, isInPanel }) {

        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">

                        <div class="relative mb-4 md:mb-6 lg:mb-8">                           
                            <ul role="list" class="grid grid-cols-1">
                              
                                {conversation.filter(x => x.isIncludedInFlowRouter).map((x, i) => (
                                    <li class={((x.id == currentId) ? "text-slate-400" : "")+ " bg-white font-bold col-span-1 flex flex-col rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer hover:bg-bww-600 hover:text-white"} onClick={e => this.handleListClick(e, x.id)}>
                                       {i+1}. {this.parseDynamicText(x.title).replace("<sub>2</sub>", "₂")}
                                    </li>
                                ))}
                                {isInPanel !== true ? (extraOptions ?? []).filter(x => x.isPrimary).map(x => (
                                    <li class="bg-white font-bold col-span-1 flex flex-col rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer hover:bg-bww-600 hover:text-white" onClick={e => this.handleListClick(e, x.goTo)}>
                                        {this.parseDynamicText(x.label).replace("<sub>2</sub>", "₂")}
                                    </li>
                                )): ""}
                            </ul>
                        </div>

                        {isInPanel !== true ? (extraOptions ?? []).filter(x => !x.isPrimary).map(x => (
                            <a class="block bg-white px-8 py-2 text-sm text-slate-500 cursor-pointer" onClick={e => this.handleListClick(e, x.goTo)}>
                                {this.parseDynamicText(x.label).replace("<sub>2</sub>", "₂")}
                            </a>
                        )): ""}

                    </div>

                </div>

            </section>
        )
    }
}