import { Component } from "preact";
import { route } from 'preact-router';
import { Auth } from './Auth'; 

export default class AutenticatedRoute extends Component {
    componentWillMount() {     
        if(!Auth.isUserAuthenticated()){         
            route('/inloggen', true);   
        }     
    }  
}