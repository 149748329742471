import { navigation } from "../navigation";

const BreadCrumbs = () => { 
    const getName = (partOfPath) => {
        if(partOfPath != null && typeof(partOfPath) != "undefined")
            return getRoute(partOfPath)?.name;
    }

    const getRoute = (partOfPath) =>{
       return navigation.filter((x) => {
            var href = x?.href;
            if(typeof(href) == "string")            
                if(href.endsWith(partOfPath))
                    return true;
        })[0];
    }

    const getUrl = (partOfPath) => {      
        if(partOfPath != null && typeof(partOfPath) != "undefined")
            return getRoute(partOfPath)?.href;
    } 

    return(
        <div class="bg-black text-white relative z-10">
            <div class="max-w-screen-2xl mx-auto pt-16 lg:pt-20 ">
                <div class="flex font-bold items-center pl-6 pr-4 lg:px-4">
                    <a href="/dashboard" class="py-3 text-sm">Dashboard</a>
                    {
                    window.location.pathname.split("/").filter((x) => (x.toLowerCase() !== "dashboard" && x.toLowerCase() !== "advies-staat-klaar")).map(x => {
                        if(x.length > 0)
                            return(
                                <>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="ml-2 h-3 w-3 fill-bww-600" viewBox="0 0 7.302 13" enable-background="new 0 0 7.302 13" >
                                        <polygon points="3.483,0 7.302,0 3.819,13 0,13 "></polygon>
                                    </svg>
                                    <a href={getUrl(x)} class="py-3 ml-2 text-sm">{getName(x)}</a>
                                </>
                            );
                    })}
                    
                </div> 
            </div>
        </div>
    )
};

export default BreadCrumbs;