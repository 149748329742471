import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import RangePresenter from "../private/rangePresenter";

const HeatingDetails = (props) => {
    return (
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-16 lg:space-y-0">
            <div class="space-y-5 sm:space-y-4">
                <h2 class="text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight ">Hoe werkt het?</h2>
                <p class="text-xl text-gray-500">
                    {props.info?.intro}
                </p>
            </div>
            <div class="lg:col-span-2">
                <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight">Hoe werkt een {props.heating.longName}?</h2>

                {props.info?.description.map(x =>
                    <p class="text-base text-gray-500 py-4 leading-6">
                        {x}
                    </p>
                    )
                }
              
                <p class="text-base text-gray-500 py-4 leading-6">
                    <section
                        aria-labelledby="summary-heading"
                        className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                    >
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900  pb-4">
                            Het financiële plaatje
                        </h2>

                        <dl className="mt-6 space-y-4">
                            <div className="flex items-center justify-between">
                                <dt className="flex items-center text-sm text-gray-600">
                                    <span>Wat je in eerste instantie uitgeeft</span>
                                                                       {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Lees meer over subsidies</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </a> */}
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">
                                    <RangePresenter value={props.heating.investment + props.heating.otherInvestment} />
                                </dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex text-sm text-gray-600">
                                    <span>Beschikbare subsidie die je terug kunt vragen</span>
                                    {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Lees meer over subsidies</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </a> */}
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">
                                    <RangePresenter value={(props.heating.availableSubsidy)} />
                                </dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base font-medium text-gray-900">Wat er voor jou overblijft om te investeren</dt>
                                <dd className="text-base font-medium text-gray-900">
                                    <RangePresenter value={(props.heating.investment + props.heating.otherInvestment - props.heating.availableSubsidy)} />
                                </dd>
                            </div>
                        </dl>
                    </section>
                </p>
            </div>

        </div>
    );
}

export default HeatingDetails; 