export default function RangePresenter(props) {
    function present() {
        var value = props.value;
        var upperBound = value * 1.05;
        var lowerBound = value * 0.95;
        
        var parse = "1";
        for(var i =0; i < value.toFixed(0).length -2; i++)
            parse += "0";
        
        var roundingIncrement = parseInt(parse);
        upperBound = Math.ceil(upperBound / roundingIncrement) * roundingIncrement;
        lowerBound = Math.ceil(lowerBound / roundingIncrement) * roundingIncrement;

        let style = "currency";
        if(typeof(props.style) !== "undefined")
            style = props.style;

        const nf = new Intl.NumberFormat("nl-NL", {
            style: style,
            currency: "EUR",
            maximumFractionDigits: 0
          });

        if(upperBound == lowerBound)
            return  nf.format(lowerBound);
            
        return  nf.format(lowerBound) + " - " + nf.format(upperBound);
    }

    return (
       <>{present()}</>
    );
}
