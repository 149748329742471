import { InformationCircleIcon } from "@heroicons/react/outline";
import { Context } from "../context/context";
import { useState } from "react"

const Progress = ({ mode, currentConverstaionScriptId }) => {
    function handleClick(e){
        e.preventDefault();
        const evt = new CustomEvent("bww_openresultPanel", {            
            bubbles: true,
            cancelable: true,
            composed: false,
        });

     
        window.dispatchEvent(evt);
    }

   return (
        <nav aria-label="Progress" class="bg-white border-b-4 border-slate-200 " >
            <div class="p-4 max-w-4xl mx-auto grid grid-cols-2 gap-4">
                <div>
                    <p class="text-gray-600 text-sm">Warmte via</p>
                    <div class="flex items-center justify-between gap-x-4">                            
                        <h3 class="text-2xl font-semibold leading-8 text-gray-900">
                            {mode}
                        </h3>
                    </div>
                </div>
                <div>
                    {(currentConverstaionScriptId.indexOf("PickResult") > -1) ? 
                    <div class="text-right cursor-pointer" onClick={(e) => handleClick(e)}>
                        <InformationCircleIcon className="w-6 h-6 text-gray-600 text-right inline" />
                        <p class="text-gray-600 text-sm">Meer Informatie</p>
                       
                        </div>
                    : ""}
                  
                </div>
            </div>
        </nav>
    );  
}

export default Progress;  