import { Context } from "../context/context";

const ChatBubble = (props) => {
    function replaceDynamics(text){
        if(text.indexOf("{typesOfFuel}") > -1){
            var types = [];
            var data = Context.getAdviceByRoute("fuel");
            for(var i = 0; i< data.length; i++){
                var result = data[i];
                if(types.indexOf(result.heating.longName) == -1){
                    types.push(result.heating.longName);
                }
            }
            text = text.replace("{typesOfFuel}", types.join(", "));
        }

        if(text.indexOf("{bww_measurement_ambassadorIntroduction}") > -1)      
            text = text.replace("{bww_measurement_ambassadorIntroduction}", localStorage.getItem("bww_measurement_ambassadorIntroduction"));
        if(text.indexOf("{bww_measurement_ambassadorName}") > -1)
            text = text.replace("{bww_measurement_ambassadorName}", localStorage.getItem("bww_measurement_ambassadorName"));
        
        if(text.indexOf("{bww_websiteUrl}") > -1){
            if(localStorage.getItem("bww_show_website") == "true")
                text = text.replace("{bww_websiteUrl}", localStorage.getItem("bww_websiteUrl"));            
            else
                text = text.split(" We organiseren ook activiteiten in de buurt")[0]            
        }


        if(text.indexOf("{NumberOfElectricResults}") > -1 || text.indexOf("{NumberOfGasResults}") > -1 || text.indexOf("{NumberOfHeatGridResults}") > -1 || text.indexOf("{NumberOfIndependantResults}") > -1){
            var a = Context.getAdvice();
            var heatgrid = a.filter(x => x.route == "heat_grid");
            var fuel = a.filter(x => x.route == "fuel");
            var electric = a.filter(x => x.route == "all_electric");
            var independant = a.filter(x => x.route == "other");

            text = text.replace("{NumberOfElectricResults}",  electric.length);
            text = text.replace("{NumberOfGasResults}",  fuel.length);
            text = text.replace("{NumberOfHeatGridResults}",  heatgrid.length);
            text = text.replace("{NumberOfIndependantResults}",  independant.length);
        }

        return text;
    }

	return( 
        <section class="animate-appear relative pl-16 lg:pl-20 mb-8 md:mb-10 lg:mb-12 ">
            {(props.isBWW) ?
                <div class="absolute bottom-0 left-0 block h-12 w-12  lg:h-16 lg:w-16 border-2 border-neutral-200 rounded-full bg-bww-600 text-neutral-100 font-bold text-xs lg:text-base flex justify-center items-center ">
                    BWW
                </div> :
               <img class="absolute bottom-0 left-0 block h-12 w-12  lg:h-16 lg:w-16 border border-slate-400 rounded-full"
               src={(props.avatar?.length > 0 ? props.avatar : localStorage.getItem("bww_measurement_ambassadorPathToImage"))}
               alt="avatar" />
            }

            <div class=" relative p-4 md:p-6 lg:p-8 bg-white rounded-xl shadow-lg">
                <p class="text-slate-800  lg:text-lg" dangerouslySetInnerHTML={{ __html: replaceDynamics(props.text) }} />
                <div class="absolute bottom-4 lg:bottom-6 -left-2 w-4 h-4 bg-white rotate-45 shadow-lg"></div>
            </div>
        </section>
    ); 
}
  
export default ChatBubble; 