import { route } from "preact-router";
import { callApi } from "../callApi";

export class Auth {
    static storeSessionId(sessionId) {
        localStorage.setItem('bww_sessionId', sessionId);
    }

    static isUserAuthenticated() {        
        var sessionId = localStorage.getItem('bww_sessionId');
        if(sessionId == null)
            return false;

        var measurementId = localStorage.getItem('bww_measurementId');

        var isValidSesson = false;
        var apiUrl = localStorage.getItem('bww_api_url');
        
        var url = (apiUrl ?? "") + '/api/1/session/validate/'+measurementId+'/'+ sessionId;
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                var response = JSON.parse(this.responseText);
                isValidSesson = response.isValid;             
            }
        };
        xhr.open("GET", url, false); 
        xhr.send();

        return isValidSesson;
    }

    static logout() {
        var measurementId = localStorage.getItem('bww_measurementId');
        var sessionId = localStorage.getItem('bww_sessionId');
        callApi("Delete", `/api/1/session/kill/${measurementId}/${sessionId}`).then(x => {
            localStorage.removeItem('bww_sessionId');
            localStorage.removeItem('bww_realEstateObjects');
            localStorage.removeItem('bww_participatorId');
            localStorage.removeItem('bww_context');           
            sessionStorage.removeItem('bww_advice_context');
            sessionStorage.removeItem('bww_advices');
            sessionStorage.removeItem('bww_advices_currentBuildingLevels');
            sessionStorage.removeItem('bww_advices_selected');
            route("/");
        });
    }

    static getSessionId() {
        return localStorage.getItem('bww_sessionId');
    }
}