import AutenticatedRoute from "../../components/auth/PrivatePage";
import ChatConversation from "../../components/chat/conversation";
import Layout from "../../components/private/layout";

export default class PersonalBehaviorRoute extends AutenticatedRoute {
	render(){
		return(
			<Layout>
				<ChatConversation flow="personalBehavior" />
			</Layout>
		)		
	}
}