import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class AbstractQuestion extends Component {
    submit(value, goto, printableValue) {
        if (this.props.noSave === true) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goto,
                    isInPanel: this.props?.isInPanel ?? false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        }
        else {

            callApi("POST", "/api/1/answers/record", JSON.stringify({
                "measurementId": Context.measurmentId(),
                "participatorId": Context.participatorId(),
                "realEstateId": Context.realEstateId(),
                "sessionId": Context.sessionId(),
                "flowId": Context.flowId(),
                "questionCode": this.props.id,
                "value": value + "",
                "printableKey": this.props.friendlyKey,
                "printableValue": printableValue + ""
            })).then(() => {
                const e = new CustomEvent("bww_goTo", {
                    detail: {
                        goTo: goto,
                        isInPanel: this.props?.isInPanel ?? false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

                window.dispatchEvent(e)
            });
        }
    }
}                   