export default function HeatingIcon(props) {
    function getIcon() {
        var name = props.name.toLowerCase();

        if (name == "hr-ketel")
            return getHrKeter();
        if (name == "biomassa")
            return getBiomassa();
        if (name == "hybride wp")
            return getHybrideWp();
        if (name == "bodem wp")
            return getBodemWp();
        if (name == "lucht wp")
            return getLuchmWp();
        if (name == "pvt wp")
            return getPvtWp();
        if (name == "ht wp")
            return getHtWp();
        if (name == "warmtenet ht")
            return getWarmtenet();
        if (name == "warmtenet mt")
            return getWarmtenet();
        if (name == "warmtenet lt")
            return getWarmtenet();
    }

    function getHrKeter() {
        return (<svg version="1.1"  height="100%"  x="0px" y="0px" viewBox="-2.009 -2.5 113.311 96.09" enable-background="new -2.009 -2.5 113.311 96.09" >
            <g>
                <path d="M0,35.112c8.823-8.858,17.646-17.716,26.524-26.629c0.476,0.434,0.805,0.715,1.11,1.02
		c8.258,8.246,16.521,16.486,24.751,24.762c0.445,0.447,0.853,1.183,0.855,1.785c0.046,15.353,0.036,30.707,0.034,46.06
		c0,0.262-0.021,0.521-0.042,0.957c-17.773,0-35.502,0-53.233,0C0,67.081,0,51.096,0,35.112z M5.323,77.648
		c14.298,0,28.43,0,42.582,0c0.022-0.265,0.05-0.438,0.05-0.61c0.003-12.963,0.013-25.926-0.029-38.887
		c-0.002-0.524-0.371-1.159-0.761-1.552c-6.536-6.585-13.103-13.14-19.663-19.699c-0.272-0.272-0.576-0.512-0.878-0.778
		c-0.202,0.171-0.346,0.274-0.468,0.397C19.407,23.265,12.648,30.004,5.93,36.784c-0.372,0.375-0.58,1.079-0.583,1.63
		C5.315,51.109,5.323,63.805,5.323,76.5C5.323,76.851,5.323,77.202,5.323,77.648z" />
                <path
                    d="M93.511,87.595c-1.884-1.931-3.752-3.873-5.652-5.784c-1.166-1.172-1.209-1.619-0.086-2.738
		c1.695-1.689,3.369-3.404,5.125-5.029c0.387-0.358,1.104-0.588,1.608-0.506c0.321,0.053,0.705,0.768,0.757,1.219
		c0.113,1.004,0.035,2.031,0.035,3.231c2.646,0,5.202,0.114,7.739-0.046c1.346-0.086,2.106-1.953,1.446-3.271
		c-0.493-0.981-1.155-1.881-1.778-2.793c-0.606-0.889-0.612-1.631,0.39-2.219c0.114-0.068,0.221-0.15,0.329-0.225
		c2.787-1.879,2.604-1.326,3.886,0.822c0.967,1.623,2.191,3.123,2.217,5.17c0.045,3.867-3.002,7.211-6.868,7.355
		c-2.38,0.088-4.767,0.016-6.896,0.016c-0.429,1.732-0.809,3.267-1.186,4.799C94.222,87.595,93.864,87.595,93.511,87.595z" />
                <path d="M86.364,29.119c0,3.05,0,6.044,0,9.109c-1.573,0-3.01,0-4.508,0c0-7.139,0-14.22,0-21.397c1.438,0,2.871,0,4.47,0
		c0,2.756,0,5.521,0,8.354c2.89,0,5.618,0,8.493,0c0-2.777,0-5.545,0-8.38c1.58,0,3.017,0,4.518,0c0,7.149,0,14.23,0,21.404
		c-1.447,0-2.884,0-4.441,0c0-2.993,0-5.991,0-9.091C92.054,29.119,89.325,29.119,86.364,29.119z" />
                <path d="M80.421,68.459c-1.304,2.097-2.595,4.053-3.762,6.08c-0.908,1.576,0.046,3.332,1.871,3.492
		c1.099,0.096,2.215,0.021,3.32-0.027c1.106-0.049,1.69,0.466,1.695,1.543c0.004,0.791-0.016,1.617-0.236,2.363
		c-0.113,0.381-0.701,0.881-1.07,0.877c-1.898-0.014-3.869,0.119-5.68-0.341c-3.941-0.997-6.131-5.866-4.338-9.327
		c0.975-1.881,2.141-3.662,3.225-5.486c0.312-0.522,0.643-1.034,1.043-1.682c-0.965-0.584-1.877-1.033-2.658-1.65
		c-0.436-0.344-0.943-1.01-0.878-1.448c0.065-0.414,0.786-0.907,1.303-1.044c2.394-0.635,4.815-1.141,7.222-1.723
		c1.135-0.274,1.75,0.1,2.006,1.259c0.521,2.372,1.15,4.723,1.646,7.101c0.113,0.547,0.054,1.48-0.271,1.682
		c-0.43,0.266-1.262,0.137-1.803-0.096C82.174,69.654,81.388,69.053,80.421,68.459z" />
                <path d="M95.706,60.306c-1.114-1.646-2.055-3.31-3.264-4.747c-1.49-1.775-2.936-1.561-4.133,0.393
		c-1.549,2.53-2.078,2.586-4.412,0.645c-0.803-0.667-0.992-1.264-0.291-2.108c0.761-0.916,1.396-1.948,2.205-2.817
		c2.879-3.084,8.138-2.355,10.281,0.736c1.207,1.741,2.34,3.534,3.588,5.43c0.902-0.549,1.77-1.148,2.702-1.61
		c0.427-0.211,1.177-0.379,1.442-0.158c0.357,0.296,0.615,1.033,0.521,1.499c-0.533,2.598-1.183,5.172-1.799,7.753
		c-0.184,0.769-0.691,1.163-1.468,0.984c-2.756-0.638-5.519-1.257-8.244-2.003c-1.053-0.288-1.134-1.316-0.187-2.036
		C93.558,61.577,94.567,61.027,95.706,60.306z" />
                <path
                    d="M106.606,46.19c1.535,0,3.066,0,4.691,0c0,0.757,0,1.4,0,2.143c-2.684,0-5.4,0-8.111,0
		c-0.346-1.163-0.027-2.039,0.824-2.865c1.172-1.138,2.232-2.394,3.34-3.601c0.06-0.065,0.103-0.145,0.153-0.216
		c1.034-1.401,1.292-2.78,0.515-3.355c-1.227-0.908-2.24-0.114-3.229,0.578c-0.104,0.073-0.22,0.132-0.414,0.249
		c-0.426-0.498-0.847-0.991-1.263-1.479c1.371-1.916,4.484-2.56,6.406-1.352c1.639,1.03,2.115,3.141,0.951,5.041
		c-0.685,1.116-1.628,2.085-2.521,3.06c-0.472,0.513-1.085,0.895-1.636,1.336C106.412,45.883,106.511,46.037,106.606,46.19z" />
                <path d="M26.736,35.128c4.484,3.923,8.152,8.367,11.184,13.296c3.38,5.492,3.872,11.255,0.713,17.037
		c-2.162,3.957-5.552,6.334-10.092,6.668c-8.56,0.634-13.984-3.815-15.509-12.32c-0.71-3.963,0.323-7.496,2.231-10.818
		C18.271,43.759,22.259,39.304,26.736,35.128z M26.813,48.972c-2.594,2.971-5.127,5.672-6.403,9.2
		c-0.436,1.203-0.729,2.635-0.519,3.863c0.707,4.143,4.278,6.305,8.521,5.394c4.02-0.863,6.258-4.687,4.944-8.591
		C32.081,55.049,29.379,52.198,26.813,48.972z" />
            </g>
            <path class={getFill()} d="M55.938,33.228c-0.003-0.672-0.454-1.493-0.953-1.993c-9.08-9.143-18.197-18.247-27.311-27.355
	c-0.298-0.295-0.616-0.569-1.072-0.986C17.134,12.362,7.823,21.7-1.534,30.994c-0.855,0.851-1.165,1.698-1.165,2.877
	c0.034,17.282,0.023,34.565,0.023,51.849c0,0.645,0,1.288,0,1.876c7.73,0,15.412,0,23.075,0c1.253,0,2.511,0,3.764,0h5.398
	c2.451,0,4.89,0,7.338,0c6.334,0,12.669,0,18.99,0c0.042-0.265,0.078-0.385,0.078-0.505C55.974,69.138,55.988,51.184,55.938,33.228z
	 M53.308,81.195c0,0.477,0,0.951,0,1.523c-5.498,0-10.948,0-16.408,0c-2.443,0-4.881,0-7.338,0h-9.162v0.051
	c-6.763,0-13.428,0-20.086,0c0-15.899,0-31.8,0-47.7c8.776-8.815,17.552-17.631,26.388-26.506c0.514,0.482,0.868,0.799,1.204,1.134
	C36.06,17.84,44.204,25.993,52.38,34.114c0.688,0.681,0.95,1.351,0.948,2.31C53.3,51.348,53.308,66.273,53.308,81.195z" />
        </svg>)
    }

    function getBiomassa() {
        return (
            <svg   height="100%" version="1.1" x="0px" y="0px" viewBox="-1.173 5.155 112.776 83.962" enable-background="new -1.173 5.155 112.776 83.962">
<path class={getFill()} d="M56.952,35.224c-0.002-0.666-0.451-1.479-0.946-1.976c-9.001-9.063-18.039-18.087-27.07-27.116
	c-0.296-0.293-0.612-0.563-1.063-0.978c-9.386,9.386-18.616,18.643-27.89,27.856c-0.849,0.843-1.156,1.682-1.156,2.852
	c0.034,17.13,0.023,34.26,0.023,51.396c0,0.64,0,1.277,0,1.858c7.662,0,15.277,0,22.873,0c1.243,0,2.489,0,3.731,0h5.351
	c2.43,0,4.847,0,7.273,0c6.278,0,12.558,0,18.824,0c0.041-0.261,0.078-0.38,0.078-0.5C56.987,70.822,57.001,53.024,56.952,35.224z
	 M54.343,82.774c0,0.472,0,0.941,0,1.509c-5.449,0-10.851,0-16.264,0c-2.422,0-4.838,0-7.273,0h-9.083v0.051
	c-6.703,0-13.31,0-19.912,0c0-15.763,0-31.522,0-47.284c8.7-8.738,17.399-17.477,26.158-26.273c0.511,0.478,0.86,0.791,1.195,1.124
	c8.083,8.072,16.155,16.153,24.259,24.204c0.682,0.675,0.945,1.339,0.942,2.29C54.336,53.185,54.343,67.98,54.343,82.774z"/>
<path d="M1,36.365C9.911,27.417,18.824,18.47,27.792,9.466c0.479,0.439,0.813,0.722,1.122,1.031
	c8.343,8.329,16.689,16.653,25.002,25.012c0.448,0.453,0.861,1.195,0.863,1.803c0.046,15.509,0.036,31.017,0.034,46.524
	c0,0.264-0.022,0.526-0.043,0.969c-17.951,0-35.861,0-53.771,0C1,68.659,1,52.512,1,36.365z M49.395,79.368
	c0.021-0.322,0.047-0.539,0.047-0.758c0.002-13.048,0.012-26.097-0.03-39.146c-0.002-0.531-0.358-1.18-0.75-1.575
	c-6.633-6.685-13.296-13.338-19.955-19.995c-0.244-0.245-0.514-0.465-0.812-0.732c-0.384,0.363-0.712,0.658-1.023,0.97
	c-6.534,6.53-13.057,13.07-19.612,19.579c-0.659,0.654-0.904,1.305-0.902,2.228c0.03,12.646,0.021,25.291,0.021,37.935
	c0,0.479,0,0.959,0,1.494C20.767,79.368,35.009,79.368,49.395,79.368z"/>
<path d="M111.603,64.353c-0.639,1.999-2.092,3.192-3.863,4.206c-8.934,5.104-17.827,10.273-26.743,15.408
	c-1.679,0.966-3.474,1.38-5.208,0.27c-1.645-1.052-2.535-2.65-2.391-4.658c0.14-1.891,1.377-3.043,2.906-3.926
	c7.051-4.072,14.105-8.134,21.157-12.201c2.095-1.207,4.177-2.433,6.274-3.63c3.558-2.03,6.492-0.819,7.648,3.134
	c0.035,0.119,0.145,0.215,0.217,0.322C111.603,63.635,111.603,63.993,111.603,64.353z M81.515,80.034
	c-0.011-1.881-1.449-3.313-3.322-3.307c-1.854,0.007-3.303,1.48-3.295,3.35c0.012,1.828,1.539,3.358,3.34,3.346
	C80.04,83.409,81.522,81.874,81.515,80.034z"/>
<path d="M111.603,80.499c-1.768,5.313-5.381,5.112-8.843,2.873c-2.436-1.578-5.015-2.931-7.528-4.386
	c-0.377-0.218-0.742-0.452-1.233-0.752c3.082-1.782,6.012-3.496,8.974-5.151c0.297-0.166,0.863-0.098,1.188,0.078
	c1.612,0.867,3.182,1.814,4.774,2.721c1.401,0.799,2.123,2.101,2.668,3.541C111.603,79.782,111.603,80.14,111.603,80.499z
	 M106.592,76.727c-1.812,0.02-3.325,1.542-3.321,3.336c0.006,1.785,1.545,3.339,3.328,3.361c1.826,0.021,3.424-1.602,3.383-3.434
	C109.938,78.186,108.403,76.708,106.592,76.727z"/>
<path d="M90.759,65.39c-3.177,1.831-6.107,3.54-9.068,5.198c-0.272,0.152-0.791,0.101-1.084-0.06
	c-1.608-0.877-3.211-1.776-4.767-2.747c-2.489-1.554-3.137-4.027-1.761-6.465c1.365-2.421,3.891-3.186,6.46-1.781
	C83.91,61.377,87.212,63.349,90.759,65.39z"/>
<g>
	<path d="M40.953,53.234c-2.766-6.732-7.727-11.859-12.89-16.912c-4.812,4.549-9.141,9.309-12.191,15.082
		c-1.881,3.562-2.344,7.281-1.433,11.306c2.514,11.101,14.157,14.115,21.897,8.592C41.506,67.612,43.608,59.7,40.953,53.234z
		 M29.729,69.002c-4.314,0.929-7.892-1.217-8.628-5.412c-0.218-1.24,0.065-2.688,0.502-3.904c1.281-3.57,3.856-6.292,6.476-9.323
		c2.644,3.306,5.447,6.25,6.697,10.192C35.986,64.375,33.663,68.157,29.729,69.002z"/>
</g>
</svg>
        );
    }

    function getHybrideWp() {
        return (
            <svg version="1.1"  height="100%"  x="0px" y="0px" viewBox="-2.392 -1.917 113.69 94.761" enable-background="new -2.392 -1.917 113.69 94.761">
                <path class={getFill()} d="M-2.391,27.54C7.233,17.74,16.857,7.943,26.542-1.917c0.518,0.481,0.877,0.791,1.211,1.129
c9.012,9.121,18.023,18.236,27.002,27.389c0.482,0.497,0.928,1.31,0.93,1.975c0.051,16.983,0.041,33.966,0.039,50.947
c0,0.289-0.025,0.578-0.047,1.061c-19.387,0-38.728,0-58.069,0C-2.391,62.904-2.391,45.222-2.391,27.54z M49.873,74.628
c0.021-0.352,0.051-0.588,0.051-0.83c0.002-14.287,0.012-28.577-0.033-42.866c-0.002-0.581-0.387-1.292-0.811-1.724
c-7.164-7.321-14.357-14.606-21.549-21.896c-0.264-0.268-0.557-0.51-0.877-0.801c-0.415,0.397-0.77,0.72-1.104,1.062
c-7.057,7.151-14.101,14.313-21.179,21.44c-0.712,0.717-0.977,1.43-0.975,2.44C3.428,45.3,3.418,59.15,3.418,72.994
c0,0.523,0,1.051,0,1.635C18.957,74.628,34.336,74.628,49.873,74.628z" />
                <g>
                    <path d="M0,29.249c8.776-8.815,17.553-17.631,26.388-26.506c0.514,0.482,0.869,0.799,1.205,1.134
c8.154,8.143,16.298,16.296,24.474,24.417c0.687,0.681,0.95,1.352,0.948,2.31c-0.028,14.924-0.02,29.848-0.02,44.771
c0,0.476,0,0.951,0,1.521c-7.957,0-15.799,0-23.746,0c0,3.557,0,7.016,0,10.564c19.329,0,38.686,0,58.122,0
c0-3.461,0-6.92,0-10.479c-3.464,0-6.892,0-10.46,0c0-6.207,0-12.318,0-18.576c-3.657,0.109-5.934,2.133-7.818,4.857
c-1.393-6.025,2.327-9.43,7.695-10.286c0-2.582,0-5.169,0-7.835c-3.532,0.157-5.822,2.131-7.67,4.846
c-1.444-5.681,2.059-9.403,7.697-10.237c0-3.419,0-6.854,0-10.393c8.842,0,17.598,0,26.492,0c0,3.439,0,6.903,0,10.45
c3.736,0.141,6.016,2.377,7.99,5.074c0,1.767,0,3.534,0,5.3c-0.373-0.478-0.734-0.964-1.121-1.429
c-1.752-2.1-3.859-3.522-6.775-3.6c0,2.678,0,5.258,0,7.889c2.18,0.154,4.064,0.928,5.59,2.422c0.836,0.82,1.543,1.773,2.308,2.666
c0,1.768,0,3.533,0,5.301c-0.374-0.479-0.735-0.965-1.124-1.43c-1.753-2.102-3.857-3.527-6.854-3.598c0,6.215,0,12.323,0,18.571
c-3.584,0-7.041,0-10.572,0c0,5.385,0,10.627,0,15.868c-22.967,0-45.933,0-68.898,0c0-5.274,0-10.551,0-15.898
c-8.067,0-15.959,0-23.851,0C0,61.049,0,45.148,0,29.249z M26.465,10.301c-6.847,6.848-13.581,13.6-20.346,20.32
c-0.618,0.615-0.842,1.227-0.84,2.08c0.023,12.496,0.016,24.992,0.016,37.488c0,0.466,0,0.931,0,1.356c14.248,0,28.306,0,42.368,0
c0.019-0.28,0.039-0.455,0.039-0.631c0.002-12.85,0.012-25.699-0.031-38.548c-0.002-0.563-0.371-1.248-0.783-1.664
C40.421,24.181,33.92,17.693,27.424,11.2C27.148,10.925,26.855,10.667,26.465,10.301z M98.054,34.639c-5.402,0-10.621,0-15.908,0
c0,1.325,0,2.554,0,3.784c0,3.335,0.021,3.299,2.885,5.098c3.41,2.143,6.777,2.157,10.158-0.022c0.776-0.5,1.576-0.972,2.313-1.529
c0.266-0.202,0.521-0.61,0.529-0.93C98.075,38.933,98.054,36.823,98.054,34.639z M82.211,71.583c5.285,0,10.504,0,15.843,0
c0-1.908,0-3.748,0-5.588c0-1.863,0-3.726,0-5.77c-2.656,1.594-4.896,3.47-7.963,3.479c-3.092,0.01-5.337-1.965-7.88-3.416
C82.211,64.155,82.211,67.84,82.211,71.583z M82.146,46.705c0,1.881,0,3.247,0,4.613c0,3.829,0.006,3.819,3.385,5.742
c2.664,1.515,5.402,1.88,8.195,0.462c1.33-0.676,2.576-1.521,3.836-2.328c0.223-0.143,0.465-0.463,0.467-0.705
c0.035-2.439,0.021-4.882,0.021-7.785C92.688,51.551,87.506,51.542,82.146,46.705z" />
                    <path d="M26.631,29.258c4.71,4.314,8.869,9,11.814,14.595c3.177,6.034,2.866,12.06-1.336,17.508
c-3.282,4.257-7.97,5.626-13.126,4.669c-5.476-1.02-8.81-4.527-10.479-9.855c-1.612-5.148-0.506-9.77,2.245-14.118
C18.734,37.338,22.359,33.131,26.631,29.258z M26.663,43.032c-2.585,3-5.123,5.679-6.382,9.198
c-0.429,1.196-0.699,2.624-0.482,3.847c0.724,4.08,4.222,6.071,8.39,5.391c3.361-0.551,6.319-4.328,5.108-8.289
C32.092,49.239,29.285,46.311,26.663,43.032z" />
                </g>
            </svg>)
    }

    function getBodemWp() {
        return (
            <svg version="1.1" x="0px" y="0px" height="100%" viewBox="-2.718 -4.502 114.469 100.829" enable-background="new -2.718 -4.502 114.469 100.829" >
                <path class={getFill()} d="M-2.717,24.954C6.906,15.154,16.53,5.357,26.216-4.502c0.518,0.481,0.877,0.791,1.212,1.129
c9.011,9.121,18.022,18.236,27.001,27.389c0.482,0.497,0.928,1.31,0.931,1.975c0.051,16.983,0.04,33.967,0.038,50.947
c0,0.289-0.025,0.578-0.047,1.061c-19.387,0-38.728,0-58.069,0C-2.717,60.318-2.717,42.636-2.717,24.954z M49.546,72.043
c0.022-0.352,0.051-0.588,0.051-0.83c0.002-14.287,0.013-28.577-0.033-42.866c-0.001-0.581-0.387-1.292-0.81-1.724
c-7.164-7.321-14.358-14.606-21.55-21.896c-0.264-0.268-0.556-0.51-0.877-0.801c-0.415,0.397-0.77,0.72-1.104,1.062
c-7.057,7.151-14.101,14.313-21.179,21.44c-0.712,0.717-0.977,1.43-0.975,2.44c0.032,13.847,0.023,27.697,0.023,41.541
c0,0.523,0,1.051,0,1.635C18.63,72.043,34.01,72.043,49.546,72.043z" />
                <path d="M104.136,69.624c-0.177,0.003-0.338-0.009-0.494-0.026c-1.041-1.894-3.055-3.177-5.367-3.177
c-2.27,0-4.246,1.238-5.305,3.072c-0.728,0.124-1.478,0.112-2.262,0.116c-0.936,0.007-1.879,0.08-2.803-0.026
c-0.098-0.012-0.202-0.033-0.305-0.056c-1.055-1.854-3.043-3.106-5.326-3.106c-2.297,0-4.295,1.267-5.344,3.138
c-0.063,0.006-0.125,0.021-0.186,0.022c-3.872,0.06-7.746,0.03-11.62,0.03c-0.468,0-0.937,0-1.502,0c0,5.436,0,10.673,0,15.941
c-11.601,0-23.101,0-34.583,0c0-3.576,0-7.063,0-10.646c8.012,0,15.919,0,23.894,0c0.018-0.354,0.042-0.612,0.042-0.87
c0.002-15.404,0.012-30.809-0.035-46.214c-0.002-0.606-0.403-1.348-0.849-1.795C43.837,17.723,35.547,9.453,27.263,1.178
C26.956,0.873,26.624,0.591,25.978,0c-0.335,0.5-0.568,1.009-0.939,1.38C16.926,9.512,8.8,17.631,0.668,25.744
c-0.33,0.329-0.743,0.575-1.117,0.859c0,16.117,0,32.235,0,48.352c7.958,0,15.915,0,24.016,0c0,5.354,0,10.62,0,15.957
c15.2,0,30.256,0,45.411,0c0-5.297,0-10.53,0-15.943c2.615,0,5.15-0.019,7.681,0.018c0.595,1.367,1.674,2.47,3.021,3.102
c0.004,0.029,0.012,0.061,0.012,0.084c0.002,6.053,0.002,12.104,0.002,18.154c1.781,0,3.563,0,5.344,0
c0-6.008-0.002-12.014,0.001-18.02c0-0.104,0.03-0.215,0.05-0.322c1.229-0.639,2.215-1.678,2.781-2.948
c0.173-0.048,0.345-0.08,0.508-0.08c0.757-0.001,1.514,0,2.27,0c0.924,0,1.545-0.001,2.031,0.067
c0.602,1.354,1.68,2.447,3.021,3.072c0.004,0.071,0.009,0.145,0.009,0.211c0.022,6.006,0.015,12.012,0.015,18.02
c1.781,0,3.563,0,5.344,0c0-6.008-0.002-12.016,0-18.021c0-0.106,0.031-0.223,0.053-0.335c1.215-0.638,2.188-1.671,2.749-2.929
c0.187-0.053,0.366-0.084,0.541-0.084c2.448-0.002,4.896-0.001,7.343-0.001c0-1.781,0-3.563,0-5.344
C109.21,69.611,106.673,69.59,104.136,69.624z M4.895,69.551c0-0.533,0-1.01,0-1.484c0-12.553,0.009-25.103-0.02-37.654
c-0.002-0.916,0.239-1.564,0.894-2.215C12.307,21.706,18.813,15.182,25.33,8.67c0.277-0.277,0.574-0.537,0.922-0.86
c0.327,0.296,0.627,0.544,0.901,0.818c6.548,6.544,13.102,13.083,19.622,19.655c0.42,0.423,0.823,1.104,0.824,1.667
c0.047,12.951,0.036,25.903,0.034,38.854c0,0.216-0.025,0.433-0.045,0.747C33.308,69.551,19.171,69.551,4.895,69.551z" />
            </svg>)
    }

    function getLuchmWp() {
        return (
            <svg version="1.1" x="0px" y="0px" height="100%"  viewBox="-2.469 -2.375 113.768 95.475" enable-background="new -2.469 -2.375 113.768 95.475">
                <path class={getFill()} d="M-2.468,27.081C7.156,17.282,16.78,7.485,26.465-2.375c0.518,0.481,0.877,0.791,1.211,1.129
C36.688,7.875,45.7,16.99,54.679,26.143c0.482,0.497,0.928,1.31,0.93,1.975c0.051,16.983,0.041,33.967,0.039,50.947
c0,0.289-0.025,0.578-0.047,1.061c-19.387,0-38.728,0-58.069,0C-2.468,62.446-2.468,44.763-2.468,27.081z M49.796,74.17
c0.021-0.352,0.051-0.588,0.051-0.83c0.002-14.287,0.012-28.577-0.033-42.866c-0.002-0.581-0.387-1.292-0.811-1.724
c-7.164-7.321-14.357-14.606-21.549-21.896c-0.264-0.268-0.557-0.51-0.877-0.801c-0.415,0.397-0.77,0.72-1.104,1.062
c-7.057,7.151-14.101,14.313-21.179,21.44c-0.712,0.717-0.977,1.43-0.975,2.44c0.032,13.847,0.023,27.697,0.023,41.541
c0,0.523,0,1.051,0,1.635C18.879,74.17,34.259,74.17,49.796,74.17z" />
                <g>
                    <path d="M0,29.5C8.778,20.686,17.557,11.873,26.394,3c0.507,0.476,0.862,0.792,1.199,1.128
c8.153,8.143,16.298,16.296,24.474,24.417c0.686,0.681,0.95,1.352,0.948,2.31c-0.029,14.923-0.02,29.847-0.02,44.771
c0,0.476,0,0.951,0,1.523c-7.957,0-15.799,0-23.746,0c0,3.555,0,7.014,0,10.564c19.329,0,38.684,0,58.122,0
c0-3.461,0-6.92,0-10.479c-3.464,0-6.892,0-10.46,0c0-6.208,0-12.317,0-18.575c-3.657,0.108-5.936,2.131-7.819,4.855
c-1.394-6.025,2.329-9.43,7.695-10.285c0-2.494,0-4.993,0-7.57c-3.531,0.156-5.821,2.132-7.67,4.847
c-1.443-5.685,2.062-9.404,7.698-10.238c0-3.507,0-7.029,0-10.657c8.842,0,17.598,0,26.492,0c0,3.528,0,7.08,0,10.715
c3.736,0.142,6.016,2.378,7.99,5.074c0,1.767,0,3.534,0,5.302c-0.374-0.478-0.734-0.963-1.121-1.429
c-1.752-2.1-3.859-3.523-6.775-3.601c0,2.589,0,5.083,0,7.625c2.179,0.154,4.064,0.927,5.59,2.422
c0.836,0.82,1.543,1.773,2.308,2.666c0,1.767,0,3.533,0,5.3c-0.374-0.478-0.735-0.964-1.125-1.431
c-1.752-2.1-3.856-3.525-6.853-3.596c0,6.215,0,12.322,0,18.571c-3.586,0-7.041,0-10.572,0c0,5.384,0,10.627,0,15.87
c-22.967,0-45.933,0-68.899,0c0-5.275,0-10.551,0-15.9c-8.066,0-15.958,0-23.85,0C0,61.299,0,45.399,0,29.5z M26.499,10.596
c-0.241,0.212-0.443,0.373-0.625,0.554c-6.653,6.647-13.313,13.29-19.935,19.968c-0.373,0.378-0.616,1.063-0.618,1.605
C5.287,45.352,5.295,57.981,5.295,70.609c0,0.417,0,0.835,0,1.224c14.242,0,28.268,0,42.365,0c0.017-0.322,0.042-0.578,0.042-0.834
c0.002-12.806,0.011-25.609-0.029-38.416c-0.001-0.526-0.333-1.174-0.717-1.561c-6.529-6.583-13.093-13.134-19.65-19.689
C27.063,11.09,26.8,10.869,26.499,10.596z M82.146,34.84c0,1.279,0,2.42,0,3.562c0,4.11,0.004,4.103,3.684,6.082
c2.33,1.252,4.764,1.689,7.225,0.579c1.594-0.718,3.057-1.726,4.563-2.631c0.193-0.117,0.41-0.396,0.414-0.603
c0.029-2.317,0.019-4.637,0.019-6.989C92.688,34.84,87.502,34.84,82.146,34.84z M82.21,71.814c5.327,0,10.546,0,15.777,0
c0-3.769,0-7.444,0-11.157c-8.373,4.6-7.723,4.405-15.777,0C82.21,64.37,82.21,68.045,82.21,71.814z M82.146,47.2
c0,1.917,0,3.317,0,4.716c0,3.344,0.021,3.308,2.885,5.106c3.416,2.146,6.785,2.155,10.168-0.028c0.74-0.479,1.5-0.933,2.205-1.459
c0.279-0.207,0.613-0.576,0.621-0.879c0.052-2.318,0.027-4.637,0.027-7.423C92.724,52.03,87.542,52.098,82.146,47.2z" />
                </g>
            </svg>
        )
    }

    function getPvtWp() {
        return (
            <svg version="1.1" x="0px" y="0px"  height="100%"  viewBox="-2.009 -2.5 113.311 96.09" enable-background="new -2.009 -2.5 113.311 96.09">
                <path class={getFill()} d="M-2.008,26.956C7.615,17.157,17.239,7.36,26.925-2.5c0.518,0.481,0.877,0.791,1.211,1.129
C37.148,7.75,46.16,16.865,55.138,26.018c0.482,0.497,0.928,1.31,0.93,1.975c0.051,16.983,0.041,33.967,0.039,50.947
c0,0.289-0.025,0.578-0.047,1.061c-19.387,0-38.728,0-58.069,0C-2.008,62.321-2.008,44.638-2.008,26.956z M50.255,74.045
c0.021-0.352,0.051-0.588,0.051-0.83c0.002-14.287,0.012-28.577-0.033-42.866c-0.002-0.581-0.387-1.292-0.811-1.724
c-7.164-7.321-14.357-14.606-21.549-21.896c-0.264-0.268-0.557-0.51-0.877-0.801c-0.415,0.397-0.77,0.72-1.104,1.062
c-7.057,7.151-14.101,14.313-21.179,21.44c-0.712,0.717-0.977,1.43-0.975,2.44C3.81,44.716,3.801,58.567,3.801,72.411
c0,0.523,0,1.051,0,1.635C19.339,74.045,34.718,74.045,50.255,74.045z" />
                <g>
                    <path d="M0,28.599C8.97,19.593,17.94,10.587,26.972,1.52c0.517,0.484,0.879,0.807,1.222,1.149
c8.332,8.32,16.654,16.651,25.008,24.95c0.699,0.694,0.977,1.375,0.975,2.358c-0.03,15.249-0.021,30.498-0.021,45.747
c0,0.485,0,0.974,0,1.558c-8.031,0-15.955,0-23.989,0c0,3.631,0,7.168,0,10.801c19.736,0,39.516,0,59.385,0
c0-3.52,0-7.053,0-10.688c-5.324,0-10.632,0-16.048,0c0-16.267,0-32.432,0-48.795c12.574,0,25.187,0,37.798,0
c0,16.248,0,32.495,0,48.743c-5.391,0-10.779,0-16.248,0c0,5.533,0,10.892,0,16.248c-23.47,0-46.939,0-70.409,0
c0-5.391,0-10.781,0-16.248c-8.333,0-16.488,0-24.643,0C0,61.094,0,44.847,0,28.599z M48.699,71.87
c0.021-0.313,0.047-0.531,0.047-0.75c0.003-13.129,0.012-26.261-0.029-39.392c-0.002-0.536-0.354-1.192-0.747-1.589
c-6.675-6.727-13.38-13.421-20.081-20.121c-0.247-0.247-0.518-0.47-0.821-0.743c-0.382,0.36-0.713,0.656-1.026,0.969
c-6.574,6.571-13.139,13.152-19.734,19.702c-0.661,0.656-0.918,1.308-0.916,2.239c0.03,12.725,0.021,25.448,0.021,38.174
c0,0.483,0,0.966,0,1.511C19.884,71.87,34.217,71.87,48.699,71.87z M84.17,34.076c-1.849,0-3.544,0-5.294,0c0,3.148,0,6.23,0,9.331
c1.809,0,3.536,0,5.294,0C84.17,40.267,84.17,37.218,84.17,34.076z M94.961,43.435c0-3.187,0-6.269,0-9.318
c-1.825,0-3.556,0-5.26,0c0,3.158,0,6.208,0,9.318C91.467,43.435,93.164,43.435,94.961,43.435z M105.819,34.099
c-1.813,0-3.543,0-5.258,0c0,3.171,0,6.253,0,9.334c1.797,0,3.494,0,5.258,0C105.819,40.319,105.819,37.27,105.819,34.099z
M84.151,58.331c0-3.209,0-6.258,0-9.342c-1.803,0-3.53,0-5.258,0c0,3.168,0,6.25,0,9.342
C80.688,58.331,82.386,58.331,84.151,58.331z M94.987,58.334c0-3.205,0-6.254,0-9.35c-1.799,0-3.526,0-5.266,0
c0,3.164,0,6.245,0,9.35C91.513,58.334,93.209,58.334,94.987,58.334z M100.513,58.284c1.871,0,3.602,0,5.305,0
c0-3.158,0-6.207,0-9.295c-1.801,0-3.528,0-5.305,0C100.513,52.122,100.513,55.17,100.513,58.284z M84.123,71.875
c0-2.754,0-5.385,0-8.012c-1.798,0-3.494,0-5.248,0c0,2.695,0,5.325,0,8.012C80.647,71.875,82.345,71.875,84.123,71.875z
M94.961,71.87c0-2.736,0-5.367,0-7.965c-1.825,0-3.556,0-5.26,0c0,2.707,0,5.305,0,7.965C91.467,71.87,93.164,71.87,94.961,71.87z
M100.576,63.829c0,2.67,0,5.332,0,8.012c1.774,0,3.503,0,5.209,0c0-2.723,0-5.354,0-8.012
C104.019,63.829,102.354,63.829,100.576,63.829z" />
                </g>
            </svg>)
    }

    function getHtWp() {
        return (
            <svg version="1.1" x="0px" y="0px"  height="100%"  viewBox="-2.009 -2.5 113.311 96.09" enable-background="new -2.009 -2.5 113.311 96.09">
                <g>
                    <g>
                        <path fill="none" d="M82.146,46.89c0,1.885,0,3.251,0,4.618c0,3.775,0.006,3.763,3.289,5.664c2.645,1.533,5.377,1.966,8.188,0.565
			c1.374-0.684,2.652-1.563,3.959-2.379c0.199-0.124,0.441-0.386,0.443-0.585c0.031-2.48,0.021-4.963,0.021-7.962
			C92.674,51.811,87.492,51.659,82.146,46.89z" />
                        <path fill="none" d="M82.228,60.495c0,3.868,0,7.553,0,11.281c5.27,0,10.457,0,15.767,0c0-3.803,0-7.48,0-11.329
			c-2.584,1.511-4.845,3.443-7.949,3.431C86.978,63.864,84.75,61.885,82.228,60.495z" />
                        <path
                            d="M29.249,77.069c7.947,0,15.789,0,23.745,0c0-0.572,0-1.047,0-1.523c0-14.922-0.007-29.847,0.021-44.771
			c0.002-0.959-0.261-1.629-0.948-2.31c-8.176-8.121-16.32-16.274-24.474-24.417c-0.337-0.335-0.691-0.652-1.205-1.134
			C17.552,11.789,8.776,20.605,0,29.42c0,15.9,0,31.8,0,47.7c7.892,0,15.784,0,23.85,0c0,1.618,0,3.221,0,4.827
			c1.8,0,3.601,0,5.399,0C29.249,80.329,29.249,78.709,29.249,77.069z M5.295,71.717c0-0.426,0-0.891,0-1.356
			c0-12.497,0.007-24.993-0.017-37.489c0-0.853,0.224-1.466,0.842-2.08c6.765-6.72,13.498-13.472,20.344-20.319
			c0.33,0.302,0.56,0.5,0.775,0.713c6.589,6.586,13.182,13.169,19.747,19.779c0.36,0.361,0.687,0.956,0.689,1.442
			c0.036,12.982,0.025,25.964,0.021,38.945c0,0.087-0.026,0.174-0.056,0.365C33.599,71.717,19.542,71.717,5.295,71.717z" />
                        <path fill="none" d="M82.146,34.804c0,2.197-0.008,4.307,0.01,6.416c0.003,0.239,0.064,0.591,0.227,0.699
			c2.262,1.509,4.432,3.238,7.314,3.397c3.244,0.179,5.629-1.739,8.113-3.388c0.162-0.108,0.231-0.457,0.233-0.695
			c0.019-2.111,0.011-4.222,0.011-6.429C92.665,34.804,87.448,34.804,82.146,34.804z" />
                        <path class={getFill()} d="M0,77.12c0-15.899,0-31.8,0-47.7c8.776-8.815,17.552-17.631,26.388-26.506
			c0.514,0.482,0.868,0.799,1.205,1.134c8.154,8.143,16.298,16.296,24.474,24.417c0.687,0.681,0.95,1.351,0.948,2.31
			c-0.028,14.923-0.021,29.849-0.021,44.771c0,0.477,0,0.951,0,1.523c-7.956,0-15.798,0-23.745,0c0,1.641,0,3.26,0,4.878
			c8.794,0,17.564,0,26.327,0c0.042-0.265,0.079-0.385,0.079-0.505c0.005-17.953,0.021-35.908-0.03-53.864
			c-0.002-0.672-0.454-1.493-0.953-1.993c-9.08-9.143-18.197-18.247-27.31-27.355c-0.298-0.295-0.616-0.569-1.072-0.986
			C16.821,6.713,7.51,16.051-1.847,25.345c-0.855,0.851-1.165,1.698-1.165,2.877c0.034,17.282,0.023,34.565,0.023,51.849
			c0,0.645,0,1.288,0,1.876c8.995,0,17.931,0,26.839,0c0-1.606,0-3.209,0-4.827C15.784,77.12,7.892,77.12,0,77.12z" />
                        <path d="M108.991,55.638c-1.525-1.495-3.411-2.269-5.59-2.423c0-2.631,0-5.212,0-7.889c2.916,0.078,5.023,1.5,6.773,3.6
			c0.389,0.466,0.749,0.951,1.123,1.428c0-1.766,0-3.534,0-5.3c-1.977-2.697-4.254-4.933-7.99-5.074c0-3.547,0-7.011,0-10.45
			c-8.895,0-17.65,0-26.492,0c0,3.539,0,6.973,0,10.392c-5.639,0.834-9.142,4.557-7.697,10.239c1.848-2.716,4.137-4.689,7.669-4.847
			c0,2.665,0,5.253,0,7.834c-5.367,0.856-9.088,4.263-7.695,10.286c1.884-2.725,4.162-4.747,7.82-4.855c0,6.258,0,12.367,0,18.575
			c3.567,0,6.995,0,10.459,0c0,3.56,0,7.017,0,10.479c-19.438,0-38.792,0-58.122,0c0-1.912,0-3.799,0-5.687
			c-1.798,0-3.599,0-5.399,0c0,3.704,0,7.393,0,11.071c22.966,0,45.933,0,68.899,0c0-5.242,0-10.484,0-15.869
			c3.529,0,6.986,0,10.572,0c0-6.249,0-12.357,0-18.57c2.996,0.069,5.101,1.496,6.853,3.596c0.39,0.466,0.75,0.951,1.125,1.431
			c0-1.767,0-3.532,0-5.301C110.534,57.411,109.827,56.458,108.991,55.638z M97.994,71.776c-5.31,0-10.497,0-15.767,0
			c0-3.729,0-7.413,0-11.281c2.522,1.391,4.75,3.369,7.817,3.383c3.104,0.013,5.365-1.92,7.949-3.431
			C97.994,64.295,97.994,67.973,97.994,71.776z M98.024,54.774c-0.002,0.199-0.244,0.461-0.443,0.585
			c-1.307,0.815-2.585,1.695-3.959,2.379c-2.811,1.4-5.543,0.968-8.188-0.565c-3.283-1.901-3.289-1.889-3.289-5.664
			c0-1.367,0-2.733,0-4.618c5.347,4.769,10.528,4.921,15.9-0.078C98.046,49.811,98.056,52.293,98.024,54.774z M98.043,41.233
			c-0.002,0.238-0.071,0.587-0.233,0.695c-2.484,1.649-4.869,3.567-8.113,3.388c-2.883-0.159-5.053-1.888-7.314-3.397
			c-0.162-0.108-0.224-0.46-0.227-0.699c-0.018-2.109-0.01-4.219-0.01-6.416c5.303,0,10.52,0,15.908,0
			C98.054,37.011,98.062,39.122,98.043,41.233z" />
                    </g>
                    <path d="M29.145,43.994c0,3.797,0.017,7.594-0.013,11.391c-0.006,0.797,0.17,1.336,0.844,1.891
		c2.327,1.914,2.422,5.491,0.309,7.652c-2.072,2.117-5.551,2.105-7.596-0.028c-2.105-2.196-1.976-5.711,0.364-7.651
		c0.615-0.511,0.824-0.998,0.821-1.771c-0.026-7.639-0.012-15.277-0.014-22.916c0-1.159,0.225-2.221,1.338-2.824
		c1.894-1.024,3.912,0.219,3.935,2.467C29.173,36.135,29.145,40.064,29.145,43.994z" />
                </g>
            </svg>
        )
    }

    function getWarmtenet() {
        return (
            <svg version="1.1" x="0px" y="0px" height="100%" viewBox="-3.012 -2.757 114.311 95.775"
				 enable-background="new -3.012 -2.757 114.311 95.775">
			<path class={getFill()}  d="M51.582,29.764c-0.001-0.549-0.371-1.221-0.778-1.63c-7.428-7.478-14.885-14.925-22.338-22.375
				c-0.244-0.242-0.505-0.464-0.877-0.806c-7.745,7.745-15.361,15.383-23.014,22.986c-0.7,0.695-0.954,1.388-0.954,2.354
				c0.028,14.135,0.019,28.27,0.019,42.41c0,0.528,0,1.055,0,1.533c6.323,0,12.606,0,18.875,0c1.026,0,2.054,0,3.079,0h4.417
				c2.005,0,4,0,6.001,0c5.18,0,10.362,0,15.533,0c0.034-0.215,0.064-0.313,0.064-0.412C51.612,59.137,51.623,44.451,51.582,29.764z
				 M49.431,69c0,0.39,0,0.777,0,1.245c-4.497,0-8.954,0-13.421,0c-1.999,0-3.992,0-6.001,0h-7.495v0.042c-5.531,0-10.983,0-16.43,0
				c0-13.008,0-26.011,0-39.018c7.179-7.21,14.357-14.421,21.584-21.68c0.421,0.394,0.71,0.653,0.985,0.927
				c6.67,6.661,13.331,13.329,20.019,19.972c0.563,0.557,0.779,1.105,0.777,1.89C49.425,44.585,49.431,56.793,49.431,69z"/>
			<path d="M85.364,79.9c-0.178,0-0.438-0.091-0.723-0.234c-0.425-0.748-1.046-1.366-1.794-1.79c-0.041-2.242-0.016-4.489-0.016-6.813
				c6.666,0,13.181,0,19.803,0c0-0.4,0-0.721,0-1.041c0-12.543,0.008-25.088-0.03-37.631c-0.002-0.539-0.305-1.206-0.689-1.592
				c-6.796-6.845-13.627-13.655-20.455-20.471c-0.254-0.252-0.525-0.488-0.83-0.77c-0.231,0.204-0.428,0.357-0.604,0.533
				c-6.954,6.946-13.915,13.887-20.839,20.864c-0.35,0.354-0.541,1.021-0.543,1.543c-0.028,12.47-0.023,24.94-0.023,37.412
				c0,0.356,0,0.714,0,1.19c6.647,0,13.188,0,19.81,0c0,2.301,0.013,4.483-0.009,6.664c-0.91,0.455-1.653,1.191-2.112,2.101
				c-0.071,0.021-0.139,0.034-0.196,0.034c-14.599,0.003-29.195,0.003-43.794,0c-0.109,0-0.275-0.063-0.47-0.165
				c-0.424-0.777-1.06-1.419-1.827-1.855c-0.032-2.244-0.015-4.491-0.015-6.815c6.667,0,13.181,0,19.801,0c0-0.4,0-0.722,0-1.041
				c0-12.545,0.01-25.088-0.029-37.631c-0.002-0.539-0.305-1.205-0.689-1.592c-6.797-6.845-13.63-13.655-20.454-20.472
				c-0.255-0.253-0.526-0.487-0.831-0.769c-0.23,0.203-0.428,0.357-0.605,0.534c-6.955,6.946-13.916,13.886-20.838,20.863
				c-0.352,0.353-0.542,1.021-0.543,1.543c-0.029,12.47-0.022,24.94-0.022,37.412c0,0.356,0,0.714,0,1.189c6.646,0,13.187,0,19.81,0
				c0,2.17,0.001,4.217-0.002,6.263c0,0.138-0.021,0.279-0.058,0.422c-0.884,0.452-1.605,1.172-2.057,2.057
				c-0.143,0.038-0.285,0.058-0.422,0.058c-8.693,0-17.386-0.002-26.079-0.004c0,1.468,0,2.936,0,4.402
				c8.708-0.001,17.416-0.012,26.124,0.024c0.19,0.002,0.384,0.071,0.575,0.169c0.835,1.336,2.316,2.229,4.008,2.229
				c1.704,0,3.194-0.906,4.025-2.257c0.282-0.105,0.599-0.156,0.889-0.156c11.984-0.016,23.97-0.014,35.953-0.014
				c2.491,0,4.986-0.023,7.476,0.025c0.149,0.004,0.3,0.051,0.448,0.12c0.83,1.366,2.327,2.28,4.042,2.28
				c1.707,0,3.198-0.91,4.029-2.268c0.244-0.091,0.511-0.146,0.76-0.146c8.635-0.016,17.269-0.01,25.905-0.01c0-1.468,0-2.935,0-4.401
				C102.603,79.897,93.983,79.899,85.364,79.9z M10.19,66.606c0-0.353,0-0.739,0-1.124c0-10.38-0.005-20.759,0.017-31.138
				c0-0.468,0.095-1.08,0.394-1.38c5.678-5.729,11.393-11.422,17.188-17.211c0.309,0.287,0.551,0.502,0.781,0.731
				c5.395,5.393,10.794,10.781,16.166,16.198c0.344,0.346,0.65,0.916,0.652,1.382c0.037,10.671,0.026,21.345,0.025,32.018
				c0,0.146-0.017,0.293-0.032,0.525C33.701,66.606,22.025,66.606,10.19,66.606z M63.016,66.653c0-0.416,0-0.804,0-1.193
				c0-10.38,0.006-20.758-0.017-31.137c0-0.716,0.207-1.216,0.714-1.721c5.388-5.352,10.747-10.726,16.118-16.093
				c0.229-0.231,0.473-0.452,0.769-0.735c0.256,0.226,0.506,0.423,0.731,0.647c5.421,5.418,10.847,10.833,16.243,16.277
				c0.34,0.344,0.637,0.918,0.638,1.387c0.036,10.635,0.027,21.271,0.025,31.908c0,0.179-0.017,0.361-0.033,0.66
				C86.457,66.653,74.809,66.653,63.016,66.653z"/>
			</svg>
        )
    }


    function getFill() {
        var label = props.label.toUpperCase();
        if (label == "A" || label[0] == "A")
            return "fill-lime-800";
        if (label == "B")
            return "fill-lime-600";
        if (label == "C")
            return "fill-amber-400";
        if (label == "D")
            return "fill-amber-600";
        if (label == "E")
            return "fill-red-500";
        if (label == "F")
            return "fill-red-800";
        if (label == "G")
            return "fill-red-900";
    }

    return (
        <div className={props.clasName}>
            {getIcon()}
        </div>
    );
}
