import { Component } from 'preact';
import { Router } from 'preact-router';
import Home from '../routes/home';

import MyDataRoute from '../routes/mydata';
import MyHouseRoute from '../routes/myhouse';
import OnBoardingRoute from '../routes/onboarding';
import PrivacyRoute from '../routes/privacy';
import ProjectGroupRoute from '../routes/projectgroup';
import DashboardRoute from '../routes/dashboard';

import SignIn from '../routes/signin';
import SignUp from '../routes/signup';
import SelectContextRoute from '../routes/selectContext';
import PersonalBehaviorRoute from '../routes/myhouse/personalBehavior';
import HouseDetailsRoute from '../routes/myhouse/houseDetails';
import PublicPageChat from '../routes/publicPageChat';
import { callApiPublic } from './callApi';
import Spinner from './questions/spinner';
import AdviceRoot from '../routes/advices';
import AdvicePageChat from '../routes/advices/conversation';

export default class App extends Component {
	state = {
		CurrentUrl: "/",
		IsLoaded: false
	};
	
	componentDidMount() {
		if (window.location.hostname == "localhost" && window.location.port == 8080) {
			localStorage.setItem('bww_api_url', 'https://localhost:44305');
		}

		callApiPublic("GET", "/api/1/measurements/measurement").then(info => {		
			if(typeof(info) == "object")		{
				localStorage.setItem('bww_measurementId', info.id);
				localStorage.setItem('bww_mode', info.mode);	
				localStorage.setItem('bww_measurement_ambassadorName', info.ambassadorName);	
				localStorage.setItem('bww_measurement_ambassadorIntroduction', info.ambassadorIntroduction);	
				localStorage.setItem('bww_measurement_ambassadorPathToImage', info.ambassadorPathToImage);	
				localStorage.setItem('bww_show_help', info.showHelp);	
				localStorage.setItem('bww_show_website', info.showWebsite);	
				localStorage.setItem('bww_websiteUrl', info.websiteUrl);	
				this.setState({
					IsLoaded: true
				});
			}
			else
				localStorage.setItem('bww_measurementId', info);						
		}); 
	}

	handleRoute = e => {
		this.setState({ CurrentUrl: e.url },function(){
			const evt = new CustomEvent("bww_routeChanged", {
                detail: { 
                    route: e.url
                },
                bubbles: true,
                cancelable: true,
                composed: false, 
            });

            window.dispatchEvent(evt)
		});
	};
	
	render({}, {IsLoaded}) {
		return (
			<div id="app" className="z-0 relative h-full">
				{IsLoaded == false ? 
					<Spinner />
				: 
					<Router onChange={this.handleRoute}>
						<Home path="/" />

						<PublicPageChat path="/welkom" />
						<SignUp path="/aanmelden" />
						<SignIn path="/inloggen" />

						<OnBoardingRoute path="/onboarding" />
						<DashboardRoute path="/dashboard" />					
						<MyHouseRoute path="/mijnhuis" />
						<HouseDetailsRoute path="/mijnhuis/huisindetail" />
						<PersonalBehaviorRoute path="/mijnhuis/gedrag" />

						<MyDataRoute path="/mijndata" />
						<ProjectGroupRoute path="/projectgroep" />
						<PrivacyRoute path="/privacy" />
						<SelectContextRoute path="/kiesadres" /> 
						 
						<AdviceRoot path="/advies-staat-klaar" />
						<AdvicePageChat path="/advies-staat-klaar/welkomterug" flow="results_welcomeback" /> 
						<AdvicePageChat path="/advies-staat-klaar/gasnet" flow="results_gas" />
						<AdvicePageChat path="/advies-staat-klaar/elektriciteitsnet" flow="results_electric" />
						<AdvicePageChat path="/advies-staat-klaar/warmtenet" flow="results_heat_grid" />
						<AdvicePageChat path="/advies-staat-klaar/geennet" flow="results_independant" />
					</Router>
				}
			</div>
		)
	}
}