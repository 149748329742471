import { Auth } from './auth/Auth';

export async function callApi(method, url, body) {
	const headers = new Headers();

	headers.append("BWW_Authorization", + Auth.getSessionId());
	if (typeof (body) !== "undefined" && body.length > 2)
		headers.append("Content-Type", "application/json");

	const options = {
		method: method,
		headers: headers,
		body: body
	};

	var apiUrl = localStorage.getItem('bww_api_url');
	var url2 = apiUrl + url;
	if(apiUrl == null)
		url2 = url;

	return fetch(url2, options)
		.then(data => {		
			if (data != null && typeof (data) !== "undefined")
				return data.json();
			return {};
		})
		.catch(error => console.log(error));
}

export async function callApiPublic(method, url, body) {
	const headers = new Headers();

	if (typeof (body) !== "undefined" && body.length > 2)
		headers.append("Content-Type", "application/json");

	const options = {
		method: method,
		headers: headers,
		body: body
	};

	var apiUrl = localStorage.getItem('bww_api_url');
	var url2 = apiUrl + url;
	if(apiUrl == null)
		url2 = url;

	return fetch(url2, options)
		.then(data => {
			if (data != null && typeof (data) !== "undefined")
				return data.json();
			return {};
		})
		.catch(error => console.log(error));
}

export async function loadStatic(method, url, body) {
	const headers = new Headers();

	if (typeof (body) !== "undefined" && body.length > 2)
		headers.append("Content-Type", "application/json");

	const options = {
		method: method,
		headers: headers,
		body: body
	};

	return fetch(url, options)
		.then(data => {
			if (data != null && typeof (data) !== "undefined")
				return data.json();
			return {};
		})
		.catch(error => console.log(error));
}


