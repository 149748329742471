import { Component } from "preact";
import { route } from "preact-router";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";


export default class SelectHouse extends Component {
    state = {
        showSpinnerForSubmit: false,
        canSubmit: false,
        selectedOption: null,
        options: []
    }

    componentDidMount(){
        var reos = Context.availableRealEstate();
        this.setState({options : reos});
    }

    handleListClick = (e) => {
        e.preventDefault();
        var selected = e.target.dataset["value"];
        this.setState({
            canSubmit: true,
            selectedOption: selected
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });   
        
        localStorage.setItem("bww_context", this.state.selectedOption);
        route("/")
    }

    render({ why }, { options, canSubmit, selectedOption }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">

                                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                    <input name="RealEstateId" id="RealEstateId" value={selectedOption} type="hidden" />
                                    {options.map(x => (
                                        <li data-value={x.id} class={((selectedOption == x.id) ? "bg-bww-600 text-white " : "bg-white ") + " font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={this.handleListClick}>
                                            {x.street + " " + x.houseNumber + (x.houseNumberAddition?? "")}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {(canSubmit) ?
                                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                        {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                    </button>
                                </div>
                                : ""}
                        </form>
                    </div>
                </div>

                <Why why={why} />
            </section>
        )
    }
}