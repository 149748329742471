const ActionButton = (props) => {
    /*function getLeftLabel(){
        var delta = 3 - props.selected.length;
        if(delta == 1)
            return "één favoriet";
        if(delta == 2)
            return "twee favorieten";
        return "drie favorieten";
    }

    if (props.selected.indexOf(props.openend) != -1) {
        return (
            <>
                <div class="text-center pb-4">Top! dit staat nu in je top 3 van favorieten</div>
                <div class=" w-full m-auto  flex justify-center">
                    <a href="#" class="text-white font-bold text-lg bg-bww-600 w-72 ease-in duration-300 px-6 py-2 rounded-md flex items-center justify-center" onClick={(e) => { e.preventDefault(); props.onremove(props.openend) }}>
                        Favortiet weghalen
                    </a>
                </div>
            </>
        );
    }

    if (props.selected.length == 3) {
        return (
            <div class=" w-full m-auto  flex justify-center">
                Je kan maximaal 3 favorieten doorgeven
            </div>
        );
    } */

    return (
        <>
            <div class=" w-full m-auto  flex justify-center">
                <a href="#" class="text-white font-bold text-lg bg-bww-600 w-72 ease-in duration-300 px-6 py-2 rounded-md flex items-center justify-center" onClick={(e) => { e.preventDefault(); props.onadd(props.openend) }}>
                    Dit is mijn favoriet
                </a>
            </div>
            
         </>
    );
}

export default ActionButton;   