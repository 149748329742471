
		import Async from "C:\\Users\\RobbinOost\\AppData\\Roaming\\npm\\node_modules\\preact-cli\\node_modules\\@preact\\async-loader\\async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!C:\\Users\\RobbinOost\\AppData\\Roaming\\npm\\node_modules\\preact-cli\\node_modules\\babel-loader\\lib\\index.js??ref--4!./index.js");
				typeof cb === 'function' && cb(result);
			}, "route-advices");
		}

		export default Async(load);
	