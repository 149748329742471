import { Component } from "preact"
import { route } from "preact-router";
import { Auth } from "../auth/Auth";
import { callApiPublic } from "../callApi";
import { Context } from "../context/context";
import Spinner from "../questions/spinner";

export default class SignInForm extends Component {
    state = {
        showSpinnerForSubmit: false,
        isSubmitted: false
    }

    componentDidMount() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let sessionId = params.set_session;        
        if (sessionId != null) {        
            var url = '/api/1/session/validate/' + Context.measurmentId() + '/' + sessionId + '/extended';
            callApiPublic("GET", url)
                .then(response => {                  
                    if (response.isValid) {
                        var reos = response.realEstateObjects;
                        var realEstateObjects = JSON.stringify(reos);
                        localStorage.setItem("bww_realEstateObjects", realEstateObjects);
                        localStorage.setItem("bww_participatorId", response.session.participatorId);

                        if (reos.length == 1)
                            localStorage.setItem("bww_context", reos[0].id);

                        Auth.storeSessionId(response.session.id);
                        route("/");
                    }
                    else {
                        alert("Helaas, deze activatiecode lijkt niet (langer) geldig.");
                    }
                });
        }
    }

    submit = (e) => {
        e.preventDefault();
        if (this.state.showSpinnerForSubmit)
            return;

        this.setState({
            showSpinnerForSubmit: true
        });

        var ref = this;
        callApiPublic("POST", "/api/1/session/send", JSON.stringify({
            "email": e.target.elements.Email.value,
            "measurementId": localStorage.getItem("bww_measurementId"),
            "url":  window.location.href.split('?')[0]
        }))
            .then(response => {
                ref.setState({
                    isSubmitted: true
                })
            });
    }

    render() {
        return (
            <>
                {this.state.isSubmitted ?
                    <div class="space-y-4">
                        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                            <h3 class="font-bold  text-xl lg:text-2xl">De toegangslink is onderweg</h3>
                            <p class="text-slate-800 mt-4 lg:text-lg pt-4 pb-16">
                                Als je met dit e-mailadres al begonnen was aan de BuurtWarmteWijzer, is er nu een toegangslink onderweg. Controleer je inbox en eventueel je spamfolder. Het duurt maximaal 2 minuten voordat je de e-mail ontvangt.
                            </p>
                        </div>
                    </div>
                    :
                    <form class="space-y-4" action="#" method="POST" onSubmit={this.submit}>

                   
                        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                            <div>
                                <label for="Email" class="blockfont-medium text-gray-700">E-mailadres</label>
                                <div class="mt-1">
                                    <input required type="email" id="Email" name="Email" class=" px-3 py-3 block w-full border-gray-300 rounded-md shadow-sm focus:ring-bww-500 focus:border-bww-500 sm:text-sm" />
                                </div>
                            </div>
                        </div>

                        <div class="pt-8 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            <button type="submit" onClick={this.handleClick} class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                {this.state.showSpinnerForSubmit ? <Spinner /> : "Inloggen"}
                            </button>

                        </div>
                    </form>
                }
            </>
        );
    }

}