export default function ConfortLevel(props) {
    function present() {
        const lookup = ["A+", "A", "B", "C", "D", "E", "F", "G" ]
        var current = props.current.toUpperCase();
        var n = props.new.toUpperCase();       
        var delta = lookup.indexOf(current) - lookup.indexOf(n);

        if(n == "A+")
            return "Kan haast niet beter";
        if(delta == 1)
            return "Een stukje beter";
        if(delta == 2)
            return "Merkbare verbetering";
        if(delta >= 3)
            return "Grote verbetering";   

        return "Geen verandering";
    }

    return (
       <>{present()}</>
    );
} 
