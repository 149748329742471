import BreadCrumbs from "./breadcrumbs";
import Footer from "./footer";
import Help from "./help";
import Menu from "./menu";

const Layout = (props) => (
    <>
        <Menu />
        <BreadCrumbs />
        {props.children}
        <Footer />
        <Help /> 
    </>
);

export default Layout;