import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class Accountability extends Component {
    state = {
        isOpend: false,
        prices: [],
        solutionsExcluded: [],
        solutionsIncluded: []
    }

    componentDidMount() {
        callApi("GET", "/api/1/" + Context.measurmentId() + "/advice_settings")
            .then(x => this.setState(x));
    }

    open = (e) =>{
        e.preventDefault();
        this.setState({isOpend : true});
    }

    close = (e) =>{
        e.preventDefault();
        this.setState({isOpend : false});
    }

    render({ }, { isOpend }) {
        if (!isOpend) {
            return (
                <div class="bg-slate-100 max-w-7xl mx-auto">
                    <div class="px-4 lg:px-8 ">
                        <div class="mx-auto py-12 lg:py-24 ">
                            <button class="border-b  flex accordion flex items-center justify-between w-full pb-4" onClick={this.open}>
                                <div class="text-xl text-gray-400 text-left pr-4">
                                    Ben je benieuwd welke keuzes en uitgangspunten er door de projectgroep zijn gekozen? Je kan door hier te klikken de uitegbreide analyse lezen. 
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class={(isOpend ? "rotate-180" : "") + " w-4 h-4 transition ease-in-out"} viewBox="0 0 24 24">
                                    <path class="fill-gray-600" d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div class="bg-slate-100 max-w-7xl mx-auto">
                    <div class="px-4 lg:px-8 ">
                        <div class="mx-auto py-12 lg:py-24">
                            <button class="  flex accordion flex items-center justify-between w-full pb-4 mb-16" onClick={this.close}>
                                <div class="text-xl text-gray-400 text-left pr-4">
                                    Ben je benieuwd welke keuzes en uitgangspunten er door de projectgroep zijn gekozen? Je kan door hier te klikken de uitegbreide analyse lezen. 
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class={(isOpend ? "rotate-180" : "") + " w-4 h-4 transition ease-in-out"} viewBox="0 0 24 24">
                                    <path class="fill-gray-600" d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"></path>
                                </svg>
                            </button>


                            <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-16 lg:space-y-0">
                                <div class="space-y-5 sm:space-y-4">
                                    <h2 class="text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight text-gray-600">Verantwoording</h2>
                                    <p class="text-xl text-gray-400">
                                        Hier zie je welke keuzes en uitgangspunten er door de projectgroep zijn gekozen. Heb je vragen of wil je meer weten over de keuzes, neem dan contact op met de <a class="text-bww-600 underline font-bold" href="/projectgroep">projectgroep</a>.
                                    </p>
                                </div>
                                <div class="lg:col-span-2">
                                    <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight text-gray-600">Looptijden en prijsontwikkeling</h2>
                                    <p class="text-base text-gray-400 py-4 leading-6">
                                        Er is gekozen om te rekenen vanaf het jaar <strong>{this.state.startYearEnergyPrice}</strong> en een periode voor de prijsontwikkeling van <strong>{this.state.durationEnergyPrice}</strong> aan te houden.
                                        De prijzen voor de verschillende warmte bronnen hebben we hieronder vermeld:
                                    </p>
                                    <table className="min-w-full divide-y divide-gray-300 mb-8">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6 md:pl-0"
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-600 sm:table-cell"
                                                >
                                                    Eenheid
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-600 sm:table-cell"
                                                >
                                                    Prijs in {this.state.startYearEnergyPrice}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-600 sm:pr-6 md:pr-0"
                                                >
                                                    Gemiddelde prijs over {this.state.durationEnergyPrice}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.prices.map((p) => (
                                                <tr key={p.name} className="border-b border-gray-200">
                                                    <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                        <div className="font-medium text-gray-600">{p.name}</div>
                                                    </td>
                                                    <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{p.unit}</td>
                                                    <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">€{p.priceAtStartYear}</td>
                                                    <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-400 sm:pr-6 md:pr-0">€{p.averagePriceDuringPeriod}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <p class="text-base text-gray-400 py-4 leading-6">
                                        Alle getoonde prijzen <strong>{this.state.pricesIncludeVat ? "inclusief" : "exclusief"}  BTW</strong> en subsidies zijn <strong>{this.state.isSubsidiesTakenInConsideration ? "wel" : "niet"} </strong> meegenomen.
                                    </p>


                                    <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight text-gray-600 py-4">Warmtebronen</h2>
                                    <p class="text-base text-gray-400 py-4 leading-6">
                                        In de berekening zijn de volgende warmtebronnen meegenomen: <strong>{this.state.solutionsIncluded.join(', ')}</strong>. {this.state.solutionsExcluded.length == 0 ? "Er zijn geen oplossingen uitgesloten." : "De volgende oplossingen zijn uitgesloten door de projectgroep: " + this.state.solutionsExcluded.join(', ') + "."}
                                    </p>

                                    <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight text-gray-600 py-4">Warmtenetten</h2>
                                    <p class="text-base text-gray-400 py-4 leading-6">
                                        Voor de warmtenetten zijn de volgende uitgangspunten gebruikt:
                                    </p>

                                    <table className="min-w-full divide-y divide-gray-300 mb-8">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6 md:pl-0"
                                                >
                                                    Soort warmtenet
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-600 sm:table-cell"
                                                >
                                                    Tempratuur
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-600 sm:table-cell"
                                                >
                                                    Subsidie / korting
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="border-b border-gray-200">
                                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                    <div className="font-medium text-gray-600">HT - Hoge temperatuur-warmtenet </div>
                                                </td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.temperatureHeatNetHigh}</td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.discountPercentageHeatNetHigh}</td>
                                            </tr>
                                            <tr className="border-b border-gray-200">
                                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                    <div className="font-medium text-gray-600">MT - Middel temperatuur-warmtenet </div>
                                                </td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.temperatureHeatNetMedium}</td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.discountPercentageHeatNetMedium}</td>
                                            </tr>
                                            <tr className="border-b border-gray-200">
                                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                    <div className="font-medium text-gray-600">LT - Lage temperatuur-warmtenet </div>
                                                </td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.temperatureHeatNetLow}</td>
                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-400 sm:table-cell">{this.state.discountPercentageHeatNetLow}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight text-gray-600 py-4">CO<sub>2</sub></h2>
                                    <p class="text-base text-gray-400 py-4 leading-6">
                                        De projectgroep heeft aangegeven dat de electricitiet gemiddeld voor <strong>{this.state.percentageOfGreenElectricity}</strong> uit groene ernergie bestaat. In het geval van gas wordt er uit gegaan van <strong>{this.state.typeOfGas}</strong> en er is een CO<sub>2</sub> belasting van <strong>{this.state.co2Tax}</strong> gebruikt.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}