import SignUpForm from "../public/signUpForm";

const SignUpComponent = () => (
    <section class="block relative mb-8 md:mb-10 lg:mb-12">
        <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
            <div class="p-4 md:p-6 lg:p-8">
                <SignUpForm />
            </div>
        </div>
    </section>
);

export default SignUpComponent;