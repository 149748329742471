import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import Why from "./why";


export default class WarmWaterUsage extends Component {

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });
        var goTo = this.props.goTo;

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "ShowerMinutesPerWeek", (e.target.elements.ShowerMinutesPerWeek.value + ""), "Minuten douchen"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TimesABathPerWeek", (e.target.elements.TimesABathPerWeek.value + ""), "badgebruik per week"));

        Promise.all(promises).then(function (responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, label) {
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value,
            "printableKey": label,
            "printableValue": value
        }));
    }

    render({ why }, { }) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>


                            <label for="ShowerMinutesPerWeek" class="block font-medium text-gray-700">Aantal douche minuten (ongeveer)</label>
                            <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                                       
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="22px" viewBox="0 0 24 22" enable-background="new 0 0 24 22" className="h-6 w-6">
                                            <g>
                                                <path class="fill-current" d="M18,0H9C7.897,0,7,0.896,7,2v2h2V2h9c0.551,0,1,0.449,1,1v14h-1v-1h-2v4h2v-1h1v3h2V3
                                                        C21,1.346,19.654,0,18,0z"/>
                                                <circle class="fill-current" cx="8" cy="7" r="1" />
                                                <circle class="fill-current" cx="6" cy="9.5" r="1" />
                                                <circle class="fill-current" cx="10" cy="9.5" r="1" />
                                                <circle class="fill-current" cx="8" cy="12" r="1" />
                                                <circle class="fill-current" cx="4" cy="12" r="1" />
                                                <circle class="fill-current" cx="12" cy="12" r="1" />
                                            </g>
                                        </svg>
                                    </div>
                                    <input required type="number" name="ShowerMinutesPerWeek" id="ShowerMinutesPerWeek" min="0" max="1000" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" />
                                </div>
                                <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                    minuten per week
                                </div>
                            </div>


                            <label for="TimesABathPerWeek" class="block font-medium text-gray-700">Aantal keer in bad per week</label>
                            <div class="mt-1 flex rounded-md shadow-sm mb-4" >
                                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="22px" viewBox="0 0 24 22" className="h-6 w-6" enable-background="new 0 0 24 22">
                                            <g>
                                                <circle class="fill-current" cx="13" cy="5" r="1" />
                                                <circle class="fill-current" cx="11" cy="7.5" r="1" />
                                                <circle class="fill-current" cx="15" cy="7.5" r="1" />
                                                <circle class="fill-current" cx="13" cy="10" r="1" />
                                                <circle class="fill-current" cx="9" cy="10" r="1" />
                                                <circle class="fill-current" cx="17" cy="10" r="1" />
                                                <path class="fill-current" d="M22,13V3c0-1.654-1.346-3-3-3h-7v3h2V2h5c0.551,0,1,0.449,1,1v10H0v2h24v-2H22z" />
                                                <path class="fill-current" d="M5.086,19.885L3.5,22H6l1.5-2h9l1.5,2h2.5l-1.586-2.115C20.681,19.471,22,17.894,22,16H2
                                                        C2,17.894,3.319,19.471,5.086,19.885z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <input required type="number" min="0" max="10" name="TimesABathPerWeek" id="TimesABathPerWeek" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" />
                                </div>
                                <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">
                                    keer per week
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.showSpinnerForSubmit ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <Why why={why} />
            </section>
        )
    };
}


