const ChatTyping = (props) => {

    return (
        <section class="block relative pl-10 mb-8 md:mb-10 lg:mb-12">
            {(props.isBWW) ?
                <div class="absolute bottom-0 left-0 block h-8 w-8  border-2 border-slate-400 rounded-full opacity-50 bg-bww-600 text-neutral-100 font-bold text-xs flex justify-center items-center ">
                    BWW
                </div> :
                <img class="absolute bottom-0 left-0 block h-8 w-8 border border-slate-400 rounded-full opacity-50"
                    src={(props?.avatar?.length > 0 ? props.avatar : localStorage.getItem("bww_measurement_ambassadorPathToImage"))}
                    alt="" />
            }
            <div class="relative h-8 flex items-center">
                <div class="w-2 h-2 bg-slate-300 animate-bounce rounded-full ml-1"></div>
                <div class="w-2 h-2 bg-slate-500 animation-delay-25 animate-bounce rounded-full ml-1"></div>
                <div class="w-2 h-2 bg-slate-700 animation-delay-50 animate-bounce rounded-full ml-1"></div>
            </div>
        </section>
    );
}

export default ChatTyping;