import { Component } from "preact";
import ChatBubble from "./bubble";
import ChatTyping from "./typing";

export default class ChatMessage extends Component {
    state = {
        isNew: true
    };
   
    componentDidMount() {       
        var target = this.props.text.length * 9.55;
        setTimeout(() => {
            this.setState({isNew: false}, () => {                        
                this.props.afterRender(); 
            });
        }, target);    
      
    }

     render({text, isBWW = false}, {isNew}){
        if(isNew)
            return(
                <ChatTyping isBWW={isBWW}  />
            )
        else
            return(
                <ChatBubble text={text} isBWW={isBWW} />
            )
     }
}