import { Component } from "preact";
import Spinner from "./spinner";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Why from "./why";


export default class DescribeFamily extends Component {
    state = {
        isSubmitting: false,
        totalNumberOfPersons: 0
    };

    handleSubmit = (e) =>{
        e.preventDefault();

        if(this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];
        var numberOfPersons = e.target.elements.numberOfPersons.value;
        var goTo = this.props.goTo;

        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "FamilySize", numberOfPersons + "", "Aantal bewoners"));
        for(var i = 0; i< numberOfPersons; i++){
            var age = e.target.elements["familyMember"+i].value;
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, "AgeFamilyMember"+(i+1), (age+""), "Leeftijd familielid "+(i+1)));
        }

  
        Promise.all(promises).then(function(responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e);
        });   
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, printableKey){
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value+"",
            "printableKey": printableKey,
            "printableValue": value +""
        }));
    }

    handleNumberOfPersonChange = (e) =>{
        this.setState({ totalNumberOfPersons:  e.target.value});    
    }

    renderPersons = () =>{
        var elements = [];
        for(var i = 0; i< this.state.totalNumberOfPersons; i++){
            elements.push(
                <div class="mb-6 flex ">
                    <div class="mt-1 pr-4 pt-2 w-1/2 lg:w-1/3">
                        <label for={"familyMember"+i} class="block mb-2  font-bold text-bww-600">Bewoner {i+1}</label>
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm  w-1/2 lg:w-2/3">
                        <div class="relative flex items-stretch flex-grow focus-within:z-10">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                     
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                            </svg>
                        </div>
                        <input type="number" required id={"familyMember"+i} min={i == 0 ? "16" : "0"} max="120" class="focus:ring-bww-500 focus:border-bww-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="32" />
                        </div>
                        <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-bww-500 focus:border-bww-500">                  
                            
                            <div class="whitespace-nowrap">Jaar</div>
                        </div>
                    </div>
            </div>)          
        }
        return elements;
    }

    render({why}) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">
                                    <label for="numberOfPersons" class="block font-medium text-gray-700">Met hoeveel personen woon je in je huis?</label>
                                    <select onChange={this.handleNumberOfPersonChange} id="numberOfPersons" name="numberOfPersons" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bww-500 focus:border-bww-500 sm:text-sm rounded-md">
                                        <option selected value="0">--</option>
                                        <option value="1">Ik woon alleen</option>
                                        <option value="2">2 personen</option>
                                        <option value="3">3 personen</option>
                                        <option value="4">4 personen</option>
                                        <option value="5">5 personen</option>
                                        <option value="6">6 personen</option>
                                        <option value="7">7 personen</option>
                                        <option value="8">8 of meer personen</option>                                     
                                    </select>
                                </div>
                            </div>

                            <div class="mt-5 sm:mt-6">
                                {this.renderPersons()}
                            </div>


                            {
                                this.state.totalNumberOfPersons > 0 ? ( 
                                    <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                        <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                            {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                        </button>
                                    </div>    
                                ) : ""
                            }
                              
                        </div>
                    </form>
                </div>

               <Why why={why} />
            </section> 
        ); 
    } 
}