import { Component } from "preact";
import { Context } from "../context/context";

export default class Medal extends Component {
    state = {
        medal: "",
        text: "Nieuw",
        nextFlow: "/onboarding",
        color: ""
    }

    componentDidMount() {
        var medal = Context.getMedal();
        var text = "Nieuw";
        var nextFlow = "/onboarding";
        var color = ""
        switch (medal) {
            case "Bronze": {
                color = "fill-amber-700";
                text = "Bronzen";
                break;
            }
            case "Silver": {
                color = "fill-gray-600";
                text = "Zilveren";
                break;
            }
            case "Gold": {
                color = "fill-yellow-200";
                text = "Gouden";
                break;
            }
        }

        var finishedFlows = Context.finishedFlows();
        if (finishedFlows.indexOf("houseDetails") == -1)
            nextFlow = "/mijnhuis/huisindetail";
        else if (finishedFlows.indexOf("personalBehavior") == -1)
            nextFlow = "/mijnhuis/gedrag";

        this.setState({
            medal: medal,
            text: text,
            nextFlow: nextFlow,
            color: color
        })
    }

    render({}, {text, color, medal, nextFlow}) {
        return (
            <div class="py-8 px-6 bg-gray-800">
                <div class="relative">
                    <div class="absolute top-1 left-0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 md:w-8 md:h-8" viewBox="0 0 24 24">
                            <path class={color} d="M12.007 4c1.699 0 3.555.435 4.993 1.144v9.392c0 1.602-.414 1.932-5 4.759l.007-15.295zm8.993-1v11.536c0 4.602-3.204 5.803-9 9.464-5.796-3.661-9-4.863-9-9.464v-11.536c1.945-1.666 5.336-3 9-3s7.055 1.334 9 3zm-2 .991c-1.729-1.159-4.361-1.991-6.993-1.991-2.654 0-5.281.834-7.007 1.991v10.544c0 3.029 1.658 3.811 7 7.107 5.336-3.293 7-4.075 7-7.107v-10.544z"></path>
                        </svg>
                    </div>
                    <div class="pl-10 text-white">
                        <div class="font-bold text-xl">
                            {text} BuurtWarmteWijzer-lid
                        </div>
                        <div>
                            {medal == "Gold" ?
                                <>Je hebt alle vragen beantwoord. Dank voor je medewerking!</> :
                                Context.mode() != "Preference" ?
                                    <>Nog <a href={nextFlow} class="text-bww-600 underline font-bold">{(medal == "Bronze" ? "twee vragenrondes" : (medal == "Silver" ? "één vragenronde" : "3 vragenrondes"))}</a> te gaan voor goud</>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


