/* This example requires Tailwind CSS v2.0+ */
import { Fragment, Component } from 'preact'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import Spinner from '../questions/spinner'
import { Context } from '../context/context'
import { callApi } from '../callApi'

export default class Help extends Component {
  state = {
    open: false,
    isSubmitting: false,
    isSubmitted: false
  }

  setOpen = (isOpen) => {
    this.setState({
      open: isOpen 
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isSubmitting: true
    });


    var x = e.target.elements;
    var ref = this;

    if (!this.state.showSpinnerForSubmit)
      callApi("POST", "/api/1/helpneeded/confirm", JSON.stringify({
        "measurementId": Context.measurmentId(),
        "participatorId": Context.participatorId(),
        "message": x.message.value,
        "url": window.location.toString(),
        "flow": Context.flowId(),
        "flowHistory": Context.flowHistory()
      })).then(x => {
        ref.setState({
          isSubmitted: true
        })
      });
  }

  render({ }, { open }) {  
    if (!Context.showHelp()) {
      return (<></>);
    }
    else {
      return (
        <>
          <button class="-rotate-90 fixed -right-10 w-32 top-[50%] rounded-t-lg bg-bww-600 p-3 text-xs text-white font-bold uppercase z-[90]" onClick={() => this.setOpen(true)}>Hulp nodig?</button>

          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={this.setOpen}>
              <div className="fixed inset-0" />

              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                          <div className="px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-900"> Ik heb hulp nodig </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-bww-500"
                                  onClick={() => this.setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* Main */}
                          <div className="divide-y divide-gray-200">
                            {this.state.isSubmitted ?
                              <div class="p-4 md:p-6 lg:p-8">Je bericht is verstuurd. Een energie coacht neemt binnenkort contact met je op!</div>
                              :
                              <form onSubmit={this.handleSubmit}>
                                <div class="p-4 md:p-6 lg:p-8">
                                  <div class="relative mb-4 md:mb-6 lg:mb-8">
                                    <div class="mb-6">
                                      <label for="message" class="block mb-2 text-sm font-bold text-bww-600">Stel je vraag</label>
                                      <textarea name="message" required id="message" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm h-32 rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5" placeholder="Waar kunnen we je mee helpen?" />
                                    </div>
                                  </div>

                                  <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                      {this.state.isSubmitting ? <Spinner /> : "Hulp aanvragen"}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            }
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )
    }
  }
}

