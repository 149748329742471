import SignInForm from "../public/signInForm";


const SignInComponent = () => (
    <section class="block relative mb-8 md:mb-10 lg:mb-12">
        <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
            <div class="p-4 md:p-6 lg:p-8">
                <SignInForm />
            </div>
        </div>
    </section>
);

export default SignInComponent;