import { Context } from "../context/context";

const HouseChanges = (props) => {
    if(props.investment == 0 || props.investment == "0"){
        return(
            <ul class="py-4 leading-6">
           
                <li class="flex pb-4">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="mr-4 h-6 w-4 fill-bww-600" viewBox="0 0 7.302 13" enable-background="new 0 0 7.302 13"><polygon points="3.483,0 7.302,0 3.819,13 0,13 "></polygon></svg>
                    <span>Er zijn geen aanpassingen nodig</span>
                </li>    
              
            </ul>        
        );
    }

    var label = props.label.toUpperCase();
    
    const infiltration = {
        "A+": "2",
        "A": "2",
        "B": "2",
        "C": "2",
        "D": "1",
        "E": "0",
        "F": "0",
        "G": "0"
    };

    const ventialtion = {
        "A+": "2",
        "A": "1",
        "B": "1",
        "C": "1",
        "D": "1",
        "E": "0",
        "F": "0",
        "G": "0"
    };

    const defaultHouse ={
        "roof" :{
            "A+": "4",
            "A": "3",
            "B": "3",
            "C": "2",
            "D": "1",
            "E": "0",
            "F": "0",
            "G": "0"
        },
        "floor": {
            "A+": "4",
            "A": "3",
            "B": "2",
            "C": "2",
            "D": "1",
            "E": "0",
            "F": "0",
            "G": "0"
        },
        "facade": {
            "A+": "3",
            "A": "2",
            "B": "2",
            "C": "2",
            "D": "1",
            "E": "0",
            "F": "0",
            "G": "0"
        },
        "glass1": {
            "A+": "3",
            "A": "2",
            "B": "2",
            "C": "2",
            "D": "1",
            "E": "0",
            "F": "0",
            "G": "0"
        },
        "glass2": {
            "A+": "3",
            "A": "2",
            "B": "2",
            "C": "2",
            "D": "1",
            "E": "0",
            "F": "0",
            "G": "0"
        }
    }

    var labels = {
        "glass2": "ramen in de slaapkamers",
        "glass1": "ramen in de woonkamers",
        "facade": "gevelisolatie",
        "floor": "vloerisolatie",
        "roof": "dakosolatie",
        "infiltration": "kieren en naaden",
        "ventilation": "ventilatie",
    }

    var targetInfiltration = parseInt(infiltration[label]);
    var targetVentialtion = parseInt(ventialtion[label]);
    var targetGlass2 = parseInt(defaultHouse["glass2"][label]);
    var targetGlass1 = parseInt(defaultHouse["glass1"][label]);
    var targetFacade = parseInt(defaultHouse["facade"][label]);
    var targetFloor= parseInt(defaultHouse["floor"][label]);
    var targetRoof = parseInt(defaultHouse["roof"][label]);

    var currentInfitration = parseInt(props.current.infiltration);
    var currentVentilation = parseInt(props.current.ventilationType);
    var currentGlass2 = parseInt(props.current.glass2);
    var currentGlass1 = parseInt(props.current.glass1);
    var currentFacade = parseInt(props.current.facade);
    var currentFloor = parseInt(props.current.floor);
    var currentRoof = parseInt(props.current.roof);
  
    var listMeasures = [];   
    
    if(targetRoof > currentRoof)
        listMeasures.push({
            name: "roof",
            from: currentRoof,
            to: targetRoof
        });
    if(targetFloor > currentFloor)
        listMeasures.push({
            name: "floor",
            from: currentFloor,
            to: targetFloor
        });
    if(targetFacade > currentFacade)
        listMeasures.push({
            name: "facade",
            from: currentFacade,
            to: targetFacade
        });
    if(targetGlass1 > currentGlass1)
        listMeasures.push({
            name: "glass1",
            from: currentGlass1,
            to: targetGlass1
        });
    if(targetGlass2 > currentGlass2)
        listMeasures.push({
            name: "glass2",
            from: currentGlass2,
            to: targetGlass2
        });
    if(targetInfiltration > currentInfitration)
        listMeasures.push({
            name: "infiltration",
            from: currentInfitration,
            to: targetInfiltration
        });
    if(targetVentialtion > currentVentilation)
        listMeasures.push({
            name: "ventilation",
            from: currentVentilation,
            to: targetVentialtion
        });
 
    
    var levels ={
        "glass2": {
            "0": "enkel glas",
            "1": "dubbel glas",
            "2": "HR+",
            "3": "HR++",
            "4": "driedubbel"
        },
        "glass1": {
            "0": "enkel glas",
            "1": "dubbel glas",
            "2": "HR+",
            "3": "HR++",
            "4": "driedubbel"
        },
        "facade": {
            "0": "geen",
            "1": "matig (< 6cm)",
            "2": "goed (6-10cm)",
            "3": "zeer goed (10-15cm)"
        },
        "floor": {
            "0": "geen",
            "1": "matig (< 7cm)",
            "2": "redelijk (8-10cm)",
            "3": "goed (11-14cm)",
            "4": "zeer goed (15+ cm)"
        },
        "roof": {
            "0": "geen",
            "1": "matig (3-5cm)",
            "2": "redelijk (6-9cm)",
            "3": "goed (10-14cm)",
            "4": "zeer goed (15+ cm)"
        },
        "infiltration": {
            "0": "geen dichting",
            "1": "weinig dichting",
            "2": "veel dichting"
        },
        "ventilation": {
            "0": "via ramen of roosters",
            "1": "via mechanische afzuiging",
            "2": "via gebalanceerde ventilatie"
        }
    }

    return(
        <ul class="py-4 leading-6">
            {
                listMeasures.map(x => (
                    <li class="flex pb-4">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="mr-4 h-6 w-4 fill-bww-600" viewBox="0 0 7.302 13" enable-background="new 0 0 7.302 13"><polygon points="3.483,0 7.302,0 3.819,13 0,13 "></polygon></svg>
                        <span>Je {labels[x.name]} aanpassen van {levels[x.name][x.from]} naar: {levels[x.name][x.to]}</span>
                    </li>    
                ))
            }
           
        </ul>        
    );
}

export default HouseChanges;    