import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";


export default class InsulationUpdate extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false,
        options:[
            {                                
                "value": "1",
                "label": "Isolatie toch meenemen"                    
            },
            {                                
                "value": "0",
                "label": "Nee, ik wil mijn woning niet isoleren"                              
            }
        ]
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            showSpinnerForSubmit: true
        });
        var btn = e.target;
        sessionStorage.setItem("bww_filter_insulation", btn.value);
        const evt = new CustomEvent("bww_filter_updated", {          
            bubbles: true,
            cancelable: true,
            composed: false, 
        });
        window.dispatchEvent(evt);
    }

   
    render({id},  {options}) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">                        
                        <div class="sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            {options.map(x => (
                                <button type="button" onClick={(e) => this.handleClick(e)} value={x.value} class={(x.isPrimary) ? "my-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg" : "my-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500  lg:text-lg"}>
                                    {this.state.showSpinnerForSubmit && x.isPrimary ? <Spinner /> : x.label}
                                </button>
                                ))
                            }                               
                        </div>
                    </div>
                </div>
             
            </section>
        )
    }
}