import Spinner from "./spinner";
import Why from "./why";
import { Context } from "../context/context";
import { Component } from "preact";
import { callApi } from "../callApi";


export default class AreaNotHeated extends Component {
    state = {
        issupmitting: false,
        percentageNotHeated: 0
    };

    handleSubmit = (e) =>{
        e.preventDefault();

        if(this.state.issupmitting)
            return;

        this.setState({
            issupmitting: true
        });
        
        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        var promises = [];
        var percentageNotHeated = this.state.percentageNotHeated;
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "PercentageNotHeated", percentageNotHeated, "Onverwarmd deel van de woning in %"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalNotHeateddSquareMeters", this.getNotHeatedSquareMeters(), "Onverwarmd deel van de woning in m²"));
        promises.push(this.getPromise(mId, pId, reoId, sId, fId, "TotalHeateddSquareMeters", this.getHeatedSquareMeters(), "Verwarmd deel van de woning in m²"));

        var goTo = this.props.goTo;
        Promise.all(promises).then(function(responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });   
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, printableKey){
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value+"",
            "printableKey": printableKey,
            "printableValue": value +""
        }));
    }

    handleNumberOfPersonChange = (e) =>{
        this.setState({ totalNumberOfPersons:  e.target.value});    
    }

    getTotalSquareMeters = () =>{
        var reo = Context.realEstate();
        return reo.totalSurface;
    }

    getNotHeatedSquareMeters = () =>{
        return (this.getTotalSquareMeters() * (this.state.percentageNotHeated/100)).toFixed();
    }

    getHeatedSquareMeters = () =>{
        return (this.getTotalSquareMeters() * ((100 - this.state.percentageNotHeated)/100)).toFixed();
    }

    handleSliderChange = (e) =>{
        var value = e.target.value;
        this.setState({
            percentageNotHeated: value
        });
    }


    render({why}, {percentageNotHeated}) {
        return (
            <section class="relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onsubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <p class="mb-6">
                                    Totaal woonoppervlak dat we gevonden hebben: <strong class="font-bold">{this.getTotalSquareMeters()}m<sup>2</sup></strong>.                                   
                                </p>

                                <div class="mb-6">
                                    <label for="numberOfPersons" class="block font-medium text-gray-700 mb-2">Welk deel van je huis* is ongeveer niet of nauwelijks verwarmd? (bijvoorbeeld een zolder of garage) </label>
                                    <input type="range" name="percentageOfHeatedRooms" min="0" max="99" value={percentageNotHeated} onChange={this.handleSliderChange} class="w-full" />
                                </div>
                              
                                <p class="mb-1">Je geeft aan dat <strong class="font-bold">{percentageNotHeated}%</strong> niet verwarmd is. </p>

                                <p class="mb-6">
                                    Dat komt neer op <strong>{this.getNotHeatedSquareMeters()}m<sup>2</sup> NIET</strong> en <strong>{this.getHeatedSquareMeters()}m<sup>2</sup> WEL</strong> verwarmde ruimtes
                                </p>

                                <p class="mb-6">
                                    * We hebben het hier alleen over ruimtes die AAN het huis vast zitten. Een losse garage of schuur telt niet mee.
                                </p>
                            </div>
                         
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="supmit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.issupmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>    
                              
                        </div>
                    </form>
                </div>

               <Why why={why} />
            </section> 
        ); 
    } 
}