import RangePresenter from "../private/rangePresenter";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import ConfortLevel from "../private/confortLevel";
import HouseChanges from "./houseChanges";
import { Context } from "../context/context";



const BuildingChanges = (props) => {

    function getCurrentLabel(){
        return Context.realEstate().currentEnergyLabel;
    }

    return (
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-16 lg:space-y-0">
        <div class="space-y-5 sm:space-y-4">
            <h2 class="text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight ">Gewenste aanpassingen</h2>
            <p class="text-xl text-gray-500">
                Om je woning goed te verwarmen met deze warmtetechniek, zijn de volgende aanpassingen in je huis gewenst. Zoals je vloer of dak isoleren, naden en kieren dichten en ventilatie verbeteren. 
            </p>
        </div>
        <div class="lg:col-span-2">
            <h2 class="text-2xl font-bold tracking-tight sm:text-3xl sm:tracking-tight">Welke veranderingen zijn nodig in jouw huis?</h2>
            <p class="text-base text-gray-500 py-4 leading-6">
                Om de gekozen warmtebron te gebruiken moet je naar isolatie label {props.label} toe. Ten opzichte van onze model woningen zou je de volgende verberteringen moeten doen:
            </p>
              
            <HouseChanges current={props.currentBuildingLevels} label={props.label} investment={props.buildingChange.investment}  />
                            
            <p class="text-base text-gray-500 py-4 leading-6">
                Deze aanpassingen hebben het volgende effect op het comfort van je huis:  <ConfortLevel current={getCurrentLabel()} new={props.label} /> 
            </p>

            <p class="text-base text-gray-500 py-4 leading-6">

                <section
                    aria-labelledby="summary-heading"
                    className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                >
                    <h2 id="summary-heading" className="text-lg font-medium text-gray-900  pb-4">
                        Het financiële plaatje
                    </h2>

                    <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                            <dt className="flex items-center text-sm text-gray-600">
                                <span>Waardestijging van je huis</span>
                                                                 {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Lees meer over subsidies</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </a> */}
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">
                                <RangePresenter value={(props.buildingChange.appreciation)} />
                            </dd>
                        </div>         
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center text-sm text-gray-600">
                                <span>Wat je in eerste instantie uitgeeft</span>
                                                                  {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Lees meer over subsidies</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </a> */}
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">
                                <RangePresenter value={props.buildingChange.investment} />
                            </dd>
                        </div>
                       
                        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                            <dt className="flex text-sm text-gray-600">
                                <span>Beschikbare subsidie die je terug kunt vragen</span>
                                                                   {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Lees meer over subsidies</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </a> */}
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">
                                <RangePresenter value={(props.buildingChange.availableSubsidy)} />
                            </dd>
                        </div>     
                                  
                        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                            <dt className="text-base font-medium text-gray-900">Wat er voor jou overblijft om te investeren</dt>
                            <dd className="text-base font-medium text-gray-900">
                                <RangePresenter value={(props.buildingChange.investment - props.buildingChange.availableSubsidy)} />
                            </dd>
                        </div>
                      
                    </dl>
                </section>
            </p>
        </div>

    </div>
    );
}

export default BuildingChanges;