import SlideOutChat from "../../components/advice/slideout";
import AutenticatedRoute from "../../components/auth/PrivatePage";
import ChatConversation from "../../components/chat/conversation";
import { Context } from "../../components/context/context";
import Layout from "../../components/private/layout";

export default class AdvicePageChat extends AutenticatedRoute {	
	state = {
		isOpen: false
	}

	componentDidMount() {
		// Load data in BG
		setTimeout(() => {
			Context.getAdvice();
			Context.getAdivceContext();
		}, 75);

		if((window.location.hash ?? "").length > 0){
			window.setTimeout(() => {			
				var target = window.location.hash.replace("#", "");
				const e = new CustomEvent("bww_goTo", {
					detail: {
						goTo: target,
						isInPanel: this.props.isInPanel
					},
					bubbles: true,
					cancelable: true,
					composed: false,
				});

				window.dispatchEvent(e)
			}, 500);
		}

	
	}

	render({flow}, {isOpen}){
		return(
			<Layout>
			
				<ChatConversation flow={flow} />
				
				<SlideOutChat open={isOpen} flow={flow} />
			</Layout>
		)
	}
}