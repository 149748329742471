const HeatingBenefits = (props) => {
    return (
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-16 lg:space-y-0">
            <div class="space-y-5 sm:space-y-4">
                <h2 class="text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight">De voordelen van deze warmtecombinatie</h2>
            </div>
            <div class="lg:col-span-2">
                <div class="pt-0">        
                    {props.benifts.map(x =>
                        <div class="relative  lg:mt-0 flex items-center my-4 md:my-6 lg:my-6">
                            <div class="relative overflow-hidden px-4 py-6 pr-8 z-0">
                                {x.icon == "check" ?
                                    <svg class="w-6 h-6 md:w-8 md:h-8 z-20" viewBox="0 0 24 24">
                                        <path class="fill-white" d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"></path>
                                    </svg>
                                    :
                                    <svg class="w-6 h-6 md:w-8 md:h-8 z-20" viewBox="0 0 24 24">
                                        <path class="fill-white" d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"></path>
                                    </svg>
                                }
                                <span class="absolute -inset-y-1 -left-1 right-3 bg-bww-600 rotate-[5deg] -z-10"></span>
                            </div>
                            <div class="pl-2">
                                <div class="font-bold text-lg lg:text-2xl"  dangerouslySetInnerHTML={{ __html: x.line }}></div>
                            </div>
                        </div>
                    )}                   
                </div>
            </div>
        </div>
    );
}

export default HeatingBenefits; 