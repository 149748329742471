import { Component } from "preact";
import Spinner from "./spinner";
import Why from "./why";


export default class CookingMethod extends Component {
    state = {
        showSpinnerForSubmit: false
    }

    constructor() {
        super();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isSubmitting: true
        }, () => {
            setTimeout(() => {
                this.props.finishCoversation();
            }, 500);
        });
    }

    render({ why }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <fieldset>
                                <legend class="sr-only">Methode om te koken</legend>
                                <div class="space-y-4">
                                    <div class="flex items-center">
                                        <input id="Gas" name="cooking-method" type="radio" checked class="focus:ring-bww-500 h-4 w-4 text-bww-600 border-gray-300" />
                                        <label for="Gas" class="ml-3 block font-medium text-gray-700"> Gas </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input id="Electriciteit" name="cooking-method" type="radio" class="focus:ring-bww-500 h-4 w-4 text-bww-600 border-gray-300" />
                                        <label for="Electriciteit" class="ml-3 block font-medium text-gray-700"> Electriciteit </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input id="Inductie" name="cooking-method" type="radio" class="focus:ring-bww-500 h-4 w-4 text-bww-600 border-gray-300" />
                                        <label for="Inductie" class="ml-3 block font-medium text-gray-700"> Inductie </label>
                                    </div> 
                                </div>
                            </fieldset>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Why why={why} />
            </section>
        )
    }
}