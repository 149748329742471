import { Component } from "preact";

export default class Why extends Component {
    state = {
        isOverlayActive: false
    };

    showOverlay = (e) => {
        e.preventDefault();
        this.setState({
            isOverlayActive: true
        });
    }

    hideOverlay = (e) => {
        e.preventDefault();
        this.setState({
            isOverlayActive: false
        });
    }

    render({why}) {
        return (
            <>
                <div class="flex justify-start items-center mt-4 ml-2 text-sm text-slate-400 cursor-pointer" onClick={this.showOverlay}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" viewBox="0 0 24 24">
                        <path class="fill-slate-400" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"></path>
                    </svg>
                   {why.title}
                </div>

                <div class={(this.state.isOverlayActive) ? " relative z-10 block" : " hidden relative z-10 block"} aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10" onClick={this.hideOverlay}></div>

                    <div class="fixed z-10 top-1/3 md:inset-0 overflow-y-auto" onClick={this.hideOverlay}>
                        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <div
                                class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button onClick={this.hideOverlay} type="button"
                                        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500">
                                        <span class="sr-only">Sluiten</span>
                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-bww-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg class="h-6 w-6 text-bww-600 rounded-full" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                            aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
                                        </svg>
                                    </div>
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            {why.title}
                                        </h3>
                                        <div class="mt-2">
                                        {why.text.map(x =>
                                            <p class="text-sm text-gray-500 mb-4">
                                                {x}                              
                                            </p>
                                        )}
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}