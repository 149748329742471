
import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";
import Why from "./why";


export default class SolarBoiler extends AbstractQuestion {
    state = {
        showSpinnerForSubmit: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });


        var m2 = parseFloat(e.target.elements[this.props.id].value);
        var value = m2 * 500;
        super.submit(value.toFixed("0"), this.props.goTo, value.toFixed("0"));
    }

    render({ why, id, min, max, screenReaderTitle }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">
                                    <label for={id} class="block mb-2 text-sm font-bold text-bww-600">{screenReaderTitle}</label>
                                    <input type="number" placeholder="0.00" required step=".01" id={id} class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-bww-600 block w-full p-2.5 " min={min} max={max}/>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Why why={why} /> 
            </section>
        )
    }
}