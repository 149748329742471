import { Component } from "preact";

export default class Youtube extends Component { 
    componentDidMount() {      
        if(typeof(this.props.goTo) == "undefined"){
            var target = 122 * 9.55;
            setTimeout(() => {
                this.setState({isNew: false}, () => {                        
                    this.props.afterRender(); 
                });
            }, target);    
        }
    }
 
    goNext = (e) => {
        e.preventDefault();
        const evt = new CustomEvent("bww_goTo", {
            detail: {
                goTo: this.props.goTo,
                isInPanel: false
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(evt);
    }


    render({ goTo, id }, {  }) {
        return (
            <div class="mb-8">      
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800 ">
                    <div class="p-4 md:p-6 lg:p-8">
                     
                        <iframe class="w-full aspect-video" src={"https://www.youtube-nocookie.com/embed/"+id} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                         
                         {(goTo ?? "").length > 0 ? 
                            <div class="py-8 text-right">                         
                                <a onclick={this.goNext} href="#" class="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-600 sm:col-start-2 sm:text-sm lg:text-lg">Volgende</a>                           
                            </div>
                        : ""
                         }
                    </div>
                </div>
            </div>)
            ;
    }
} 