import { callApi } from "../callApi";
import { Context } from "../context/context";
import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";
import Why from "./why";

export default class PointSorter extends AbstractQuestion {
    state = {
        isSubmitting: false,
        canSubmit: false,
        maxPoints: 0,
        pointsGivven: 0,
        pointsAvailable: 0
    }

    componentDidMount() {
        var p = this.props.points;
        this.setState({
            maxPoints: p,
            pointsAvailable: p
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        var promises = [];
        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();

        document.querySelectorAll("input.bww_value").forEach(x => {
            promises.push(this.getPromise(mId, pId, reoId, sId, fId, x.name, parseInt(x.value), x.dataset["key"], parseInt(x.value)));
        });

        var goTo = this.props.goTo;
        Promise.all(promises).then(function (responses) {
            const e = new CustomEvent("bww_goTo", {
                detail: {
                    goTo: goTo,
                    isInPanel: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    getPromise(mId, pId, reoId, sId, fId, code, value, friendlyKey, friendlyValue) {
        if(isNaN(friendlyValue)){
            friendlyValue = 0
        };
        if(isNaN(value)){
            value = 0
        };

        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value + "",
            "printableKey": friendlyKey,
            "printableValue": friendlyValue +""
        }));
    }

    handleValueChanged = (e) => {
        var newValueItem = parseInt(e.target.value);
        if(isNaN(newValueItem))
            newValueItem = 0;

        var scores = 0;
        document.querySelectorAll("input.bww_value").forEach(x => {
            if (x.id != e.target.id)
                if (x.value != "")
                    scores += parseInt(x.value);
        });

        var allowedValue = this.props.points - scores;
        if (newValueItem < allowedValue)
            scores += newValueItem;

        else {
            scores += allowedValue;
            e.target.value = allowedValue;
        }

        this.setState({
            pointsAvailable: this.props.points - scores
        });
    }

    render({ id, options, screenReaderTitle, why, points }, { pointsAvailable }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <h3 class="block font-medium text-bww-500">{screenReaderTitle}</h3>
                                <div class="mb-6">
                                    {options.map((x, i) => {
                                        var key = this.props.friendlyKey + ": " + x.title;
                                        var n = id + "_" + x.name;
                                        return (
                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-3 relative" style={{"z-index" : (options.length-i)}}>
                                                <div class="flex items-center sm:pt-1.5 " >
                                                    <label htmlFor={n} className="block text-sm font-medium leading-6 text-gray-900 ">
                                                        {x.title.replace("<sub>2</sub>", "₂")}
                                                    </label>
                                                    <div class="cursor-pointer group">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2 fill-gray-400" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"></path></svg>
                                                        <div class="absolute -top-60 left-0 hidden group-hover:block bg-white p-8 shadow-xl border z-90 text-gray-400 ">
                                                            {x.info.replace("<sub>2</sub>", "₂")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-bww-600 sm:max-w-md">

                                                        <input
                                                            type="number"
                                                            data-key={key}
                                                            name={n}
                                                            id={n}
                                                            autoComplete={n}
                                                            max={points}
                                                            min={0}
                                                            onChange={(e) => this.handleValueChanged(e)}
                                                            className="bww_value block flex-1 border-0 bg-transparent px-2 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <p class="mt-2 text-sm">Nog <strong className="font-bold">{pointsAvailable}</strong> punten uit te delen</p>
                            </div>

                            {(pointsAvailable == 0) ?
                                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                        {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                    </button>
                                </div>
                                : ""}
                        </form>
                    </div>
                </div>
                {typeof (why) == "undefined" ? "" :
                    <Why why={why} />
                }
            </section>
        )
    }
}