import { Component, createRef } from "preact"
import { route } from "preact-router";
import { Auth } from "../auth/Auth";
import { callApiPublic } from "../callApi";
import Spinner from "../questions/spinner";

export default class SignUpForm extends Component {
    input1 = createRef();
    input2 = createRef();
    input3 = createRef();
    input5 = createRef();
    input6 = createRef();
    input7 = createRef();
    input8 = createRef();
    input10 = createRef();
    input11 = createRef();
    input12 = createRef();

    state = {
        showSpinnerForSubmit: false
    }

    setEvents = () => {
        var input1 = this.input1.current;
        var input2 = this.input2.current;
        var input3 = this.input3.current;
        var input5 = this.input5.current;
        var input6 = this.input6.current;
        var input7 = this.input7.current;
        var input8 = this.input8.current;
        var input10 = this.input10.current;
        var input11 = this.input11.current;
        var input12 = this.input12.current;

        this.setEvent(input1, input2, null);
        this.setEvent(input2, input3, input1);
        this.setEvent(input3, input5, input2);
        this.setEvent(input5, input6, input3);
        this.setEvent(input6, input7, input5);
        this.setEvent(input7, input8, input6);
        this.setEvent(input8, input10, input7);
        this.setEvent(input10, input11, input8);
        this.setEvent(input11, input12, input10);

        input12.addEventListener("keyup",
            function (e) {               
                var isLetter = e.keyCode >= 65 && e.keyCode <= 90;
                if (isLetter) 
                    this.value = this.value.toUpperCase();                    
                
                var isBackSpace = e.keyCode === 8;
                var isLeftArrow = e.keyCode === 37;
                if (isBackSpace || isLeftArrow)
                    input11.focus();
            });
    }
 
    setEvent = (from, to, prev) => {
        from.addEventListener("keyup", function (e) {
            var isNumber = e.keyCode >= 48 && e.keyCode <= 57 || (e.keyCode >= 96 && e.keyCode <= 105);
            var isLetter = e.keyCode >= 65 && e.keyCode <= 90;

            if (isLetter) 
                this.value = this.value.toUpperCase();
           
            if (isNumber || isLetter) 
                to.focus();
            
            var isBackSpace = e.keyCode === 8;
            var isLeftArrow = e.keyCode === 37;
            if ((isBackSpace || isLeftArrow) && prev !== null)
                prev.focus();

            var isRightArrow = e.keyCode === 39;
            if (isRightArrow)
                to.focus();
        });
    }

    submit = (e) => {
        e.preventDefault();
        if(this.state.showSpinnerForSubmit)
            return;
        
        if (!document.getElementById('consented').checked){
            alert("Je dient akkoord te gaan met de privacyverklaring");
            return;
        }
            
        this.setState({
            showSpinnerForSubmit: true
        });

        
        var x = e.target.elements;
        var activationCode =  x.Code01.value+x.Code02.value+x.Code03.value+x.Code04.value+x.Code05.value+x.Code06.value+x.Code07.value+x.Code08.value+x.Code09.value+x.Code10.value+x.Code11.value+x.Code12.value;
        callApiPublic("POST", "/api/1/realestateobjects/activate/"+localStorage.getItem("bww_measurementId"),  JSON.stringify({
            "postalCode": x.PostalCode.value.replace(" ", ""),
            "houseNumber":  parseInt(x.HouseNumber.value),
            "activationCode": activationCode
        })).then(response => {
            if(response.isValid){
                var reos = response.realEstateObjects;
                var realEstateObjects = JSON.stringify(reos);
                localStorage.setItem("bww_realEstateObjects", realEstateObjects);
                localStorage.setItem("bww_participatorId", response.session.participatorId);

                if(reos.length == 1)
                    localStorage.setItem("bww_context", reos[0].id);
                
                Auth.storeSessionId(response.session.id);
                route("/");
            }
            else{
                alert("Helaas, deze activatiecode en postcode huisnummer combinatie lijkt niet geldig.")

                this.setState({
                    showSpinnerForSubmit: false
                });
            }
        });
    }

    openPrivacy = () =>{
        var evt = new CustomEvent("bww_open_privacy", {
            detail: {
              caller: "SignUpForm",
            },
        });

        window.dispatchEvent(evt);
    }

    componentDidMount() {
        this.setEvents();
    }

    render() {
        return (
            <form class="space-y-4" action="#" method="POST" onSubmit={this.submit}>

                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div>
                        <label for="PostalCode" class="blockfont-medium text-gray-700">Postcode</label>
                        <div class="mt-1">
                            <input required type="text" id="PostalCode" name="PostalCode" autocomplete="given-name" class=" px-3 py-3 block w-full border-gray-300 rounded-md shadow-sm focus:ring-bww-500 focus:border-bww-500 sm:text-sm" />
                        </div>
                    </div>

                    <div>
                        <label for="HouseNumber" class="block font-medium text-gray-700">Huisnummer</label>
                        <div class="mt-1">
                            <input required type="number" id="HouseNumber" name="HouseNumber" autocomplete="family-name" class=" px-3 py-3 block w-full border-gray-300 rounded-md shadow-sm focus:ring-bww-500 focus:border-bww-500 sm:text-sm" />
                        </div>
                    </div>

                </div>
                
                <div>
                    <label for="password" class="block font-medium text-gray-700"> Activatiecode </label>
                    <div class="mt-1">

                        <div class="mt-4 mb-4">
                            <input required ref={this.input1} autocomplete="off" id="Code01" maxlength="1" minlength="1" name="Code01" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input2} autocomplete="off" id="Code02" maxlength="1" minlength="1" name="Code02" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input3} autocomplete="off" id="Code03" maxlength="1" minlength="1" name="Code03" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required id="Code04" name="Code04" readonly="True" type="text" value="-" class="w-full sm:w-[6.45%] mr-[2%] text-center border-0 focus:outline-none focus:ring-0 text-center" />
                            <input required ref={this.input5} autocomplete="off" id="Code05" maxlength="1" minlength="1" name="Code05" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input6} autocomplete="off" id="Code06" maxlength="1" minlength="1" name="Code06" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input7} autocomplete="off" id="Code07" maxlength="1" minlength="1" name="Code07" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input8} autocomplete="off" id="Code08" maxlength="1" minlength="1" name="Code08" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required id="Code09" name="Code09" readonly="True" type="text" value="-" class="w-full sm:w-[6.45%] mr-[2%] text-center border-0 focus:outline-none focus:ring-0" />
                            <input required ref={this.input10} autocomplete="off" id="Code10" maxlength="1" minlength="1" name="Code10" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input11} autocomplete="off" id="Code11" maxlength="1" minlength="1" name="Code11" type="text" class="w-[20.9%] sm:w-[6.45%] mr-[2%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                            <input required ref={this.input12} autocomplete="off" id="Code12" maxlength="1" minlength="1" name="Code12" type="text" class="w-[20.9%] sm:w-[6.45%] border-0 border-b-2 border-slate-400 focus:outline-none focus:ring-bww-500 focus:border-bww-500 text-center" />
                        </div>

                    </div>
                </div>

                <div class="pt-8">
                    <label class="text-gray-400 text-sm">
                        <input id="consented" class="mr-2" type="checkbox" />
                        Ik ga akkoord met de <a href="#privacy" class="border-b" onClick={(e) => this.openPrivacy()}>privacyverklaring</a>
                    </label> 
                </div>
             
                <div class="pt-8 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                    <button type="submit" onClick={this.handleClick} class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                        {this.state.showSpinnerForSubmit ? <Spinner /> : "Aanmelden"}
                    </button>
                </div>

              

            </form>
        );
    }
}