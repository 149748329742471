
import { useState } from 'react'
import { Dialog} from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import ChatConversation from '../chat/conversation'



const SlideOutChat = (props) => {
  const [open, setOpen] = useState(props.open)

    window.addEventListener("bww_openresultPanel", (e) =>{		
        setOpen(true)
    });

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Dialog.Panel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-slate-100  py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900"></Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-slate-100  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-bww-600 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Sluiten</span>
                        <XIcon aria-hidden="true" className="h-6 w-6" /> 
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6"> 
                    <ChatConversation flow={props.flow} hideExtraOptions={true}  /> 
                </div>
              </div>
            </Dialog.Panel>
          </div> 
        </div>
      </div>
    </Dialog> 
  )
}


export default SlideOutChat;