import { Component } from "preact";
import { route } from "preact-router";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";

export default class GoToAdviceRoot extends Component {
    state = {
        isSubmitting: false
    }

    handleClick = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });

        var mId = Context.measurmentId();
        var pId = Context.participatorId();
        var reoId = Context.realEstateId();
        var sId = Context.sessionId();
        var fId = Context.flowId();
        
        localStorage.setItem("bww_adivce_intro_openend", true);

        this.getPromise(mId, pId, reoId, sId, fId, "IsFlowFinished", "true")
        .then(x => 
            callApi("POST", "/api/1/flow/sync", JSON.stringify({
                "measurementId": mId,
                "participatorId": pId,
                "realEstateId": reoId,
                "flowId": fId,               
            }))
        )
        .then(x => 
            callApi("PUT", "/api/1/measurements/measurement/"+localStorage.getItem("bww_measurementId")+"/realestate/"+localStorage.getItem("bww_context")+"/participator/"+localStorage.getItem("bww_participatorId")+"/history/flow/"+localStorage.getItem("bww_flowId"), JSON.stringify({           
                "history": localStorage.getItem("bww_flowhistory").split(",").join("|"),
                "percentage": 100
            }))
        )       
        .then(x =>
            Context.setMissingFlowInfo()
        )
        .then(x => {
            route("/advies-staat-klaar");
        });
    }

    getPromise(mId, pId, reoId, sId, fId, code, value){
        return callApi("POST", "/api/1/answers/record", JSON.stringify({
            "measurementId": mId,
            "participatorId": pId,
            "realEstateId": reoId,
            "sessionId": sId,
            "flowId": fId,
            "questionCode": code,
            "value": value+"",
            "printableKey": code,
            "printableValue": value +""
        }));
    }
    render() {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">                
                    <div class="p-4 md:p-6 lg:p-8">               
                        <div class="sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            <button type="button"  onClick={this.handleClick} class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bww-600 text-base font-bold text-white hover:bg-bww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bww-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                {this.state.isSubmitting ? <Spinner /> : "Kies je voorkeuren"}
                            </button>
                        </div>           
                        {this.state.isSubmitting ? <p class="text-xs py-4">We verwerken nu je antwoorden.</p> : ""}   
                    </div>
                </div>
            </section>
        )
    }
}